<script>
  import { chart } from 'svelte-apexcharts'

  export let npsSurvey
  export let colors

  const series = Object.entries(npsSurvey.score_percentages).map(
    ([_, percentage]) => percentage,
  )
  const labels = Object.entries(npsSurvey.score_percentages).map(
    ([score, _]) => score,
  )

  let options = {
    chart: {
      type: 'donut',
      height: 380,
    },
    series: series,
    labels: labels,
    colors: colors,
    legend: {
      show: true,
      position: 'bottom',
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: { show: true, color: 'black' },
            total: {
              showAlways: true,
              show: true,
              color: 'black',
              label: 'Score',
              formatter: () => {
                return npsSurvey.score.toFixed(0)
              },
            },
          },
        },
      },
    },
  }
</script>

<div use:chart={options} />
