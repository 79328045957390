<script>
  import UserAvatar from '../../../components/UserAvatar.svelte'
  import ImpactScoreCircles from '../../Events/components/ImpactScoreCircles.svelte'
  import {
    startDate,
    endDate,
  } from '../../../components/ReportsNavigation/store.js'
  import dayjs from 'dayjs'

  export let usersWithFewestRegistrations
  export let usersWithNoIncidentsRegistered

  let start_date
  let end_date

  startDate.subscribe((value) => {
    start_date = dayjs(value).format('YYYY-MM-DD')
  })

  endDate.subscribe((value) => {
    end_date = dayjs(value).format('YYYY-MM-DD')
  })

  const urlParams = new URLSearchParams()
  urlParams.append('start_date', start_date)
  urlParams.append('end_date', end_date)
</script>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <h3>Medarbejdere med færest registreringer</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <table class="table table-hover table-striped table-sm">
        <thead>
          <tr>
            <th>Navn</th>
            <th class="text-right">Hændelser</th>
            <th class="text-right">Seneste login</th>
          </tr>
        </thead>
        <tbody>
          {#each usersWithFewestRegistrations as user}
            <tr>
              <td>{user.user.name}</td>
              <td class="text-right">{user.incident_count}</td>
              <td class="text-right">
                {#if user.user.last_successful_login}
                  {dayjs(user.user.last_successful_login).format('lll')}
                {:else}
                  Aldrig
                {/if}
              </td>
            </tr>
          {/each}
          {#each usersWithNoIncidentsRegistered as user}
            <tr>
              <td>{user.name}</td>
              <td class="text-right">0</td>
              <td class="text-right">
                {#if user.last_successful_login}
                  {dayjs(user.last_successful_login).format('lll')}
                {:else}
                  Aldrig
                {/if}
              </td>
            </tr>
          {/each}
        </tbody>
      </table>
    </div>
  </div>
</div>
