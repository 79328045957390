<script>
  import { page } from '@inertiajs/svelte'

  export let companies = []
  export let reportType = ''

  const current_company = $page.url.slice(1).split('/')[1]

  const companyReportSelectLink = (company_id) => {
    switch (reportType) {
      case 'all':
        return `/organization_reports/${company_id}`
      case 'workday':
        return `/organization_reports/${company_id}/workday_reports`
      case 'incident':
        return `/organization_reports/${company_id}/incident_reports`
      default:
        throw new Error('Invalid report type')
    }
  }

  const changeCompany = (e) => {
    location.href = e.target.value
  }
</script>

{#if companies.length > 1}
  <br />
  <strong>Vælg enhed</strong>
  <br />
  <select
    name="organization_report_select"
    id="organization_report_select"
    class="form-control"
    on:change={changeCompany}
  >
    {#each companies as company}
      <option
        value={companyReportSelectLink(company.id)}
        selected={company.id == current_company}>{company.name}</option
      >
    {/each}
  </select>
{/if}
