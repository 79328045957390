<script>
  import CommentRow from './CommentRow.svelte'
  import WorkdayModal from '../HistoryBar/WorkdayModal.svelte'

  let showModal = false
  let modalWorkday = null
  let modalOffset = 0

  export let days

  const showWorkdayModal = (e) => {
    showModal = true
    modalWorkday = e.detail
    modalOffset = window.scrollY + 500
  }
</script>

<table class="table table-hover">
  <tbody>
    {#each days as w}
      <CommentRow {w} on:open_wdr={showWorkdayModal} />
    {/each}
  </tbody>
</table>

<WorkdayModal bind:showModal bind:modalWorkday {modalOffset} />
