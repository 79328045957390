<script>
  import ReportMenu from '@/components/ReportsNavigation/ReportMenu.svelte'
  import UserAvatar from '@/components/UserAvatar.svelte'
  import ColumnChart from './components/ColumnChart.svelte'
  import PeriodSampleChart from './components/PeriodSampleChart.svelte'
  import { page } from '@inertiajs/svelte'
  import WorkdayModal from './components/HistoryBar/WorkdayModal.svelte'
  import WdrWithComment from './components/WdrWithComment.svelte'
  import dayjs from 'dayjs'

  export let user
  export let positiveWdrsWithComments
  export let negativeWdrsWithComments
  export let wdrsWithoutDayOff
  export let periodSamples
  export let dateRange

  let showModal = false
  let modalWorkday = null

  const current_user = $page.props.auth.user

  const showWorkdayModal = (e) => {
    showModal = true
    modalWorkday = e.detail
  }
</script>

<ReportMenu hideReportNavigation displayDateSelection />

<div class="row">
  <div class="col-xs-12 col-md-8 col-md-offset-2">
    <h2 class="text-center">
      <UserAvatar {user} />
      {user.name}
    </h2>
    <br />
    <strong class="text-muted"> Farvefordeling for perioden </strong>
    <ColumnChart data={wdrsWithoutDayOff} height={100} />
  </div>
</div>

<div class="row">
  <div class="col-xs-12 col-md-8 col-md-offset-2">
    {#if periodSamples.length > 0}
      <strong class="text-muted">
        Gennemsnitlig trivselscore ({dayjs(dateRange.start).format('ll')} - {dayjs(
          dateRange.end,
        ).format('ll')})
      </strong>
      <PeriodSampleChart data={periodSamples} />
    {:else}
      <div class="panel panel-default">
        <div class="panel-body">
          <div class="text-center">
            Vend tilbage hertil om et par uger for at se hvordan {user.id ==
            current_user.id
              ? 'din'
              : user.name} trivsel udvikler sig.
          </div>
        </div>
      </div>
    {/if}
  </div>
</div>

{#if positiveWdrsWithComments.length > 0}
  <div class="row">
    <div class="col-xs-12 col-md-8 col-md-offset-2">
      <div class="row">
        <strong class="text-muted"
          >Eksempler på dage med god trivsel i perioden</strong
        >
      </div>
      <div class="row">
        {#each positiveWdrsWithComments as wdr (wdr.id)}
          <WdrWithComment {wdr} on:open_wdr={showWorkdayModal} />
        {/each}
      </div>
    </div>
  </div>
{/if}
{#if current_user.id != user.id && negativeWdrsWithComments.length > 0}
  <div class="row">
    <div class="col-xs-12 col-md-8 col-md-offset-2">
      <div class="row">
        <strong class="text-muted"
          >Eksempler på gule/røde dage i perioden</strong
        >
      </div>
      <div class="row">
        {#each negativeWdrsWithComments as wdr (wdr.id)}
          <WdrWithComment {wdr} on:open_wdr={showWorkdayModal} />
        {/each}
      </div>
    </div>
  </div>
{/if}

<WorkdayModal bind:showModal bind:modalWorkday />
