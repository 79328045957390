import { writable } from 'svelte/store'
import dayjs from 'dayjs'

const defaultTaskState = {
  name: '',
  description: '',
  start_time: dayjs(),
  end_time: dayjs().add(30, 'minute'),
  citizen_id: null,
  user_id: null,
  recurring: null,
}

const initialState = structuredClone(defaultTaskState)

function createStore() {
  const { subscribe, set, update } = writable({ ...initialState })

  return {
    subscribe,
    set,
    update,
    reset: () => {
      set(structuredClone(defaultTaskState))
    },
  }
}

// TODO: Rename to taskFormStore
export const taskForm = createStore()

// TODO : Remove !
export function resetTaskForm() {
  taskForm.set(structuredClone(defaultTaskState))
}
