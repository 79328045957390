<script>
  import { eventForm } from '@/stores/EventFormStore'
  import dayjs from 'dayjs'
  import isToday from 'dayjs/plugin/isToday'
  import isYesterday from 'dayjs/plugin/isYesterday'

  // Props
  export let triggerNextSlide

  // Making the date text dynamic
  dayjs.extend(isToday)
  dayjs.extend(isYesterday)
  const urlParams = new URLSearchParams(window.location.search)
  const dateString = urlParams.get('date')

  $eventForm.day = dateString

  function dateText() {
    const date = new Date(dateString)

    if (dayjs(date).isToday()) {
      return 'i dag'
    } else if (dayjs(date).isYesterday()) {
      return 'i går'
    } else {
      return dayjs(date).format('dddd [d.] D. MMMM')
    }
  }

  function setCategory(category_id) {
    $eventForm.event_category_id = category_id
    triggerNextSlide(1)
  }
</script>

<div class="row" style="margin-top: 6rem">
  <div class="col-xs-12 col-md-6 col-md-offset-3">
    <h2>Opret en ny hændelse oplevet {dateText()}</h2>
    <div class="category-selection">
      {#each $eventForm.form_data.categories as category}
        <button
          id={category.id}
          on:click|preventDefault={() => {
            setCategory(category.id)
          }}
          class="btn btn-lg btn-default {category.id ==
          $eventForm.event_category_id
            ? 'active'
            : ''}"
        >
          {category.name}
        </button>
      {/each}
    </div>
  </div>
</div>

<style>
  .category-selection {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .btn {
    text-align: left;
    text-wrap: wrap;
  }
</style>
