<script>
  export let score
  export let with_text = false

  let rounded_score = Math.round(score)
  let remainder = 5 - rounded_score

  const score_to_color = (score) => {
    switch (score) {
      case 1:
        return '#ECC600'
      case 2:
        return '#ECC600'
      case 3:
        return '#ec8b00'
      case 4:
        return '#ec8b00'
      case 5:
        return '#CA0000'
      default:
        return '#d9d9d9'
    }
  }
</script>

<div class="text-center">
  {#each Array(rounded_score) as _i}
    <span style="background-color:{score_to_color(rounded_score)};"></span>
  {/each}
  {#if remainder > 0}
    {#each Array(remainder) as _i}
      <span style="background-color:{score_to_color(null)}"></span>
    {/each}
  {/if}
  {#if with_text}
    <br />
    <small class="text-muted"
      >{Math.round(score * 10) / 10}/5 Påvirkningsgrad</small
    >
  {/if}
</div>

<style>
  span {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 2px;
  }
</style>
