<script>
  import { Link, page } from '@inertiajs/svelte'
  import DateNavigationButtons from './DateNavigationButtons.svelte'
  import DateRangeSelection from './DateRangeSelection.svelte'

  export let current_tab = ''
  export let displayDateSelection = false
  export let hideReportNavigation = false

  export const is_active = (tab) => {
    return tab == current_tab ? 'active' : null
  }

  const urlParams = new URLSearchParams(window.location.search)
  const policies = $page.props.auth.policies
  const current_user = $page.props.auth.user
  const company = $page.props.auth.company
</script>

{#if displayDateSelection}
  <div class="row date-selection">
    <div class="col-xs-12 text-right">
      <DateNavigationButtons />
      <br class="visible-xs" />
      <DateRangeSelection />
    </div>
  </div>
{/if}
{#if !hideReportNavigation}
  <div class="row" style="margin-bottom: 5rem">
    <div class="col-xs-12">
      <ul class="nav nav-tabs">
        {#if company.has_work_enviroment_product}
          {#if policies.company.inbox.index}
            <li class={is_active('inbox')}>
              <Link href="/inbox">
                <div class="glyphicon glyphicon-flag" />
                Aktuelt
                {#if policies.company.inbox.unread > 0}
                  <span class="badge appreciation-badge"
                    >{policies.company.inbox.unread}</span
                  >
                {/if}
              </Link>
            </li>
          {/if}
          {#if policies.workday_registration_report.anonymous}
            <li class={is_active('anonymous')}>
              <a
                href="/workday_registration_reports/anonymous?{urlParams.toString()}"
              >
                <div class="glyphicon glyphicon-stats" />
                Trivselsrapport</a
              >
            </li>
          {/if}
          {#if policies.workday_registration_report.employee_overview}
            <li class={is_active('daily')}>
              <a
                href="/workday_registration_reports/employee_daily_overview?{urlParams.toString()}"
              >
                <div class="glyphicon glyphicon-user" />
                Daglig oversigt
              </a>
            </li>
            <li class={is_active('percent')}>
              <a
                href="/workday_registration_reports/employee_percent_overview?{urlParams.toString()}"
              >
                <div class="glyphicon glyphicon-user" />
                Procentvis fordeling
              </a>
            </li>
          {/if}
          {#if policies.workday_registration_report.biweekly}
            <li class={is_active('biweekly')}>
              <a href="/workday_registration_reports/biweekly">
                <div class="glyphicon glyphicon-calendar" />
                Seneste 14-dages rapport</a
              >
            </li>
          {/if}
        {/if}
        {#if company.event_registration_enabled}
          {#if policies.incident_report.overall}
            <li class={is_active('incident_overall')}>
              <Link href="/incident_reports/overall">
                <div class="glyphicon glyphicon-alert" />
                Hændelser</Link
              >
            </li>
          {/if}
        {/if}
        {#if policies.organizationReport.companyReport}
          <li class={is_active('company_report')}>
            <a
              href="/organization_reports/company_report?leader_id={current_user.id}"
              >{company.name}</a
            >
          </li>
        {/if}
        {#if policies.organizationReport.show}
          <li class={is_active('organization_report')}>
            <a href="/organization_reports">Organisationsrapport</a>
          </li>
        {/if}
        {#if policies.nps_survey.index}
          <!-- {#if policies.nps_survey.any} -->
          <li class={is_active('nps_surveys')}>
            <a href="/nps_survey_reports">
              <div class="glyphicon glyphicon-alert" />
              eNPS Rapport</a
            >
          </li>
          <!-- {/if} -->
        {/if}
      </ul>
    </div>
  </div>
{/if}

<style>
  .date-selection {
    min-height: 70px;
  }
</style>
