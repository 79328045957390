<script>
  import { useForm } from '@inertiajs/svelte'

  let form = useForm({
    user: {
      email: null,
    },
  })

  function handleSubmit() {
    $form.get('/users/invite_user')
  }
</script>

<div class="row">
  <div class="col-xs-12 col-md-6 col-md-offset-3">
    <h3>Invitér medarbejder til TeamEffect</h3>
    <br />
    <form on:submit|preventDefault={handleSubmit}>
      <div class="form-group">
        <label class="control-label" for="email"
          >Indtast medarbejderens email adresse</label
        >
        <input
          type="email"
          class="form-control"
          id="email"
          placeholder="Email"
          bind:value={$form.user.email}
        />
      </div>
      <button type="submit" class="btn btn-primary btn-block">Invitér</button>
    </form>
  </div>
</div>
