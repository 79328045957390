<script>
  import { workdayRegistrationForm } from '@/stores/WorkdayRegistrationFormStore'

  export let selections = []

  function handleSelection(selection) {
    if ($workdayRegistrationForm.feelings.includes(selection)) {
      $workdayRegistrationForm.feelings =
        $workdayRegistrationForm.feelings.filter(
          (feeling) => feeling !== selection,
        )
    } else {
      $workdayRegistrationForm.feelings = [
        ...$workdayRegistrationForm.feelings,
        selection,
      ]
    }
  }
</script>

<strong>I dag har jeg</strong>
<div class="selectionContainer">
  {#each selections as selection}
    <label for={selection} class="panel-label">
      <input
        type="checkbox"
        id={selection}
        value={selection}
        on:change={() => {
          handleSelection(selection)
        }}
      />
      {selection}
    </label>
  {/each}
</div>

<style>
  label,
  input[type='checkbox'] {
    cursor: pointer;
  }

  .panel-label {
    display: block;
    color: #333333;
    font-weight: normal;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 10px 10px 10px 30px;
  }
  .panel-label > input[type='checkbox'] {
    margin-right: 1rem;
  }

  .selectionContainer {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
</style>
