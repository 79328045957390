<script>
  import {page} from '@inertiajs/svelte'
  import UserAvatar from '@/components/UserAvatar.svelte'

  export let users;
  export let selectedUsers = [];

  const handleCheckboxChange = (e) => {
    document.querySelector(`label[for="${e.target.id}"]`).classList.toggle('user-checkbox-checked');
  }

  const current_user = $page.props.auth.user;
</script>

{#each users as user}
{@const labelClass = user.id == current_user.id ? 'user-checkbox-checked' : ''}
<div class="col-xs-12 col-md-4">
  <div class="checkbox">
    <label for="defusing[user_ids][{user.id}]" class="btn btn-default btn-block user-btn {labelClass}">
      <input
        type="checkbox"
        id="defusing[user_ids][{user.id}]"
        name="defusing[user_ids][{user.id}]"
        value={user.id}
        checked={user.id == current_user.id}
        class="user-checkbox"
        on:change={handleCheckboxChange}
        bind:group={selectedUsers}
      />
      <UserAvatar {user} />
      {user.name}
    </label>
  </div>
</div>
{/each}
