<script>
  import {
    deactivateEventCategory,
    activateEventCategory,
  } from '../eventCategoryHelper'

  export let form
  export let handleSubmit
  export let type
</script>

<div class="row">
  <div class="col-xs-12 col-md-6 col-md-offset-3">
    <h2>{type == 'new' ? 'Opret' : 'Redigér'} Hændelseskategori</h2>
    <form on:submit|preventDefault={handleSubmit}>
      <div class="form-group">
        <label for="name">Navn</label>
        <input
          type="text"
          class="form-control"
          id="name"
          placeholder="Navn"
          bind:value={$form.event_category.name}
        />
      </div>
      <button type="submit" class="btn btn-primary btn-block"
        >{type == 'new' ? 'Opret' : 'Gem'}</button
      >
    </form>
    <hr />
    {#if $form.event_category.active_state == 'active'}
      <button
        on:click={() => deactivateEventCategory($form.event_category)}
        class="btn btn-danger btn-block">Deaktivér</button
      >
    {:else}
      <button
        on:click={() => activateEventCategory($form.event_category)}
        class="btn btn-success btn-block">Aktivér</button
      >
    {/if}
  </div>
</div>
