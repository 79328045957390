<script>
  import { page, useForm } from '@inertiajs/svelte'
  import UserAvatar from '@/components/UserAvatar.svelte'
  import { capitalize } from '@/utils/helpers.js'
  export let company
  export let app_client

  const current_user = $page.props.auth.user

  let form = useForm({
    new_post: {
      text: '',
      image: null,
    },
  })

  const error_class = 'has-error'

  function handleSubmit() {
    $form.post('/posts')
  }

  const handleImgInput = (e) => {
    const [file] = e.target.files
    $form.new_post.image = file
  }
</script>

<div class="row">
  <div class="col-xs-12 col-md-8 col-md-offset-2">
    <div class="media">
      <div class="media-left">
        <UserAvatar user={current_user} />
      </div>
      <div class="media-body">
        <strong>{capitalize(current_user.name)}</strong>
        <br />
        <small class="text-muted">
          Opdatering til alle kollegaer hos {company.name}
        </small>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <br />
        <form on:submit|preventDefault={handleSubmit}>
          <div class="form-group">
            <textarea
              placeholder="Hvad vil du gerne dele?"
              bind:value={$form.new_post.text}
              class="form-control {$form.errors.text && error_class}"
            />
            {#if $form.errors.text}
              <div class="form-error">{$form.errors.text}</div>
            {/if}
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-block btn-primary">Del</button>
          </div>
          {#if !app_client}
            <div class="form-group">
              <div class="image-upload">
                <label for="image" class="image-label">
                  📷 Tilføj billede
                </label>
                <input
                  class="image-upload-input"
                  type="file"
                  on:input={(e) => handleImgInput(e)}
                />
                {#if $form.errors.text}
                  <div class="form-error">{$form.errors.image}</div>
                {/if}
              </div>
            </div>
          {/if}
        </form>
      </div>
    </div>
  </div>
  <div class="col-xs-12">
    <div class="image-preview-box">
      {#if $form.new_post.image}
        <img
          class="image-preview"
          src={URL.createObjectURL($form.new_post.image)}
          alt={$form.new_post.text}
        />
      {/if}
    </div>
  </div>
</div>

<style>
  .has-error {
    border: 2px solid #dc3545;
  }

  .progress-bar {
    width: 100%;
  }

  .image-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .image-upload input {
    line-height: 100%;
    width: 100%;
    position: absolute;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
    background-color: blue;
  }

  .image-upload label {
    font-size: 16px;
    font-weight: normal;
  }

  .image-preview-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
  }

  .image-preview {
    max-width: 100%;
    max-height: 100%;
  }
</style>
