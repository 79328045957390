<script>
  import { defusingUsers, defusingsForm } from '@/stores/DefusingFormStore'
  import { page } from '@inertiajs/svelte'

  import UserSelect from '../components/UserSelect.svelte'

  const company = $page.props.auth.company
  const current_user = $page.props.auth.user

  let selected = [current_user.id]
  $: $defusingsForm.user_ids = [...selected]
</script>

<div class="text-center">
  {#if $defusingsForm.defusing_type == 'mutual'}
    <h2>Hvem var tilstede fra {company.name}?</h2>
  {:else}
    <h2>Hvem er tilstede under denne defusing?</h2>
    <small>Typisk dig selv og alarmansvarlig</small>
  {/if}
</div>

<br />
<div class="row">
  <div class="col-xs-12">
    <UserSelect users={$defusingUsers} bind:selectedUsers={selected} />
  </div>
</div>
