// Desc: Helper functions for WDR
import dayjs from 'dayjs'

const scoreOptions = {
  1: 'Dårlig',
  2: 'Lidt dårlig',
  3: 'Ok',
  4: 'Lidt god',
  5: 'God',
}

export const score_color = (score) => {
  switch (parseInt(score)) {
    case 1:
      return '#CA0000'
    case 2:
      return '#ECC600'
    case 3:
      return '#D9DFB8'
    case 4:
      return '#A9D95A'
    case 5:
      return '#00B425'
    default:
      throw new Error(`Score ${score} is not valid argument`)
  }
}

export const deprecatedScoreNumberToText = (score) => {
  switch (score) {
    case 1:
      return 'Rød'
    case 2:
      return 'Gul'
    case 3:
      return 'Grøn'
    default:
      throw new Error(`Score ${score} is not valid argument`)
  }
}

// TODO: Remove this function - it is deprecated
export const scoreTitle = (score, type = null) => {
  if (Object.keys(scoreOptions).includes(score.toString())) {
    return scoreOptions[score]
  } else {
    return workdayTypeToTitle(type)
  }
}

export const workdayTypeToTitle = (w) => {
  switch (w.workday_type) {
    case 'workday':
      return ''
    case 'day_off':
      return 'Fridag'
    case 'sickness_absense':
      return 'Sygedag'
    case 'week_off':
      return 'Friuge'
    default:
      throw new Error(
        `Title for workday type '${w.workday_type}' is not available`,
      )
  }
}

export const shiftTypeToTitle = (s) => {
  // not_registered day evening night
  switch (s) {
    case 'not_registered':
      return 'Ikke registreret'
    case 'day':
      return 'Dag'
    case 'evening':
      return 'Aften'
    case 'night':
      return 'Nat'
    default:
      return 'Ukendt vagttype'
  }
}

export const workdaySentence = (wdr, auth) => {
  let sentence = ''
  if (auth.verified_login) {
    sentence += dayjs(wdr.day).format('dddd [d.] D. MMMM') + ' '
    switch (wdr.workday_type) {
      case 'workday':
        sentence += `var ${scoreTitle(wdr.score, wdr.workday_type)}`
        break
      case 'day_off':
        sentence += sentenceWithPossibleAuther('havde jeg fri', wdr)
        break
      case 'sickness_absense':
        sentence += sentenceWithPossibleAuther('var jeg syg', wdr)
        break
      default:
        throw new Error(
          `Title for workday type '${wdr.workday_type}' is not available`,
        )
    }
  } else {
    sentence += anonymousWorkdaySentence(wdr)
  }

  return sentence
}

const sentenceWithPossibleAuther = (sentence, wdr) => {
  let out = ''
  out += sentence
  if (wdr.user_id != wdr.created_by_user_id) {
    out += ` (Oprettet af ${wdr.created_by_user.name})`
  }
  return out
}

const anonymousWorkdaySentence = (wdr) => {
  return dayjs(wdr.day).format('dddd') + ' er udfyldt'
}

export const hasComment = (wdr) => {
  if (!wdr) {
    return false
  }
  return !!(wdr.comment || wdr.positive_comment || wdr.negative_comment)
}

export const hasEventOnDay = (events, day) => {
  return events.some((event) => dayjs(event.day).isSame(day, 'day'))
}

export const getScoreName = (score) => {
  switch (parseInt(score)) {
    case (1, 2, 3, 4, 5):
      return ''
    default:
      return 'Sygedage'
  }
}
