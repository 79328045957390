<script>
  import { createEventDispatcher } from 'svelte'
  import { hasComment, workdayTypeToTitle } from '@/utils/wdr_helper.js'
  import { Icon, ChatBubbleBottomCenterText } from 'svelte-hero-icons'
  import dayjs from 'dayjs'

  export let date
  export let wdr
  export let event = false
  export let sickday = false

  const dipsatch = createEventDispatcher()

  const openWorkdayModal = () => {
    if (wdr.workday_type == 'workday') {
      dipsatch('open_wdr', {
        date: dayjs(date).format('YYYY-MM-DD'),
        workday: wdr,
      })
    }
  }
</script>

{#if sickday}
  <div
    on:click={openWorkdayModal}
    class="progress-bar wdr-progress-block workday-type-sickness_absense"
    role="progressbar"
    data-toggle="tooltip"
    data-placement="top"
    title="{dayjs(date).format('dddd [d.] D MMMM')}: {workdayTypeToTitle(wdr)}"
  />
{:else}
  <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div
    on:click={openWorkdayModal}
    class="progress-bar wdr-progress-block workday-type-{wdr.workday_type} score-{wdr.score}"
    role="progressbar"
    data-toggle="tooltip"
    data-placement="top"
    title="{dayjs(date).format('dddd [d.] D MMMM')}: {workdayTypeToTitle(wdr)}"
  >
    {#if event}
      H
    {:else if hasComment(wdr)}
      <Icon src={ChatBubbleBottomCenterText} size="16" />
    {/if}
  </div>
{/if}

<style>
  .wdr-progress-block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .workday-type-workday:hover {
    cursor: pointer;
  }
</style>
