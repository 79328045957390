<script>
  import { Icon, Plus } from 'svelte-hero-icons'
  import axios from 'axios'

  import Reference from './components/Reference.svelte'

  export let incidentReferences

  const deleteReference = (id) => {
    axios
      .delete(`/incident_references/${id}`)
      .then(() => {
        incidentReferences = incidentReferences.filter((ref) => ref.id !== id)
      })
      .catch((error) => {
        console.log(error)
      })
  }
</script>

<div class="row">
  <div class="col-xs-12 title-row">
    <h1>
      Referencepunkter ({incidentReferences.length})
    </h1>
    <a href="/incident_references/new" class="btn btn-primary btn-icon">
      <Icon src={Plus} size="18" />
      Opret
    </a>
  </div>
</div>

<div class="row">
  <div class="col-xs-12">
    {#each incidentReferences as ref}
      <Reference
        reference={ref}
        on:delete-reference={(e) => deleteReference(e.detail)}
      />
    {/each}
  </div>
</div>

<style>
  .title-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
  }

  .title-row h1 {
    margin: 0;
  }
</style>
