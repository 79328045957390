<script>
  import { chart } from 'svelte-apexcharts'
  import da from 'apexcharts/dist/locales/da.json'

  export let data
  export let height = 300
  export let name

  let options = {
    chart: {
      locales: [da],
      defaultLocale: 'da',
      type: 'line',
      height: height,
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {},
    series: [
      {
        name: name,
        data: data,
      },
    ],
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 5,
    },
  }
</script>

<div use:chart={options} />
