<script>
  import { page } from '@inertiajs/svelte'
  import { fade } from 'svelte/transition'

  $: flash = $page.props.flash.flash
  $: show = $page.props.flash.has_flash

  let type = 'primary'
  let msg = ''

  $: if (show) {
    if (flash.danger) {
      type = 'danger'
      msg = flash.danger
    }
    if (flash.alert || flash.warning) {
      type = 'warning'
      msg = flash.alert || flash.warning
    }

    if (flash.info) {
      type = 'info'
      msg = flash.info
    }

    if (flash.success) {
      type = 'success'
      msg = flash.success
    }

    setTimeout(() => {
      show = false
    }, 3000)
  }

  const closeAlert = () => {
    show = false
  }
</script>

{#if show}
  <div class="alert alert-dismissable alert-{type}" transition:fade>
    <button
      type="button"
      class="close"
      aria-hidden="true"
      on:click|preventDefault={closeAlert}
    >
      &times;
    </button>
    {msg}
  </div>
{/if}

<style>
  .alert {
    position: absolute;
    z-index: 1000;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
</style>
