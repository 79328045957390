<script>
  import { capitalize } from '@/utils/helpers'
  import dayjs from 'dayjs'
  import Button from '@/components/Button.svelte'
  import { getUrlTimeParams } from '../utils'
  import { Icon, ArrowsPointingOut, ArrowsPointingIn } from 'svelte-hero-icons'
  import { boardStore } from '@/stores/TaskBoardStore'

  export let board

  let userUrl = `/user_board${getUrlTimeParams($boardStore)}`
  let citizenUrl = `/citizen_board${getUrlTimeParams($boardStore)}`

  $: urlParams = new URLSearchParams(window.location.search)
  $: isFullScreen =
    new URLSearchParams(window.location.search).get('fullscreen') || false

  const changeFullScreen = () => {
    if (urlParams.get('fullscreen')) {
      urlParams.delete('fullscreen')
    } else {
      urlParams.set('fullscreen', true)
    }
    window.location.search = urlParams.toString()
  }
</script>

<div class="board-selection-container">
  <Button variant="icon">
    <div on:click={changeFullScreen}>
      <Icon
        src={isFullScreen ? ArrowsPointingIn : ArrowsPointingOut}
        size="16"
      />
      {isFullScreen ? 'Afslut Fuld skærm' : 'Fuld skærm'}
    </div>
  </Button>
  <div class="board-selection-controls">
    <a href={userUrl}>
      <Button disabled={board == 'user'}>Medarbejder</Button>
    </a>
    <a href={citizenUrl}>
      <Button disabled={board == 'citizen'}>Borger</Button>
    </a>
  </div>
  <div>
    <h2>
      {capitalize(dayjs($boardStore.startTime).format('dddd'))} - Uge: {dayjs(
        $boardStore.startTime,
      ).week()}
    </h2>
  </div>
</div>

<style>
  .board-selection-container {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  a {
    text-decoration: none;
  }
</style>
