<script>
  import { defusingsForm } from '@/stores/DefusingFormStore'
</script>

<div class="text-center">
  <h2>Hvad var din rolle?</h2>

  <br />
  <textarea bind:value={$defusingsForm.role_description} rows="3" />
</div>

<style>
  textarea {
    width: 80%;
    resize: vertical;
    margin: auto;
  }
</style>
