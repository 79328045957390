<script>
  import ActivateMembershipRow from '../components/ActivateMembershipRow.svelte'

  export let deactivated_employees
  const companies = Object.keys(deactivated_employees)
</script>

<div class="tab-pane" id="deleted-employees-tab" role="tabpane">
  {#each companies as company}
    <h3>{company}</h3>
    {#each deactivated_employees[company] as membership (membership.id)}
      <ActivateMembershipRow user={membership.user} {membership} />
    {/each}
  {/each}
</div>
