<script>
  import dayjs from 'dayjs'
  import { startDate, endDate } from './store.js'

  $: disabledClass = dayjs($endDate).isSameOrAfter(dayjs(), 'day')
    ? 'disabled'
    : ''
  $: periodLength = dayjs($endDate).diff(dayjs($startDate), 'day')

  $: previous_end_date = dayjs($endDate)
    .subtract(periodLength, 'day')
    .format('YYYY-MM-DD')
  $: previous_start_date = dayjs(previous_end_date)
    .subtract(periodLength, 'day')
    .format('YYYY-MM-DD')
  $: next_end_date =
    dayjs($endDate).add(periodLength, 'day') <= dayjs()
      ? dayjs($endDate).add(periodLength, 'day').format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD')
  $: next_start_date = dayjs(next_end_date)
    .subtract(periodLength, 'day')
    .format('YYYY-MM-DD')
</script>

<div class="date-navigation-btns">
  <a
    href="?start_date={previous_start_date}&end_date={previous_end_date}"
    class="btn btn-default btn-sm"
  >
    <div class="glyphicon glyphicon-arrow-left" />
    Forrige periode
  </a>
  <a
    href="?start_date={next_start_date}&end_date={dayjs(next_end_date) > dayjs()
      ? dayjs().format('YYYY-MM-DD')
      : next_end_date}"
    class="btn btn-default btn-sm {disabledClass}"
  >
    Næste periode
    <div class="glyphicon glyphicon-arrow-right" />
  </a>
</div>

<style>
  .date-navigation-btns {
    padding-bottom: 5px;
    display: inline-block;
  }
</style>
