<script>
  // !TODO: Images not updating after upload
  import { useForm, page } from '@inertiajs/svelte'
  import { Icon, Camera } from 'svelte-hero-icons'

  export let accountTransaction
  export let account
  export let citizen

  export let discrete = false

  const device_type = $page.props.meta.device_type
  const ios_app_client = $page.props.meta.ios_app_client

  const behavior =
    (device_type == 'mobile' || device_type == 'tablet') && ios_app_client
      ? 'trigger-camera-guide'
      : 'trigger-file-field'

  let form = useForm({
    document: {
      document_file: null,
    },
  })

  async function handleChange() {
    $form.post(`/account_transactions/${accountTransaction.id}/documents`)
  }

  function triggerFileField() {
    if (behavior == 'trigger-camera-guide') {
      window.webkit.messageHandlers.triggerCameraGuide.postMessage(null)
      return
    } else if (behavior == 'trigger-file-field') {
      let fileField = document.querySelector('.hidden-file-field')
      fileField.click()
    }
  }
</script>

<form on:change|preventDefault={handleChange}>
  <input
    type="file"
    on:input={(e) => ($form.document.document_file = e.target.files[0])}
    class="hidden-file-field"
  />
</form>
{#if !discrete}
  <div class="actions">
    <button
      disabled={$form.processing}
      class="btn btn-success btn-lg transaction-propmt-btn"
      on:click|preventDefault={triggerFileField}
    >
      Ja
    </button>
    <a
      href="/citizens/{citizen.id}/accounts/{account.id}"
      class="btn btn-danger btn-lg transaction-propmt-btn">Nej</a
    >
  </div>
{:else}
  <div class="text-center">
    <button
      disabled={$form.processing}
      class="btn btn-default btn-icon"
      on:click|preventDefault={triggerFileField}
    >
      <Icon src={Camera} solid size="18" />
      Tilføj bilag
    </button>
  </div>
{/if}

<style>
  .actions {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  .transaction-propmt-btn {
    width: 100px;
  }

  .btn-icon {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
</style>
