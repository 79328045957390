<script>
  import dayjs from 'dayjs'
  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()
  const today = dayjs().format('YYYY-MM-DD')

  const rangeOptions = [
    {
      label: 'siden i går',
      value: '1_day',
      date: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
    },
    {
      label: 'sidste 7 dage',
      value: '7_day',
      date: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
    },
    {
      label: 'sidste 14 dage',
      value: '14_day',
      date: dayjs().subtract(14, 'day').format('YYYY-MM-DD'),
    },
    {
      label: 'sidste måned',
      value: '1_month',
      date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    },
    {
      label: 'sidste 6 måneder',
      value: '6_month',
      date: dayjs().subtract(6, 'month').format('YYYY-MM-DD'),
    },
  ]

  let startDate = dayjs().subtract(1, 'month').format('YYYY-MM-DD')
  let endDate = dayjs().format('YYYY-MM-DD')

  $: startDate && endDate && onDateChange()

  $: selected = '1_month'

  const setDates = () => {
    const range = rangeOptions.find((option) => option.value == selected)
    const value = range.value.split('_')[0]
    const unit = range.value.split('_')[1]
    startDate = dayjs().subtract(value, unit).format('YYYY-MM-DD')
    endDate = dayjs().format('YYYY-MM-DD')
  }

  const onDateChange = () => {
    if (endDate != today) {
      selected = 'custom'
      return
    }

    const range = rangeOptions.find((option) => option.date == startDate)
    if (range) {
      selected = range.value
    } else {
      selected = 'custom'
    }
  }

  const handleSubmit = () => {
    setDates()
    dispatch('dateChange', { startDate, endDate })
  }
</script>

<div class="row">
  <div class="col-xs-12">
    <form class="inline-form" on:submit|preventDefault={handleSubmit}>
      <div class="form-group">
        <select
          class="form-control"
          bind:value={selected}
          on:change={handleSubmit}
        >
          <option value="custom">Vælg Datoer</option>
          {#each rangeOptions as option}
            <option value={option.value}>{option.label}</option>
          {/each}
        </select>
      </div>
      <div class="inline-form">
        <div class="form-group">
          <input
            type="date"
            class="form-control"
            id="start_date"
            placeholder="Start dato"
            bind:value={startDate}
          />
        </div>
        <div class="form-group">
          <strong>til</strong>
        </div>
        <div class="form-group">
          <input
            type="date"
            class="form-control"
            id="end_date"
            placeholder="Slut dato"
            bind:value={endDate}
          />
        </div>
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-primary">Opdater</button>
      </div>
    </form>
  </div>
</div>

<style>
  .inline-form {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
</style>
