<script>
  import { page, Link } from '@inertiajs/svelte'
  import UserAvatar from '@/components/UserAvatar.svelte'
  import WorkdayHistoryBar from './workdayHistoryBar.svelte'
  import ColumnChart from './ColumnChart.svelte'

  export let user
  export let events = []
  export let workdayRegistrations
  export let vacationPeriods = []
  export let daily = false
  export let dateRange

  const company = $page.props.auth.company

  const urlParams = new URLSearchParams(window.location.search)
</script>

<div class="row user-report">
  <div class="col-xs-12 col-sm-12 col-md-3">
    <Link
      href="/workday_registration_reports/user_report/{user.id}?{urlParams.toString()}"
    >
      <div class="row">
        <div class="col-xs-2 col-sm-1 col-md-3 col-image-print">
          <UserAvatar {user} size="35" />
        </div>
        <div class="col-xs-10 col sm 11 col-md-9 col-name-print">
          <div class="user-name">
            {user.name}
          </div>
          <div class="text-muted hidden-print">Se udvidet rapport</div>
        </div>
      </div>
    </Link>
  </div>
  <div class="col-xs-12 col-md-9 user-progress-container">
    {#if daily}
      <div class="workday-history-bar">
        {#if !company.weekly_worklife_rating}
          <WorkdayHistoryBar
            {dateRange}
            {user}
            {workdayRegistrations}
            {vacationPeriods}
            {events}
          />
        {:else}
          workweek_history_bar
        {/if}
      </div>
    {:else}
      <div class="workday-percentage-distribution-bar">
        {#if !company.weekly_worklife_rating}
          <ColumnChart data={workdayRegistrations} overview />
        {:else}
          weekly_worklife_rating
        {/if}
      </div>
    {/if}
  </div>
</div>

<style>
  .workday-percentage-distribution-bar {
    height: 100%;
  }
</style>
