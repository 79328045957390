<script>
  import { page, useForm } from '@inertiajs/svelte'

  export let user
  export let companies

  const company = $page.props.auth.company

  const policies = $page.props.auth.policies

  let form = useForm({
    user: {
      name: user.name,
      email: user.email,
      password: null,
      password_confirmation: null,
      team_ids: [],
      company_ids: [],
    },
  })

  function handleSubmit() {
    if ($form.user.password !== $form.user.password_confirmation) {
      $form.errors.password = ['Adgangskoderne er ikke ens']
    } else if (companies.length > 1 && $form.user.company_ids.length === 0) {
      $form.errors.company_ids = ['Vælg mindst én afdeling']
    } else {
      $form.post('/users')
    }
  }
</script>

{#if policies.company.userSignupAllowed}
  <div class="row">
    <div class="col-xs-12 col-md-6 col-md-offset-3">
      <h3>Invitér via email</h3>
      <a href="/users/invite_new_user_form" class="btn btn-primary btn-block">
        <div class="glyphicon glyphicon-envelope" />
        Send invitation til email
      </a>
    </div>
  </div>
  <br />
  <br />
{/if}

<div class="row">
  <div class="col-xs-12 col-md-6 col-md-offset-3">
    <h3>Opret ny bruger</h3>
    <form on:submit|preventDefault={handleSubmit}>
      <div class="form-group {$form.errors.name ? 'has-error' : ''}">
        <label class="control-label" for="name">Navn</label>
        <input
          type="text"
          class="form-control"
          id="name"
          placeholder="Navn"
          bind:value={$form.user.name}
        />
        {#if $form.errors.name}
          <span class="help-block">{$form.errors.name}</span>
        {/if}
      </div>
      <div class="form-group {$form.errors.email ? 'has-error' : ''}">
        <label class="control-label" for="email">Email</label>
        <input
          type="email"
          class="form-control"
          id="email"
          placeholder="Email"
          bind:value={$form.user.email}
        />
        {#if $form.errors.email}
          <span class="help-block">{$form.errors.email}</span>
        {/if}
      </div>
      <br />
      <div class="form-group {$form.errors.password ? 'has-error' : ''}">
        <label for="password">Adgangskode</label>
        <input
          type="password"
          class="form-control"
          id="password"
          placeholder="Adgangskode"
          bind:value={$form.user.password}
        />
        {#if $form.errors.password}
          <span class="help-block">{$form.errors.password}</span>
        {/if}
      </div>
      <div class="form-group {$form.errors.password ? 'has-error' : ''}">
        <label for="password_confirmation">Gentag adgangskode</label>
        <input
          type="password"
          class="form-control"
          id="password_confirmation"
          placeholder="Gentag adgangskode"
          bind:value={$form.user.password_confirmation}
        />
        {#if $form.errors.password}
          <span class="help-block">{$form.errors.password}</span>
        {/if}
      </div>
      {#if company.teams_count > 0}
        <div class="form-group">
          <label for="teams">Teams</label>
          {#each company.teams as team}
            <div class="checkbox">
              <label>
                <input
                  type="checkbox"
                  value={team.id}
                  bind:group={$form.user.team_ids}
                />
                {team.name}
              </label>
            </div>
          {/each}
        </div>
      {/if}
      {#if companies.length > 1}
        <div class="form-group">
          <label for="companies">Afdelinger</label>
          <div class={$form.errors.company_ids ? 'has-error' : ''}>
            {#each companies as company}
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    value={company.id}
                    bind:group={$form.user.company_ids}
                  />
                  {company.name}
                </label>
              </div>
            {/each}
            {#if $form.errors.company_ids}
              <span class="help-block">{$form.errors.company_ids}</span>
            {/if}
          </div>
        </div>
      {/if}

      <button type="submit" class="btn btn-primary btn-block">
        Opret bruger
      </button>
    </form>
  </div>
</div>
