<script>
  import { defusingsForm } from '@/stores/DefusingFormStore'
</script>

<h2 class="text-center">Beskriv hændelsen faktuelt</h2>

<div class="form-group">
  <textarea
    class="form-control"
    name="defusing[description]"
    id="defusing[description]"
    rows="4"
    placeholder="Beskriv hændelsen faktuelt"
    bind:value={$defusingsForm.description}
  ></textarea>
</div>

<style>
  textarea {
    width: 80%;
    resize: vertical;
    margin: auto;
  }
</style>
