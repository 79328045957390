<script>
  import WorkdayRegistration from '../../../WorkdayRegistrations/components/WorkdayRegistration.svelte'
  import Modal from '@/components/Modal.svelte'
  import UserAvatar from '@/components/UserAvatar.svelte'

  export let showModal
  export let modalWorkday

  const fetchModalData = async () => {
    const response = await fetch(
      `/workday_registrations/${modalWorkday.workday.id}.json`,
    )

    return await response.json()
  }
</script>

{#if showModal && modalWorkday}
  {#await fetchModalData()}
    <div class="loader">Henter...</div>
  {:then response}
    <Modal bind:showModal>
      <div class="user_info" slot="header">
        <UserAvatar user={response.wdr.user} />
        <h4>{response.wdr.user.name}</h4>
      </div>
      <div class="wdr-content">
        <WorkdayRegistration
          workday={response.wdr}
          canDestroy={response.canDestroy}
          workdayResponses={response.workdayResponses}
          createNewResponse={response.createNewResponse}
          userEvents={response.userEvents}
          otherWorkdayRegistrationsSameDay={response.otherWorkdayRegistrationsSameDay}
          canShare={response.canShare}
        />
      </div>
    </Modal>
  {:catch error}
    <div class="loader">
      {error.message}
    </div>
  {/await}
{/if}

<style>
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .user_info {
    display: flex;
    flex: 1;
    align-items: center;
    gap: 10px;
  }
</style>
