<script>
  import UserAvatar from '../../../components/UserAvatar.svelte'
  import ImpactScoreCircles from '../../Events/components/ImpactScoreCircles.svelte'
  import {
    startDate,
    endDate,
  } from '../../../components/ReportsNavigation/store.js'
  import dayjs from 'dayjs'

  let start_date
  let end_date

  startDate.subscribe((value) => {
    start_date = dayjs(value).format('YYYY-MM-DD')
  })

  endDate.subscribe((value) => {
    end_date = dayjs(value).format('YYYY-MM-DD')
  })

  const urlParams = new URLSearchParams()
  urlParams.append('start_date', start_date)
  urlParams.append('end_date', end_date)

  export let mostImpactedUsers
</script>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <h3>5 mest påvirkede medarbejdere</h3>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-xs-12">
      <div class="grid-col">
        {#each mostImpactedUsers as user}
          <div class="grid">
            <div>
              <UserAvatar user={user.user} size="25" />
              <strong>
                <a
                  href="/events?incident_search[user_id]={user.user
                    .id}&{urlParams.toString()}"
                >
                  {user.user.name}
                </a>
              </strong>
            </div>
            <div class="text-center">
              <ImpactScoreCircles score={user.avg_impact} with_text />
            </div>
            <div>
              <strong>{user.incidents_count}</strong> Hændelser
            </div>
            <div>
              {#if user.high_impact_count > 0}
                <a
                  href="/events?incident_search[user_id]={user.user
                    .id}&incident_search[impact_score_category]=high_impact&{urlParams.toString()}"
                  class="btn btn-defult btn-sm"
                >
                  <span class="text-danger">
                    <strong>{user.high_impact_count}</strong> med Høj påvirkning
                  </span>
                </a>
              {/if}
            </div>
          </div>
        {/each}
      </div>
    </div>
  </div>
</div>

<style>
  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
  }

  .grid-col {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
</style>
