<script>
  import axios from 'axios'
  import { createEventDispatcher } from 'svelte'

  import PostUser from './PostUser.svelte'
  import PostContent from './PostContent.svelte'
  import EmojiRow from './EmojiRow.svelte'

  const dispatch = createEventDispatcher()

  export let post
  export let emojis
  export let showPost = false

  const deletePost = () => {
    axios
      .delete(`/posts/${post.id}`)
      .then(() => {
        dispatch('postDeleted', post)
      })
      .catch((error) => {
        console.log('ERROR:', error)
      })
  }
</script>

<div class="feed-item {post.read_by_current_user ? 'unread' : 'read'}">
  <PostUser {post} />
  <div class="feed-item-content">
    <PostContent {post} on:deletePost={deletePost} />
    <div class="feed-item-reactions">
      <EmojiRow {post} {emojis} {showPost} />
    </div>
  </div>
</div>

<style>
  .feed-item {
    display: flex;
    gap: 2rem;
    padding: 10px 15px 10px 15px;
    background-color: #fff;
    border: 1px solid;
    border-color: #ddd;
    border-radius: 5px;
    margin-bottom: 5px;
  }

  .feed-item-content {
    flex: 1;
  }

  .feed-item.unread {
    border-color: #aacdff;
  }
</style>
