<script>
   import Icon from 'svelte-awesome';
  import arrowUp from 'svelte-awesome/icons/arrowUp';
  import arrowDown from 'svelte-awesome/icons/arrowDown';
  import minus from 'svelte-awesome/icons/minus';

  export let currentScore;
  export let priorScore;
  export let ignoreChanged = false;
</script>

{#if priorScore < currentScore}
  <Icon class="text-success" data={arrowUp} />
  Forbedret fra {priorScore}
{:else if priorScore > currentScore}
  <Icon class="text-danger" data={arrowDown} />
  Forringet fra {priorScore}
{:else if !ignoreChanged && priorScore === currentScore}
  <Icon class="text-muted" data={minus} />
  Uændret siden sidste rapport
{/if}
