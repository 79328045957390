import dayjs from 'dayjs'

export const formatData = (data) => {
  const aggregatedData = {}

  // Iterate through the input data and aggregate scores
  for (const entry of data) {
    const score = entry.score
    const type = entry.workday_type

    if (type == 'workday' || type == 'sickness_absense') {
      if (aggregatedData[score] === undefined) {
        aggregatedData[score] = 1
      } else {
        aggregatedData[score]++
      }
    }
  }

  // Convert the aggregated data into the desired format
  const outputData = Object.entries(aggregatedData).map(([score, count]) => ({
    name: getScoreName(score),
    data: [count],
    color: getScoreColor(score),
  }))

  return outputData
}

const getScoreName = (score) => {
  switch (parseInt(score)) {
    case 1:
      return 'Dårlig'
    case 2:
      return 'Lidt dårlig'
    case 3:
      return 'Ok'
    case 4:
      return 'Lidt god'
    case 5:
      return 'God'
    default:
      return 'Sygedage'
  }
}

export const getScoreColor = (score) => {
  switch (parseInt(score)) {
    case 1:
      return '#CA0000'
    case 2:
      return '#ECC600'
    case 3:
      return '#D9DFB8'
    case 4:
      return '#A9D95A'
    case 5:
      return '#00B425'
    default:
      return '#D490D3'
  }
}

const getNameColor = (name) => {
  switch (name) {
    case 'Dårlig':
      return '#CA0000'
    case 'Lidt dårlig':
      return '#ECC600'
    case 'Ok':
      return '#D9DFB8'
    case 'Lidt god':
      return '#A9D95A'
    case 'God':
      return '#00B425'
    case 'Sygedage':
      return '#D490D3'
  }
}

export const getDatesArray = (dateRange) => {
  const startDate = dayjs(dateRange.split('..')[0]).format('YYYY-MM-DD')
  const endDate = dayjs(dateRange.split('..')[1]).format('YYYY-MM-DD')
  const numberOfDays = dayjs(endDate).diff(dayjs(startDate), 'day') + 1
  const dates = []

  // loop from start date to end date
  for (let i = 0; i < numberOfDays; i++) {
    dates.push(dayjs(startDate).add(i, 'day').format('YYYY-MM-DD'))
  }

  return dates
}

export const formatDataV2 = (data) => {
  let interimData = data.map((item) => {
    return {
      name: getScoreName(item.score),
      data: {
        date: item.day,
        score: item.score,
      },
    }
  })
  
  let aggregatedData = {
    'Dårlig': { data: {}},
    'God': { data: {}},
    'Lidt dårlig': { data: {}},
    'Lidt god': { data: {}},
    'Ok': { data: {}},
    'Sygedage': { data: {}},
  }

  interimData.forEach((entry) => {
    const type = entry.name

    if (aggregatedData[type] === undefined) {
      aggregatedData[type] = {
        data: {
          [entry.data.date]: 1,
        },
      }
    } else if (aggregatedData[type].data[entry.data.date] === undefined) {
      aggregatedData[type].data[entry.data.date] = 1
    } else {
      aggregatedData[type].data[entry.data.date]++
    }
  })


  let formattedData = Object.entries(aggregatedData).map(([name, data]) => ({
    name: name,
    data: Object.entries(data.data).map(([date, count]) => ({
      x: date,
      y: count,
    })),
    color: getNameColor(name),
  }))

  return formattedData
}
