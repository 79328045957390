<script>
  import Form from './components/Form.svelte'
  import { useForm } from '@inertiajs/svelte'

  export let incidentReference
  export let companies

  let selectedCompanies = incidentReference.companies.map((c) => c.id)

  let form = useForm({
    incident_reference: {
      name: incidentReference.name,
      company_ids: [...selectedCompanies],
    },
  })

  async function handleSubmit() {
    const submitUrl = `/incident_references/${incidentReference.id}`

    await $form.patch(submitUrl, {
      preserveState: true,
      onError: () => {
        $form.errors
      },
    })
  }
</script>

<div class="row">
  <div class="col-xs-12 col-md-6 col-md-offset-3">
    <h1>Nyt Referencepunkt</h1>
  </div>
</div>

<br />

<Form {handleSubmit} {companies} bind:form update />
