<script>
  import { page } from '@inertiajs/svelte'
  import dayjs from 'dayjs'
  import {
    storedCompanies,
    storedTeams,
    startDate,
    endDate,
    storedSelectedCompany,
  } from '@/components/ReportsNavigation/store.js'
  import ReportMenu from '@/components/ReportsNavigation/ReportMenu.svelte'
  import ColumnChart from './components/ColumnChart.svelte'
  import DailyDistribution from './components/DailyDistribution.svelte'
  import ImpactGraph from './components/ImpactGraph.svelte'
  import AreaChart from './components/AreaChart.svelte'
  import { printPage } from '@/utils/helpers.js'

  export let scopedTeam = null
  export let scopedCompanies
  export let workdays_and_sickdays
  export let date_range
  export let incidentByImpact
  export let totalPerDay
  export let companies
  export let dateRange
  export let WdrDistributionData

  storedCompanies.set(companies)
  storedTeams.set(scopedTeam)

  if (scopedCompanies.length == 1) {
    $storedSelectedCompany = scopedCompanies[0].id
  } else {
    $storedSelectedCompany = null
  }

  $startDate = dateRange.start
  $endDate = dateRange.end

  const company = $page.props.auth.company
</script>

<div class="hidden-print">
  <ReportMenu displayDateSelection current_tab="anonymous" />
</div>
<div class="row">
  <div class="col-xs-12">
    <h2>Trivselsrapport</h2>
    {#if !$page.props.meta.app_client}
      <button class="btn btn-default pull-right" on:click={printPage}>
        <div class="glyphicon glyphicon-print" />
        Print rapport
      </button>
    {/if}
    <h4 class="text-muted">
      {#if scopedCompanies}
        {dayjs($startDate).format('ll')} - {dayjs($endDate).format('ll')} for {scopedCompanies[0]
          .name}
      {:else}
        {dayjs($startDate).format('ll')} - {dayjs($endDate).format('ll')} for {company.name}
        {#if scopedTeam}
          - {scopedTeam.name}
        {/if}
      {/if}
    </h4>
    <br />
    <h4>Fordeling i perioden</h4>
    <div class="text-center">
      {#if !company.weekly_worklife_rating}
        <ColumnChart data={WdrDistributionData} />
      {:else}
        Ugentlig trivselsrapport
      {/if}
    </div>
    <h4>Fordeling af registreringer per dag</h4>
    <div class="text-center">
      <DailyDistribution data={workdays_and_sickdays} dateRange={date_range} />
    </div>
    <h4>Fordeling af påvirkningsgrad</h4>
    <div class="text-center">
      <ImpactGraph data={incidentByImpact} dateRange={date_range} />
    </div>
    <h4>Bedømmelser pr. dag</h4>
    <div class="text-center">
      <AreaChart data={totalPerDay} dateRange={date_range} />
    </div>
  </div>
</div>
