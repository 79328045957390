<script>
  import ReviseKeyboard from './components/ReviseKeyboard.svelte'

  export let account
  export let moneyTypes

  $: money = moneyTypes

  const DKK = new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: account.balance.currency_iso,
  })

  $: total = 0
  $: differnce = total - account.balance.cents / 100

  const calculateTotal = () => {
    // Calculates the total amount of money
    total = money.reduce((acc, m) => {
      return m.count * m.amount + acc
    }, 0)
  }
</script>

<div class="container">
  <div class="row">
    <div class="text-center">
      <button class="btn btn-default btn-lg start-count-btn"
        >Afslut optælling</button
      >
    </div>
    <br />
  </div>
  <div class="row">
    <div class="col-xs-12 col-md-4 col-md-offset-4">
      <div class="money-type-grid">
        {#each money as m (m.amount)}
          <div class="col-xs-6">
            <button class="btn btn-default btn-block overview-btn">
              <span id="amount-{m.amount}" class="money-value">{m.count}</span>
              x {m.amount == 0.5 ? '50 øre' : `${m.amount} kr`}
            </button>
          </div>
        {/each}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-md-4 col-md-offset-4">
      <div class="text-right">
        <strong class="total">
          I alt: <span class="value">{DKK.format(total)}</span>
        </strong>
        <div class="balance">
          Forventet: {DKK.format(account.balance.cents / 100)}
        </div>
        <div class="difference {differnce == 0 ? '' : 'text-danger'}">
          Difference: <span class="value">{DKK.format(differnce)}</span>
        </div>
      </div>
    </div>
  </div>
  <br />
  <ReviseKeyboard bind:moneyTypes={money} on:updateAmount={calculateTotal} />
</div>

<style>
  .overview-btn {
    margin-bottom: 5px;
  }
</style>
