<script>
  import { defusingsForm } from '@/stores/DefusingFormStore'
</script>

<div class="text-center">
  <h2>Hvor lang tid var du væk fra din afdeling?</h2>
  <br />
  <br />
  <label for="timeRegistrationHours">
    <input
      type="number"
      id="timeRegistrationHours"
      name="timeRegistrationHours"
      min="0"
      max="24"
      step="1"
      class="form-control text-center"
      bind:value={$defusingsForm.time_registration_hours}
    />
    <div class="lead">Timer</div>
  </label>
  <label for="timeRegistrationMinutes">
    <input
      type="number"
      id="timeRegistrationMinutes"
      name="timeRegistrationMinutes"
      min="0"
      max="59"
      step="1"
      class="form-control text-center"
      bind:value={$defusingsForm.time_registration_minutes}
    />
    <div class="lead">Minutter</div>
  </label>
</div>
