<script>
  import { chart } from 'svelte-apexcharts'
  import da from 'apexcharts/dist/locales/da.json'
  import { getDatesArray, formatDataV2 } from '../dataHelper'
  import dayjs from 'dayjs'

  export let data
  export let dateRange

  let dates = getDatesArray(dateRange)
  let outputData = formatDataV2(data)
  let periodLength = dates.length

  let formattedData = [
    {
      name: 'Sygedage',
      data: [],
      color: '#D490D3',
    },
    {
      name: 'Dårlig',
      data: [],
      color: '#CA0000',
    },
    {
      name: 'Lidt dårlig',
      data: [],
      color: '#ECC600',
    },
    {
      name: 'Ok',
      data: [],
      color: '#D9DFB8',
    },
    {
      name: 'Lidt god',
      data: [],
      color: '#A9D95A',
    },
    {
      name: 'God',
      data: [],
      color: '#00B425',
    },
  ]

  dates.forEach((date) => {
    outputData.forEach((item) => {
      let found = item.data.find((d) => {
        return dayjs(d.x).isSame(dayjs(date), 'day')
      })
      if (found) {
        formattedData
          .find((f) => {
            return f.name === item.name
          })
          .data.push({ x: found.x, y: found.y })
      } else {
        formattedData
          .find((f) => {
            return f.name === item.name
          })
          .data.push({ x: date, y: 0 })
      }
    })
  })

  $: options = {
    chart: {
      locales: [da],
      defaultLocale: 'da',
      type: 'bar',
      stacked: true,
      height: 300,
      zoom: {
        enabled: false,
      },
      toolbar: {
        export: {
          png: {
            filename: `${dayjs(dates[0]).format('DD-MM-YYYY')}_${dayjs(
              dates[-1],
            ).format('DD-MM-YYYY')}_dagligfordeling`,
          },
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
      },
    },
    series: formattedData,
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 5,
    },
    legend: {
      formatter: function (val, _) {
        return val === 'Sygedage' ? 'Sygedage' : ''
      },
    },
    xaxis: {
      type: 'datetime',
      tickAmount: periodLength,
      labels: {
        formatter: function (_, timestamp) {
          return dayjs(timestamp).format('D. MMM')
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        formatter: function (value) {
          return value.toFixed(0)
        },
      },
    },
  }
</script>

<div use:chart={options} />
