<script>
  import { Icon, ExclamationCircle } from 'svelte-hero-icons'

  export let citizen = null
  export let flags = []
</script>

<div class="container">
  <h2>OBS punkter</h2>

  <div class="messages-container">
    {#each flags as flag}
      <div class="card">
        <div class="card-body">
          <div class="card-text flag-message-container">
            <div class="flag-message-icon">
              <Icon src={ExclamationCircle} size="24" />
            </div>
            <div>{flag.message}</div>
          </div>
        </div>
      </div>
    {/each}
    {#if flags.length === 0}
      {citizen.name} har ingen OBS punkter
    {/if}
  </div>
</div>

<style>
  .container {
    margin-top: 50px;
  }

  .flag-message-container {
    display: flex;
    gap: 10px;
  }

  .card {
    width: 30%;
    margin-bottom: 20px;
  }

  .flag-message-icon {
    color: #fe9f5b;
    font-weight: bold;
  }
</style>
