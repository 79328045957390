<script>
  import { page } from '@inertiajs/svelte'

  export let handleSubmit
  export let form
  export let companies
  export let update = false

  let currentCompany = $page.props.auth.company
</script>

<div class="row">
  <div class="col-xs-12 col-md-6 col-md-offset-3">
    <form on:submit|preventDefault={() => handleSubmit(form)}>
      <div class="form-group {$form.errors.name ? 'has-error' : ''}">
        <label for="name">Navn</label>
        <input
          type="text"
          class="form-control"
          id="name"
          bind:value={$form.incident_reference.name}
        />
        {#if $form.errors.name}
          <div>
            <span class="help-block">{$form.errors.name}</span>
          </div>
        {/if}
      </div>
      <div class="form-group">
        {#each companies as company}
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value={company.id}
              id="check-{company.id}"
              disabled={company.id == currentCompany.id}
              bind:group={$form.incident_reference.company_ids}
            />
            <label class="form-check-label" for="check-{company.id}">
              {company.name}
            </label>
          </div>
        {/each}
      </div>
      <div class="form-group">
        <input
          type="submit"
          class="btn btn-success pull-right"
          value="{update ? 'Opdater' : 'Opret'} Referencepunkt"
        />
      </div>
    </form>
  </div>
</div>
