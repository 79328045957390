<script>
  import ReportMenu from '@/components/ReportsNavigation/ReportMenu.svelte'
  import dayjs from 'dayjs'
  import ENpsPieChart from './ENpsPieChart.svelte'
  import ENpsColumnChart from './ENpsColumnChart.svelte'
  import Icon from 'svelte-awesome'
  import male from 'svelte-awesome/icons/male'
  import CompanySelect from './CompanySelect.svelte'

  export let scopedCompany
  export let npsSurvey
  export let lastFiveSurveys
  export let npsScoresByScore
  export let npsScores
  export let companies

  let score_colors = {
    detractor: '#E16F71',
    passive: '#FCDD4B',
    promoter: '#6DB97B',
  }

  let colors = Object.entries(score_colors).map(([score, color]) => {
    if (Object.keys(npsSurvey.score_percentages).includes(score)) {
      return color
    }
  })

  const npsScoresWithComments = () => {
    let scores = npsScores.filter((npsScore) => {
      return npsScore.comment
    })

    return scores.sort((a, b) => {
      return b.score - a.score
    })
  }
</script>

<ReportMenu />

<div class="row">
  <div class="col-xs-12">
    <h1>eNPS rapport for {scopedCompany.name}</h1>
    <h4 class="text-muted">
      {dayjs(npsSurvey.start_date).format('LL')} - {dayjs(
        npsSurvey.end_date,
      ).format('LL')}
    </h4>
  </div>
</div>

{#if companies.length > 1}
  <div class="row">
    <div class="col-xs-12">
      <h3>Vælg enhed</h3>
      <CompanySelect {companies} />
    </div>
  </div>
{/if}

<div class="row graph-container">
  <div class="col-xs-12 col-sm-6">
    <div class="text-center">
      <strong class="nps_score">eNPS Score</strong>
    </div>
    <ENpsPieChart {npsSurvey} {colors} />
  </div>
  <div class="col-xs-12 col-sm-6">
    <div class="text-center">
      <strong class="nps_score"> Sammenligning med seneste rapporter </strong>
    </div>
    <ENpsColumnChart {lastFiveSurveys} {colors} />
  </div>
</div>

<div class="row">
  <div class="col-xs-12">
    <div class="text-center lead text-muted">
      Hvor sandsynligt er det, at du vil anbefale {scopedCompany.name} til andre
      som et godt sted at arbejde?
    </div>
    <div class="nps-score-breakdown-container">
      <div class="nps-score-breakdown-cell first-column">
        <div class="nps-score-breakdown-cell-male first-column">
          <div class="text-right">Skala</div>
          <div class="text-right">Svar fordeling</div>
        </div>
        {#each Array(10) as _, i}
          <div class="nps-score-breakdown-cell-male">
            <div class="text-center">
              <div class="icon">
                <div class="label label-default">
                  {i + 1}
                </div>
              </div>
            </div>
            <br />
            {#if i + 1 >= 0 && i + 1 <= 6}
              <Icon data={male} class="nps-male male-red" />
            {:else if i + 1 >= 7 && i + 1 <= 8}
              <Icon data={male} class="nps-male male-yellow" />
            {:else}
              <Icon data={male} class="nps-male male-green" />
            {/if}
            <h3 class="text-center">
              {(npsScoresByScore[i + 1] || []).length}
            </h3>
          </div>
        {/each}
      </div>
    </div>
  </div>
</div>

<div class="row" style="margin-bottom: 7em">
  <div class="col-xs-12">
    <h1>Kommentarer</h1>
    {#each npsScoresWithComments() as score}
      <div class="panel panel-default">
        <div class="panel-body">
          <span class="icon">
            <div class="label label-default nps-{score.score_type}-background">
              {score.score}
            </div>
          </span>
          <strong></strong>
          {score.comment}
        </div>
      </div>
    {/each}
  </div>
</div>

<style>
  .nps_score {
    font-size: 20px;
  }

  .nps-score-breakdown-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }

  .nps-score-breakdown-cell {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    margin: 0 10px;
  }

  .nps-score-breakdown-cell-male {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .nps-score-breakdown-cell-male h3 {
    margin-bottom: 0;
  }

  .nps-score-breakdown-cell-male.first-column {
    align-items: flex-end;
  }

  .icon {
    margin-right: 10px;
    font-size: 16px;
  }
</style>
