<script>
  import { page } from '@inertiajs/svelte'
  import Post from '../Posts/components/Post.svelte'
  import UserAvatar from '@/components/UserAvatar.svelte'
  import axios from 'axios'

  export let posts = []
  export let emojis
  export let lastPage
  export let currentPage

  const currentUser = $page.props.auth.user
  $: loadingPosts = false
  $: localPosts = posts
  // $: localPage = currentPage
  // $: localLastPage = lastPage

  const handlePostDeleted = (e) => {
    posts = posts.filter((post) => post.id !== e.detail.id)
  }

  const loadMorePosts = async () => {
    loadingPosts = true
    axios
      .get(`/news_feed?page=${currentPage + 1}`, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        console.log(res.data)
        posts = [...posts, ...res.data.posts]
        currentPage = res.data.currentPage
        lastPage = res.data.lastPage
      })
      .finally(() => {
        loadingPosts = false
      })
  }
</script>

<div class="row">
  <div class="col-xs-12 col-md-8 col-md-offset-2">
    <ul class="menu-list">
      <li>
        <a href="/posts/new" class="menu-item">
          <span class="icon pull-left" style="width: 45px">
            <UserAvatar user={currentUser} size={35} />
          </span>
          <span class="text-muted"> Er der noget ny at dele? </span>
        </a>
      </li>
    </ul>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-md-8 col-md-offset-2">
    {#if posts.length > 0}
      <div class="infinite-content">
        <div class="content">
          {#each localPosts as post (post.id)}
            <Post
              {post}
              {emojis}
              on:postDeleted={(e) => handlePostDeleted(e)}
            />
          {/each}
        </div>
        <div class="pagination">
          {#if !lastPage}
            <button
              class="btn btn-default btn-block"
              disabled={loadingPosts}
              on:click={loadMorePosts}>Vis flere</button
            >
          {:else}
            <p>Ikke flere nyheder</p>
          {/if}
        </div>
      </div>
    {:else}
      <div class="text-center">
        <hr />
        <h3>
          <span style="font-size: 45px">😯</span>
          <br />
          Ingen nyheder fra
          <br />
          dine kolleger endnu
        </h3>
        <br />
        <br />
        <h4>
          Start ballet med at dele
          <br />
          en god nyhed ovenfor ☝️
        </h4>
        <hr />
      </div>
    {/if}
  </div>
</div>

<style>
  .pagination {
    width: 100%;
    text-align: center;
  }
</style>
