<script>
  import { router, page } from '@inertiajs/svelte'
  import axios from 'axios'
  import {
    Icon,
    ChevronLeft,
    ChevronRight,
    ArrowUturnLeft,
    Banknotes,
    XCircle,
    Calendar,
    ChatBubbleBottomCenter,
    DocumentDuplicate,
    DocumentMagnifyingGlass,
    Clock,
  } from 'svelte-hero-icons'
  import UserAvatar from '../../components/UserAvatar.svelte'
  import dayjs from 'dayjs'
  import DocumentUploadForm from './components/DocumentUploadForm.svelte'

  export let accountTransaction
  export let account
  export let citizen
  export let checkedBy
  export let canCheck
  export let documents

  const currentUser = $page.props.auth.user
  const canDelete = currentUser.id == accountTransaction.user.id
  const disableDelete =
    dayjs().diff(dayjs(accountTransaction.created_at), 'hour') > 24 ||
    accountTransaction.checked_user_id != null
  const disableDeleteDescription =
    'Du kan kun slette en postering, hvis den er oprettet inden for de sidste 24 timer og ikke er afstemt.'

  $: prior_transaction_on_account =
    accountTransaction.prior_transaction_on_account
  $: next_transaction_on_account =
    accountTransaction.next_transaction_on_account

  const goToTransaction = (transaction) => {
    router.visit(
      `/citizens/${citizen.id}/accounts/${account.id}/account_transactions/${transaction.id}`,
      {
        data: {
          account_transaction: transaction,
        },
      },
    )
  }

  const deleteTransaction = () => {
    // citizens/:citizen_id/accounts/:account_id/account_transactions/:id
    axios
      .delete(
        `/citizens/${citizen.id}/accounts/${account.id}/account_transactions/${accountTransaction.id}`,
      )
      .then(() => {
        window.location.href = `/citizens/${citizen.id}/accounts/${account.id}`
      })
      .catch((error) => {
        console.log('ERROR:', error)
      })
  }
</script>

<div class="row btn-row">
  <div>
    {#if prior_transaction_on_account}
      <button
        on:click={() => goToTransaction(prior_transaction_on_account)}
        class="btn btn-default btn-icon"
      >
        <Icon src={ChevronLeft} size="16" />
        Forrige
      </button>
    {/if}
  </div>
  <div>
    <a
      href="/citizens/{citizen.id}/accounts/{account.id}/"
      class="btn btn-default btn-icon"
    >
      <Icon src={ArrowUturnLeft} size="16" />
      Kontooverblik
    </a>
  </div>
  <div>
    {#if next_transaction_on_account}
      <button
        on:click={() => goToTransaction(next_transaction_on_account)}
        class="btn btn-default btn-icon"
      >
        Næste
        <Icon src={ChevronRight} size="16" />
      </button>
    {/if}
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-md-6 col-md-offset-3 text-center">
    <h1 class="heading">
      {accountTransaction.name}
      <small
        >(
        {#if accountTransaction.revision}
          Afstemning
        {:else}
          {accountTransaction.amount_cents >= 0 ? 'Indbetalt' : 'Udbetalt'}
        {/if})
      </small>
    </h1>
  </div>
</div>
<br />
<div class="row">
  <div class="col-xs-12 col-md-6 info">
    <div class="info-row">
      <div class="info-row_icon">
        <Icon src={Banknotes} size="32" />
      </div>
      <div class="info-row_details">
        {#if account.account_type == 'physical'}
          <div class="info-row_details-item">
            {#if accountTransaction.revision}
              <strong class="item_desc">Difference</strong>
            {:else}
              <strong class="item_desc">Beløb</strong>
            {/if}
            <span
              class="item_info {accountTransaction.amount_cents >= 0
                ? 'text-success'
                : 'text-danger'}"
            >
              {accountTransaction.amount_cents >= 0 ? '+' : ''}{(
                accountTransaction.amount_cents / 100
              ).toLocaleString('da-DK', {
                style: 'currency',
                currency: account.balance.currency_iso,
              })}
            </span>
          </div>
        {/if}
        <div class="info-row_details-item">
          <strong class="item_desc"
            >{account.account_type == 'physical' ? 'Ny saldo' : 'Saldo'}</strong
          >
          <span class="item_info">
            {(accountTransaction.current_balance_cents / 100).toLocaleString(
              'da-DK',
              {
                style: 'currency',
                currency: account.balance.currency_iso,
              },
            )}
          </span>
        </div>
      </div>
    </div>
    <div class="info-row">
      <div class="info-row_icon">
        <UserAvatar user={accountTransaction.user} size="35" />
      </div>
      <div class="info-row_details">
        <div class="info-row_details-item">
          <strong class="item_desc"
            >{accountTransaction.revision
              ? 'Afstemt af'
              : 'Oprettet af'}</strong
          >
          <span class="item_info">{accountTransaction.user.name}</span>
        </div>
        <div class="info-row_details-item">
          <strong class="item_desc">Dato</strong>
          <span class="item_info"
            >{dayjs(accountTransaction.created_at).format('ll, LT')}</span
          >
        </div>
      </div>
    </div>
    <div class="info-row">
      <div class="info-row_icon">
        {#if checkedBy}
          <UserAvatar user={checkedBy} size="35" />
        {:else}
          <Icon src={XCircle} size="32" />
        {/if}
      </div>
      <div class="info-row_details">
        <div class="info-row_details-item">
          {#if checkedBy}
            <strong class="item_desc">Godkendt af:</strong>
            <span class="item_info">{checkedBy.name}</span>
          {:else}
            <strong class={canCheck ? 'item_desc' : ''}>Ikke godkendt</strong>
          {/if}
          {#if !checkedBy && canCheck}
            <a
              href="/citizens/{citizen.id}/accounts/{account.id}/account_transactions/{accountTransaction.id}/confirm_check_prompt"
              class="item_info">Godkend</a
            >
          {/if}
        </div>
      </div>
    </div>
    <div class="info-row">
      <div class="info-row_icon">
        <Icon src={Calendar} size="32" />
      </div>
      <div class="info-row_details">
        <div class="info-row_details-item">
          <strong class="item_desc"
            >{accountTransaction.revision
              ? 'Afstemingsdato'
              : 'Kvitteringsdato'}</strong
          >
          <span class="item_info"
            >{dayjs(accountTransaction.datetime).format('ll, LT')}</span
          >
        </div>
      </div>
    </div>
    {#if accountTransaction.description}
      <div class="info-row">
        <div class="info-row_icon">
          <Icon src={ChatBubbleBottomCenter} size="32" />
        </div>
        <div class="info-row_details">
          <div class="info-row_details-item">
            <strong class="item_desc">Kommentar</strong>
            <span class="item_info">{accountTransaction.description}</span>
          </div>
        </div>
      </div>
    {/if}
    {#if canDelete}
      <button
        class="btn btn-danger"
        on:click={deleteTransaction}
        disabled={disableDelete}>Slet Postering</button
      >
      {#if disableDelete}
        <small class="text-muted text-center">{disableDeleteDescription}</small>
      {/if}
    {/if}
  </div>
  <div class="col-xs-12 col-md-6 documents">
    <div class="heading">
      <h3 class="pull-left">
        <Icon src={DocumentDuplicate} size="24" />
        Bilag
      </h3>
      <div class="pull-right">
        <DocumentUploadForm {accountTransaction} {account} {citizen} discrete />
      </div>
    </div>
    <div class="documents-preview">
      {#if documents.length > 0}
        {#each documents as document}
          <div class="image-and-text">
            <a
              href="/account_transactions/{accountTransaction.id}/documents/{document.id}"
            >
              {#if document['thumbable?']}
                <img
                  class="img-responsive img-thumbnail"
                  src="/account_transactions/{accountTransaction.id}/documents/{document.id}/file/thumb"
                  alt=""
                />
              {:else}
                <img
                  class="img-responsive img-thumbnail"
                  src="/assets/document-fallback/default.jpg"
                />
              {/if}
            </a>
            <div class="image-date img-thumbnail">
              <Icon src={Clock} size="16" />
              <small>{dayjs(document.created_at).format('lll')}</small>
            </div>
          </div>
        {/each}
      {:else}
        <div class="text-center text-muted">
          <h3>Der er ikke tilføjet nogen bilag endnu.</h3>
          <Icon src={DocumentMagnifyingGlass} size="100" />
        </div>
      {/if}
    </div>
  </div>
</div>

<style>
  .btn-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    justify-items: stretch;
  }

  .btn-row div {
    display: flex;
    justify-content: center;
    min-width: 33%;
  }

  .btn-row div:first-child {
    justify-content: flex-start;
  }

  .btn-row div:last-child {
    justify-content: flex-end;
  }
  .btn-icon {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .heading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 0;
  }

  .documents .heading {
    justify-content: space-between;
  }

  .documents .heading h3 {
    margin: 0;
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .info-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }

  .info-row_details {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .info-row_details-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
  }

  .item_desc {
    display: flex;
    flex: 1;
    align-items: baseline;
  }

  .item_desc::after {
    margin: 0 0.5rem;
    content: '';
    border-bottom: 1px dotted;
    flex: 1;
    display: flex;
  }

  .item_info {
    align-items: baseline;
    flex: 1;
  }

  .img-thumbnail {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 4px;
    line-height: 1.42857143;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .documents-preview {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .image-and-text {
    position: relative;
    width: 45%;
    height: 45%;
  }

  .image-date {
    background-color: white;
    position: absolute;
    right: 0.1rem;
  }
</style>
