<script>
  import { chart } from 'svelte-apexcharts'
  import dayjs from 'dayjs'

  export let data

  let options = {
    chart: {
      type: 'bar',
      stacked: true,
      height: 350,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '80%',
        endingShape: 'rounded',
      },
    },
    series: data.series,
    xaxis: {
      type: data.categories ? 'category' : 'datetime',
      categories: data.categories || [],
      tickAmount: data.categories ? data.categories.length : data.series.length,
      tickPlacement: 'on',
      labels: {
        show: true,
        hideOverlappingLabels: true,
        showDuplicates: false,
        rotate: data.categories ? -45 : false,
        rotateAlways: data.categories ? true : false,
        formatter: function (value) {
          return data.categories ? value : dayjs(value).format('MMMM YYYY')
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: data.categories ? true : false,
    },
  }
</script>

<div use:chart={options} />
