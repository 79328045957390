<script>
  import { page } from '@inertiajs/svelte'
  import { hasComment } from '@/utils/wdr_helper'
  import { Icon, ChatBubbleBottomCenterText } from 'svelte-hero-icons'

  export let wdr
  export let size = 'md'

  const verified_login = $page.props.auth.verified_login

  let cssClass = ''

  switch (wdr.workday_type) {
    case 'workday':
      cssClass = `score-${wdr.score}`
      break
    default:
      cssClass = `workday-type-${wdr.workday_type}`
      break
  }
</script>

{#if verified_login}
  {#if hasComment(wdr)}
    <span class="label label-default {cssClass} {size}">
      <div class="label-icon">
        <Icon src={ChatBubbleBottomCenterText} size="20" />
      </div>
    </span>
  {:else}
    <span class="label label-default {cssClass}">&nbsp;&nbsp;</span>
  {/if}
{:else}
  <span class="label label-primary glyphicon glyphicon-ok">&nbsp;&nbsp;</span>
{/if}

<style>
  span {
    display: inline-block;
    margin-right: 3px;
    color: black;
    text-align: center;
    vertical-align: middle;
  }

  span .md {
    line-height: 25px;
    width: 25px;
    height: 25px;
    padding: 2px;
  }

  span .lg {
    line-height: 40px;
    width: 40px;
    height: 40px;
    padding: 5px;
  }

  .label-icon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .score-1 {
    background-color: #ca0000 !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
  .score-2 {
    background-color: #ecc600 !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  .score-3 {
    background-color: #d9dfb8 !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  .score-4 {
    background-color: #a9d95a !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  .score-5 {
    background-color: #00b425 !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  .no-score {
    background-color: #428bca !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  .workday-type-day_off,
  .workday-type-week_off {
    background-color: #00dfeb !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  .workday-type-sickness_absense {
    background-color: #d490d3 !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
</style>
