<script>
  import dayjs from 'dayjs'
  import {
    Icon,
    PlusCircle,
    ChevronLeft,
    XCircle,
    CheckCircle,
    ArrowPath,
  } from 'svelte-hero-icons'
  import './floweffect.css'
  import { boardStore } from '@/stores/TaskBoardStore'
  import { userStore, getUserById } from '@/stores/UserStore'
  import { citizenStore } from '@/stores/CitizenStore'
  import Modal from '@/components/Modal.svelte'
  import TaskForm from './components/TaskForm.svelte'
  import Task from './components/Task.svelte'
  import CheckInModal from './components/CheckInModal.svelte'
  import TaskGrabbingUserModal from './components/TaskGrabbingUserModal.svelte'
  import TaskBoard from './components/TaskBoard.svelte'
  import { headerTemplate } from './components/headerTemplate'
  import { taskTemplate } from './components/taskTemplate'
  import DateSelection from './components/DateSelection.svelte'
  import BoardSelection from './components/BoardSelection.svelte'
  import { getUrlTimeParams } from './utils'
  import { modalStore as userGrabbingModalStore } from '@/stores/ModalStore'
  import axios from 'axios'
  import CitizenObjectives from './components/CitizenObjectives.svelte'
  import CitizenFlagMessages from './components/CitizenFlagMessages.svelte'

  export let citizens
  export let tasks
  export let allUsers
  export let citizenSelected

  let showNewTaskModal = false
  let showCheckInModal = false

  function updateTaskBoard() {
    showNewTaskModal = false
    let paramString = '?'
    paramString +=
      'start_time=' +
      dayjs().startOf('day') +
      '&end_time=' +
      dayjs().endOf('day')
    paramString += citizenSelected ? '&citizen_id=' + citizenSelected.id : ''

    axios.get('/tasks' + paramString).then((response) => {
      $boardStore.tasks = response.data

      let newTasks = []
      response.data.forEach((task) => {
        newTasks.push(formattedTaskData(task))
      })
      $boardStore.boardTasks = newTasks
    })
  }

  function handleTaskGrabbingButtonClick() {
    if ($boardStore.taskGrabbingActivated) {
      postGrabbedTasks()
    } else {
      userGrabbingModalStore.openModal()
    }
  }

  function postGrabbedTasks() {
    let grabbedTasksData = { tasks: { user_id: null, task_ids: null } }
    let taskIds = []
    $boardStore.grabbedTasks.forEach((taskId) => {
      taskIds.push($boardStore.boardTasks.find((t) => t.id === taskId).task_id)
    })

    grabbedTasksData.tasks.user_id = $boardStore.userGrabbingTasks.id
    grabbedTasksData.tasks.task_ids = taskIds

    axios
      .post('/floweffect/tasks/grab_tasks', grabbedTasksData)
      .then(() => {
        updateTaskBoard()
        boardStore.resetGrabbedTasks()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function taskClass(task) {
    let classes = []

    if (task.completed_at != null) {
      classes.push('done')
    } else if (
      dayjs(task.start_time) < dayjs() &&
      dayjs(task.end_time) < dayjs()
    ) {
      classes.push('late')
    }

    if ($boardStore.taskGrabbingActivated && task.user_id != null) {
      classes.push('not-grabbing')
    }

    if ($boardStore.grabbedTasks.includes(task.id)) {
      classes.push('grabbing')
    }

    return classes.join(' ')
  }

  function formattedTaskData(task) {
    return {
      id: task.id,
      resourceId: task.citizen_id,
      from: dayjs(task.start_time),
      to: dayjs(task.end_time),
      html: taskTemplate({
        citizen: getUserById(task.user_id),
        label: task.name,
        start_time: task.start_time,
        end_time: task.end_time,
      }),
      classes: taskClass(task),
      label: task.name,
      name: task.name,
      task_id: task.id,
      card_type: 'citizen',
      recurring: task.recurring,
      user_id: task.user_id,
    }
  }
  function formattedCitizenData(citizen) {
    return {
      id: citizen.id,
      headerHtml: headerTemplate(
        citizen,
        citizen.flag_messages,
        citizenSelected
          ? `/citizen_board${getUrlTimeParams($boardStore)}`
          : `/citizen_board${getUrlTimeParams($boardStore)}&citizen_id=${
              citizen.id
            }`,
      ),
    }
  }

  function updateFormattedTasks() {
    let newTasks = []
    $boardStore.tasks.forEach((task) => {
      newTasks.push(formattedTaskData(task))
    })
    console.log('NEW TASKS', newTasks)
    $boardStore.boardTasks = newTasks
  }

  function initializeData() {
    let formattedCitizens = []
    let formattedTasks = []
    $userStore = allUsers
    $citizenStore = citizens

    citizens.forEach((citizen) => {
      formattedCitizens.push(formattedCitizenData(citizen))
    })

    tasks.forEach((task) => {
      formattedTasks.push(formattedTaskData(task))
    })

    $boardStore.rows = formattedCitizens
    $boardStore.tasks = tasks
    $boardStore.boardTasks = formattedTasks
    $userStore = allUsers
    $citizenStore = citizens
  }

  initializeData()

  function closeModal() {
    showNewTaskModal = false
  }

  $: console.log($boardStore)
</script>

<!-- Board selection -->
<BoardSelection board={'citizen'} />
<div
  style="display: flex; justify-content: {citizenSelected
    ? 'space-between'
    : 'right'}"
>
  {#if citizenSelected}
    <div style="padding-left: 20px;">
      <a
        href="/citizen_board{getUrlTimeParams($boardStore)}"
        class="btn btn-default"
      >
        <div class="glyphicon glyphicon-chevron-left" />
        Tilbage til oversigt
      </a>
    </div>
  {/if}
  <DateSelection
    startTime={$boardStore.startTime}
    endTime={$boardStore.endTime}
    on:updateTaskBoard={updateTaskBoard}
  />
</div>

<!-- Gantt chart -->
<TaskBoard
  on:updateTaskBoard={updateTaskBoard}
  on:newTaskGrabbed={() => {
    updateFormattedTasks()
  }}
/>

{#if citizenSelected}
  <CitizenFlagMessages
    citizen={citizenSelected}
    flags={citizenSelected.flag_messages}
  />
  <CitizenObjectives
    citizen={citizenSelected}
    objectives={citizenSelected.objectives}
  />
{/if}

<!-- Green corner buttons -->
<div class="corner-btns">
  {#if true}
    <button
      class="corner-btn green-corner-btn"
      on:click={() => {
        handleTaskGrabbingButtonClick()
      }}
    >
      {#if $boardStore.grabbedTasks.length > 0}
        <Icon src={CheckCircle} size="36" />
        Gem {$boardStore.grabbedTasks.length} opgaver
      {:else}
        <Icon src={PlusCircle} size="36" />
        Tag opgaver
      {/if}
    </button>
  {/if}
  {#if $boardStore.taskGrabbingActivated}
    <button
      class="corner-btn red-corner-btn"
      on:click={() => {
        $boardStore.taskGrabbingActivated = false
        boardStore.resetGrabbedTasks()
        updateFormattedTasks()
      }}
    >
      <Icon src={XCircle} size="36" />
      Annuller
    </button>
  {/if}
  <button
    class="corner-btn green-corner-btn new-task-btn"
    on:click={() => {
      showNewTaskModal = !showNewTaskModal
    }}
  >
    <Icon src={PlusCircle} size="36" />
    Ny opgave
  </button>
  <button
    class="corner-btn green-corner-btn check-in-btn"
    on:click={() => {
      showCheckInModal = !showCheckInModal
    }}
  >
    <Icon src={CheckCircle} size="36" />
    Tjek ind / ud
  </button>
</div>

<!-- Modals -->
<Modal bind:showModal={showNewTaskModal}>
  <span slot="header">Opret ny opgave</span>

  <TaskForm {closeModal} on:updateTaskBoard={updateTaskBoard} />
</Modal>

<Modal bind:showModal={$boardStore.showTaskModal}>
  <div slot="header">
    <div>
      Opgave: {$boardStore.selectedTask.name}
      {#if $boardStore.selectedTask.recurring}
        <span class="badge badge-pill badge-primary">
          <Icon src={ArrowPath} size="12" />
          Gentagende</span
        >
      {/if}
    </div>
  </div>

  <Task on:updateTaskBoard={updateTaskBoard} />
</Modal>

<Modal bind:showModal={showCheckInModal}>
  <span slot="header">Tjek ind / ud</span>

  <CheckInModal
    users={allUsers}
    bind:showModal={showCheckInModal}
    on:updateTaskBoard={updateTaskBoard}
  />
</Modal>

<Modal bind:showModal={$userGrabbingModalStore.showModal}>
  <span slot="header">Hvem skal sættes på opgaverne?</span>
  <TaskGrabbingUserModal on:updateTasks={updateFormattedTasks} />
</Modal>

<style>
  .corner-btn {
    z-index: 100;
    border: none;
    background-color: #64cc7c;
    color: white;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 20px;
  }

  .green-corner-btn:hover,
  .green-corner-btn:active {
    background-color: #4e9e5d;
  }

  .corner-btns {
    display: flex;
    gap: 1rem;
    position: fixed;
    bottom: 5rem;
    right: 5rem;
    z-index: 100;
  }

  .red-corner-btn {
    background-color: #f92121;
  }
</style>
