<script>
  import CreatePlan from './CreatePlan.svelte'
  import { currentPage, pageCount } from '@/stores/IncidentPlanStore'
  import { router } from '@inertiajs/svelte'
  import ProgressBar from '../../components/ProgressBar.svelte'

  export let selectedIncidents = {}
  let progressBar

  $: currentActive = $currentPage + 1

  if (Object.keys(selectedIncidents).length == 0) {
    router.replace('/incident_plans/select_incidents?anonymous=true')
  }

  $pageCount = Object.keys(selectedIncidents).length
</script>

<div class="flex-container">
  {#each Object.entries(selectedIncidents) as [group, incidents], index}
    <CreatePlan {group} {incidents} {index} bind:progressBar />
  {/each}
  <div class="controls">
    <ProgressBar
      steps={[...Array($pageCount).keys()]}
      bind:currentActive
      bind:this={progressBar}
    />
    <h4 class="text-center">{$currentPage + 1} / {$pageCount}</h4>
  </div>
</div>

<style>
  .flex-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 2rem;
  }

  .controls {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
</style>
