<script>
  import SmallLoadingSpinner from '@/components/SmallLoadingSpinner.svelte'

  export let variant = 'default'
  export let width = 'auto'
  export let submitting = false
  export let size = 'md'
  export let disabled = false
</script>

<button
  class="button button-{variant} button-{size}"
  style="Width: {width};"
  {disabled}
>
  {#if submitting}
    <SmallLoadingSpinner />
  {:else}
    <slot />
  {/if}
</button>

<style>
  /* General styling */
  .button {
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
  }

  button:disabled {
    cursor: not-allowed;
    color: #6c757d;
  }

  /* Variants */
  .button-default {
    background-color: #ececec;
  }

  .button-default:hover {
    background-color: #dddcdc;
  }

  .button-primary {
    background-color: #007bff;
    color: white;
  }
  .button-primary:hover {
    background-color: #0069d9;
  }
  .button-danger {
    background-color: #dc3545;
    color: white;
  }
  .button-danger:hover {
    background-color: #b02a37;
  }
  .button-success {
    background-color: #198754;
    color: white;
  }
  .button-success:hover {
    background-color: #157347;
  }
  .button-warning {
    background-color: #ffc107;
    color: black;
  }
  .button-warning:hover {
    background-color: #d39e00;
  }

  /* Sizes */
  .button-sm {
    font-size: 1em;
    padding: 0.25em 0.5em;
  }
</style>
