<script>
  import ReportMenu from '@/components/ReportsNavigation/ReportMenu.svelte'
  import LineChart from './charts/LineChart.svelte'
  import ColumnChart from './charts/ColumnChart.svelte'
  import { formatForChart } from '@/utils/helpers.js'
  import DataRangeSelection from '@/components/ReportsNavigation/DateRangeSelection.svelte'
  import { startDate, endDate } from '@/components/ReportsNavigation/store.js'
  import dayjs from 'dayjs'
  import IncidentsWithComments from './components/IncidentsWithComments.svelte'
  import EventCategories from './components/EventCategories.svelte'
  import MostImpactedUsers from './components/MostImpactedUsers.svelte'
  import FewestRegistrations from './components/FewestRegistrations.svelte'
  import Section from '../../components/Section.svelte'

  export let incidentCountByMonth
  export let highImpactColumnChart
  export let eventCategoriesByImpact
  export let incidentsWithComments
  export let incidentsWithCommentsCount
  export let mostImpactedUsers
  export let usersWithFewestRegistrations
  export let usersWithNoIncidentsRegistered

  let start_date
  let end_date

  startDate.subscribe((value) => {
    start_date = dayjs(value).format('YYYY-MM-DD')
  })
  endDate.subscribe((value) => {
    end_date = dayjs(value).format('YYYY-MM-DD')
  })
</script>

<ReportMenu current_tab={'incident_overall'} />

<Section title="Hændelsesudvikling">
  <div slot="subtitle">Seneste 6 måneder</div>

  <div class="row">
    <div class="col-xs-12">
      <h3>Antal hændelser per måned</h3>
      <LineChart data={formatForChart(incidentCountByMonth)} name="Hændelser" />
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <h3>Antal hændelser med høj påvirkning</h3>
      <h4 class="text-muted">Påvirkning 4 og 5</h4>
      <ColumnChart data={highImpactColumnChart} name="Hændelser" />
    </div>
  </div>
</Section>
<hr />

<Section title="Hændelser">
  <div slot="subtitle">
    {dayjs(start_date).format('ll')} - {dayjs(end_date).format('ll')}
  </div>
  <div slot="rightHeader"><DataRangeSelection /></div>
  <div class="subsections">
    <EventCategories {eventCategoriesByImpact} />
    <IncidentsWithComments
      {incidentsWithComments}
      {incidentsWithCommentsCount}
    />
    <MostImpactedUsers {mostImpactedUsers} />
    <FewestRegistrations
      {usersWithFewestRegistrations}
      {usersWithNoIncidentsRegistered}
    />
  </div>
</Section>

<style>
  .subsections {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
</style>
