<script>
  import Incident from '../Events/components/Incident.svelte'
  import { selectedIncidents } from '@/stores/IncidentPlanStore.js'
  import { router } from '@inertiajs/svelte'
  import DateRangeSelect from './components/DateRangeSelect.svelte'
  import axios from 'axios'

  export let incidents = []
  export let anonymous = true

  const handleSubmit = () => {
    // Go to create plan page
    console.log($selectedIncidents)
    router.get('/incident_plans/new', {
      selected_incidents: $selectedIncidents,
    })
  }

  const handleChange = (e) => {
    if (e.target.checked) {
      e.target.parentNode.classList.add('active')
    } else {
      e.target.parentNode.classList.remove('active')
    }
  }

  const toggleCheckbox = (id) => {
    const checkbox = document.querySelector('input#incident-' + id)
    checkbox.click()
    handleChange({ target: checkbox })
    console.log(checkbox)
  }

  const updateIncidents = (e) => {
    axios
      .get(
        '/events.json',
        {
          params: {
            start_date: e.startDate,
            end_date: e.endDate,
          },
        },
        {
          headers: {
            Accept: 'application/json',
          },
        },
      )
      .then((res) => {
        incidents = res.data
      })
  }
</script>

<div class="row">
  <div class="col-xs-12 col-md-6 col-md-offset-3 text-center">
    <h2>Udvælg Hændelser</h2>
    <DateRangeSelect on:dateChange={(e) => updateIncidents(e.detail)} />
  </div>
</div>

<div class="row">
  {#if incidents.length > 0}
    {#each incidents as incident}
      <div class="form-group">
        <label for="incident-{incident.id}">
          <input
            type="checkbox"
            class="user-checkbox"
            id="incident-{incident.id}"
            name="incident_ids[]"
            bind:group={$selectedIncidents}
            value={incident.id}
            on:change={handleChange}
          />
          <Incident
            {incident}
            {anonymous}
            forChecklist
            forChecklistToggle={toggleCheckbox}
            forChecklistSelected={$selectedIncidents.includes(incident.id)}
          />
        </label>
      </div>
    {/each}

    <button
      on:click={handleSubmit}
      class="btn btn-default btn-block"
      disabled={$selectedIncidents.length == 0}>Videre</button
    >
  {:else}
    <div class="col-xs-12 col-md-6 col-md-offset-3 text-center">
      <div class="alert alert-info">
        <p>Der er ingen hændelser i den valgte periode.</p>
      </div>
    </div>
  {/if}
</div>

<style>
  label {
    display: block;
    width: 100%;
  }

  input[type='checkbox'] {
    display: none;
  }

  button {
    margin-top: 20px;
    margin-bottom: 3rem;
  }
</style>
