<script>
  import { page } from '@inertiajs/svelte'
  import UserLink from './components/UserLink.svelte'

  export let teams
  export let usersWithNoTeam
  export let users
  export let usersWithTeam
  export let sso_login_enabled
  export let floweffect_module_enabled

  const company = $page.props.auth.company
</script>

<h2>{company.name}</h2>
<div class="flex">
  <a href="/user_onboarding/welcome" class="btn btn-lg btn-default">
    <div class="glyphicon glyphicon-plus" />
    Opret ny bruger
  </a>
  {#if floweffect_module_enabled}
    <a
      href="/citizen_board"
      class="btn btn-lg btn-default"
      style="margin-top: 20px; margin-bottom: 20px;">Gå til FlowEffect</a
    >
  {/if}
</div>
<br />
{#if teams.length > 0}
  <h4 class="text-muted">Gå til team</h4>
  <div class="team-links">
    {#each teams as team}
      <a href="/session/new?team_id={team.id}" class="btn btn-primary">
        {team.name}
      </a>
    {/each}
  </div>
  <hr />

  {#each teams as team}
    <h2>{team.name}</h2>
    <div class="users">
      {#each team.active_users as user}
        <UserLink {user} {sso_login_enabled} />
      {/each}
    </div>
  {/each}
  {#if usersWithNoTeam.length > 0}
    <h2>Andre</h2>
    <div class="users">
      {#each usersWithNoTeam as user}
        <UserLink {user} {sso_login_enabled} />
      {/each}
    </div>
  {/if}
{:else}
  <div class="users">
    {#each users as user}
      <UserLink {user} {sso_login_enabled} />
    {/each}
  </div>
{/if}

{#if $page.props.meta.app_client}
  <div class="text-center">
    <a href="/i" class="btn btn-lg btn-primary"> Skift arbejdsplads </a>
  </div>
{/if}

<style>
  .team-links {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    column-gap: 10px;
    row-gap: 10px;
  }

  .users {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    column-gap: 20px;
    row-gap: 20px;
    justify-items: center;
  }
</style>
