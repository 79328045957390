<script>
  import { createEventDispatcher } from 'svelte';
  import {hasComment} from '@/utils/wdr_helper.js';
  import dayjs from 'dayjs';
  import UserReportWorkdayComments from './UserReportWorkdayComments.svelte';

  export let wdr;

  const dipsatch = createEventDispatcher();

  const openWorkdayModal = () => {
    dipsatch('open_wdr', {
      date: dayjs(wdr.day).format('YYYY-MM-DD'),
      workday: wdr,
    });
  };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div class="media" on:click={openWorkdayModal}>
  <div class="media-left">
    <div class="label label-default score-{wdr.score}">&nbsp;&nbsp;</div>
  </div>
  <div class="media-body">
    <div class="text-muted">
      {dayjs(wdr.day).format('dddd [d.] D MMMM')}
    </div>
    <UserReportWorkdayComments {wdr} />
    {#if wdr.emotion}
      {#if hasComment(wdr)}
        <br />
      {/if}
      <div class="text-muted">
        {wdr.user.name} følte sig: {wdr.emotion.emoji}
      </div>
    {/if}
  </div>
</div>

<style>
  .media {
    min-height: 40px;
  }

  .media:hover {
    cursor: pointer;
  }
  .label {
    padding: 10;
  }
</style>
