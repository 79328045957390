<script>
  import { onMount } from 'svelte'
  import { storedCompanies, storedTeams } from './store.js'
  import { page } from '@inertiajs/svelte'
  import dayjs from 'dayjs'
  import AirDatepicker from 'air-datepicker'
  import localeDa from 'air-datepicker/locale/da'
  import 'air-datepicker/air-datepicker.css'
  import UnitSelect from './UnitSelect.svelte'
  import { startDate, endDate } from './store.js'

  $: start_picker = null
  $: end_picker = null

  onMount(() => {
    end_picker = new AirDatepicker('#end_date', {
      dateFormat: 'dd/MM/yyyy',
      locale: localeDa,
      selectedDates: [$endDate],
      maxDate: dayjs(),
      onSelect: (date) => {
        start_picker.update({
          maxDate: date.date,
        })
      },
    })
    start_picker = new AirDatepicker('#start_date', {
      dateFormat: 'dd/MM/yyyy',
      locale: localeDa,
      selectedDates: [$startDate],
      maxDate: end_picker.selectedDates[0] || dayjs(),
      onSelect: (date) => {
        end_picker.update({
          minDate: date.date,
        })
      },
    })
    $startDate = dayjs($startDate).format('YYYY-MM-DD')
    $endDate = dayjs($endDate).format('YYYY-MM-DD')
  })

  const dateSelectionWidth = $page.props.meta.app_client ? '' : '90px'

  function handleSubmit() {
    $startDate = dayjs(start_picker.selectedDates[0]).format('YYYY-MM-DD')
    $endDate = dayjs(end_picker.selectedDates[0]).format('YYYY-MM-DD')
    const url = new URL(location.href)
    url.searchParams.set('start_date', $startDate)
    url.searchParams.set('end_date', $endDate)

    location.assign(url.search)
  }
</script>

<form class="form-inline pull-right" on:submit|preventDefault={handleSubmit}>
  {#if $storedCompanies && $storedCompanies.length > 1}
    <UnitSelect units={$storedCompanies} type="company" />
  {/if}
  <div class="form-group form-group-sm">
    <div class="input-group">
      <div class="input-group-addon">Fra</div>
      <input
        type="text"
        id="start_date"
        class="form-control from-period-pick"
        name="start_date"
        style="width: {dateSelectionWidth};"
      />
    </div>
  </div>
  <div class="form-group form-group-sm">
    <div class="input-group">
      <div class="input-group-addon">Til</div>
      <input
        type="text"
        id="end_date"
        class="form-control from-period-pick"
        name="end_date"
        style="width: {dateSelectionWidth};"
      />
    </div>
  </div>
  <div class="form-group form-group-sm">
    <button
      type="submit"
      class="btn btn-default btn-sm"
      data-behavior="date-range-submit">Opdatér</button
    >
  </div>
</form>
