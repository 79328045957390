<script>
  import { onMount, onDestroy } from 'svelte'
  import { boardStore } from '@/stores/TaskBoardStore'
  import axios from 'axios'
  import { createEventDispatcher } from 'svelte'
  import UserAvatar from '@/components/UserAvatar.svelte'
  import Button from '@/components/Button.svelte'
  import UserCheckInOptions from './UserCheckInOptions.svelte'
  export let users

  let userSelected = null
  let checkIns = []
  let loading = true
  let tasks = []

  const dispatch = createEventDispatcher()

  onMount(() => {
    $boardStore.safeToReload = false
  })

  onDestroy(() => {
    $boardStore.safeToReload = true
  })

  function resetUser() {
    userSelected = null
  }

  function updateCheckIns() {
    axios
      .get(
        '/check_in_logs' +
          `?start_time=${$boardStore.startTime}&end_time=${$boardStore.endTime}`,
      )
      .then((response) => {
        checkIns = response.data
        loading = false
        dispatch('updateUsers')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function updateTasks() {
    axios
      .get(
        '/tasks' +
          `?start_time=${$boardStore.startTime}&end_time=${$boardStore.endTime}`,
      )
      .then((response) => {
        tasks = response.data
        loading = false
        dispatch('updateTaskBoard')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function getCheckInStatus(check, tas, user) {
    let checkIn = getCheckIn(user)

    let userTasks = tas.find((task) => {
      return task.user_id == user.id && task.completed_at == null
    })

    if (checkIn) {
      if (checkIn.check_out_time) {
        if (userTasks) {
          return 'warning'
        } else {
          return 'default'
        }
      } else {
        return 'success'
      }
    } else if (userTasks) {
      return 'warning'
    } else {
      return 'default'
    }
  }

  function statusText(check, tas, user) {
    let checkIn = getCheckIn(user)

    let userTasks = tas.find((task) => {
      return task.user_id == user.id && task.completed_at == null
    })

    if (checkIn) {
      if (checkIn.check_out_time) {
        if (userTasks) {
          return 'Taget hjem, har opgaver'
        } else {
          return 'Taget hjem'
        }
      } else {
        return 'På arbejde'
      }
    } else if (userTasks) {
      return 'Ikke på job, har opgaver'
    } else {
      return 'Ikke på job'
    }
  }

  function getCheckIn(user) {
    return checkIns.find((checkIn) => {
      return checkIn.user_id == user.id
    })
  }

  function handleUserClick(user) {
    let checkIn = getCheckIn(user)

    if (!checkIn || (checkIn && !checkIn.check_out_time)) {
      userSelected = user
    }
  }

  updateCheckIns()
  updateTasks()
</script>

<div>
  <div class="user-box-container">
    {#if loading}
      <div>Loading...</div>
    {:else if userSelected != null}
      <UserCheckInOptions
        user={userSelected}
        checkInLog={getCheckIn(userSelected)}
        {resetUser}
        on:updateCheckIns={updateCheckIns}
        on:updateTasks={updateTasks}
      />
    {:else}
      {#each users as user}
        <Button
          variant={getCheckInStatus(checkIns, tasks, user)}
          width={'250px'}
        >
          <div
            class="user-box-button"
            on:click={() => {
              handleUserClick(user)
            }}
          >
            <UserAvatar {user} />
            <div class="text-left">
              <div>{user.name}</div>
              <small>{statusText(checkIns, tasks, user)}</small>
            </div>
          </div>
        </Button>
      {/each}
    {/if}
  </div>
</div>

<style>
  .user-box-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .user-box-button {
    display: flex;
    align-items: center;
    gap: 10px;
  }
</style>
