<script>
  import { fade, fly } from 'svelte/transition'
  import { quintOut } from 'svelte/easing'
  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()

  export let showModal

  function modalClose() {
    console.log('Modal closed')
    showModal = false
    dispatch('modalClose')
  }
</script>

{#if showModal}
  <div
    class="modal show"
    tabindex="-1"
    role="dialog"
    aria-labelledby="sampleModalLabel"
    aria-hidden={false}
  >
    <div
      class="modal-dialog"
      role="document"
      in:fly={{ y: -50, duration: 300 }}
      out:fly={{ y: -50, duration: 300, easing: quintOut }}
    >
      <div class="modal-content">
        <div class="modal-header">
          <div class="header-title">
            <slot name="header" />
          </div>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            on:click={() => modalClose()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot {modalClose} />
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop show" transition:fade={{ duration: 150 }} />
{/if}

<style>
  .modal-dialog {
    max-height: 80%;
    overflow-y: auto;
  }

  .modal-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-content: center;
  }

  .modal-body {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .header-title {
    font-size: 32px;
  }

  .modal-backdrop {
    background: rgba(0, 0, 0, 0.3);
  }
</style>
