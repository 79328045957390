import axios from 'axios'

export const deactivateEventCategory = async (eventCategory) => {
  axios.delete(`/event_categories/${eventCategory.id}`).then(() => {
    location.href = '/event_categories'
  })
}

export const activateEventCategory = async (eventCategory) => {
  await axios
    .patch(`/event_categories/${eventCategory.id}/activate`)
    .then(() => {
      location.href = '/event_categories'
    })
    .catch((error) => {
      console.error(error)
    })
}
