<script>
  import ScoreBlock from './ScoreBlock.svelte';
  import ScoreSummary from './ScoreSummary.svelte';

  export let overallScore;
  export let priorScore;
  export let teamName = null
</script>

<div class="overall-score_row">
  <div class="item">
    <strong>{teamName ? teamName : 'Overordnet'} Trivsel-Score</strong>
  </div>
  <div class="item text-center">
    <ScoreBlock score={overallScore} />
  </div>
  <div class="item">
    <ScoreSummary currentScore={overallScore} priorScore={priorScore} />
  </div>
</div>

<style>
  .overall-score_row {
    height: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #ddd;
    padding: .75rem 0;
  }

  .item {
    flex: 1 1 0px;
  }
</style>
