import { writable } from 'svelte/store'
import { get } from 'svelte/store'

function createStore() {
  const { subscribe, set, update } = writable([])

  return {
    subscribe,
    set,
    update,
    get: () => get({ subscribe }),
  }
}

export const userStore = createStore()

export function getUserById(user_id) {
  return userStore.get().find((user) => user.id === user_id)
}
