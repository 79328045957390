<script>
  import { chart } from 'svelte-apexcharts';
  import da from 'apexcharts/dist/locales/da.json';
  import { getDatesArray } from '../dataHelper';
  import dayjs from 'dayjs';

  export let data;
  export let dateRange;

  let periodLength = getDatesArray(dateRange).length;

  let outputData = data.map((item) => {
    return {
      name: item.name,
      data: item.data.map((d) => {
        return {
          x: dayjs(d[0], 'DD/MM YYYY'),
          y: d[1],
        };
      }),
      color: item.color,
    };
  });

  let options = {
    chart: {
      locales: [da],
      defaultLocale: 'da',
      type: 'line',
      height: 300,
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 4,
    },
    series: outputData,
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 4,
    },
    xaxis: {
      type: 'datetime',
      tickPlacement: 'on',
      tickAmount: periodLength,
      labels: {
        formatter: function (val, timestamp) {
          return dayjs(timestamp).format('D. MMM');
        }
      },
    },
    yaxis: {
      labels: {
        show: true,
        formatter: function (value) {
          return value.toFixed(0);
        },
      },
    },
  };
</script>

<div use:chart={options} />
