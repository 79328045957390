<script>
  import { page } from '@inertiajs/svelte'
  import ReportMenu from '@/components/ReportsNavigation/ReportMenu.svelte'
  import CompanyMetricBox from './components/CompanyMetricBox.svelte'
  import AvgScoreChart from './components/AvgScoreChart.svelte'
  import StackedBarChart from './components/StackedBarChart.svelte'
  import LineChart from './components/LineChart.svelte'
  import ImpactScoreCircles from '@/pages/Events/components/ImpactScoreCircles.svelte'
  import { Icon, Home, QueueList } from 'svelte-hero-icons'
  import dayjs from 'dayjs'

  export let leaderOfShownCompany = false
  export let usersThatCanSeeThisReport = []
  export let company
  export let companiesWithFullAccess = []
  export let leader
  export let report
  export let activeLicensesCount

  const current_company = $page.props.auth.company

  const companyHasFullAccess = companiesWithFullAccess.some(
    (c) => c.id === company.id,
  )
</script>

<ReportMenu current_tab="company_report" />

<div class="row">
  <div class="col-xs-12 col-md-8 col-md-offset-2">
    {#if leaderOfShownCompany}
      <br />
      <div class="alert alert-info">
        På denne side kan du se de data fra din afdeling, der deles med andre
        dele af organisationen, som fx din leder eller nøglepersoner i HR.
        <br />
        <br />
        <strong>Bemærk:</strong>
        De viste medarbejderdata er således på afdelingsniveau. Dvs. der ikke er
        kommentarer eller bedømmelser på individniveau.
        <br />
        <br />
        Følgende personer kan se denne rapport:
        {#if usersThatCanSeeThisReport && usersThatCanSeeThisReport.length > 0}
          {new Intl.ListFormat('da-DK').format(usersThatCanSeeThisReport)}
        {:else}
          Ingen
        {/if}
      </div>
    {/if}
    <h1>
      Rapport seneste måned for {company.name}
    </h1>
    {#if companyHasFullAccess || company.parent.id === current_company.id}
      <h3>{leader.name}</h3>

      <div class="row metric_box_wrapper">
        <CompanyMetricBox>
          Gennemsnitlig aktiv i TeamEffect
          <strong>{report.leader_avg_days_active_per_week}</strong> dage om ugen
          i den seneste måned
        </CompanyMetricBox>
        <CompanyMetricBox>
          <strong>{report.wdr_responses_count}</strong> tilbagemeldinger på arbejdsdage
          sendt i TeamEffect i den seneste måned
        </CompanyMetricBox>
        <CompanyMetricBox>
          <strong>{report.appreciations_sent_count}</strong> skuldreklap sendt i
          den seneste måned af {leader.name}
        </CompanyMetricBox>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <h4>Gennemsnitlig trivselsscore</h4>
          <AvgScoreChart data={report.user_report_dotted_line_chart} />
        </div>
      </div>
    {/if}
    <div class="row">
      <h3 class="icon-text">
        <Icon src={Home} solid size="24" />
        Trivsel og aktivitet for {company.name}
      </h3>
      <div class="metric_box_wrapper">
        <CompanyMetricBox>
          Aktiv siden <strong
            >{dayjs(company.created_at).format('MMMM YYYY')}</strong
          >
          med <strong>{activeLicensesCount}</strong> aktive licenser
        </CompanyMetricBox>
        <CompanyMetricBox>
          <strong>{report.wdr_count}</strong> arbejdsdage bedømt den seneste måned
        </CompanyMetricBox>
        <CompanyMetricBox>
          <strong>{report.wdrs_with_comments_count}</strong> arbejdsdage med kommentar
        </CompanyMetricBox>
        <CompanyMetricBox>
          <strong>{report.employee_appreciations_count}</strong> skulreklap sendt
          til kollegaer
        </CompanyMetricBox>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-xs-12">
        <h4>Farvefordeling pr. dag for den seneste måned</h4>
        <StackedBarChart data={report.workday_registrations_chart} />
      </div>
    </div>
    {#if company.event_registration_enabled}
      <div class="row">
        <div class="col-xs-12">
          <br />
          <br />
          <br />
          <h3 class="icon-text">
            <Icon src={QueueList} solid size="24" />
            Hændelsesrapport
          </h3>
          {#if report.incident_count > 0}
            <p>
              I den seneste måned er registreret <strong
                >{report.incident_count}</strong
              >
              hændelser, hvoraf
              <strong>{report.incidents_with_high_impact_count}</strong>
              havde en påvirkningsgrad på 4 eller derover.
              <strong
                >{report.incident_category_registered_the_most_count}</strong
              >
              af de i alt <strong>{report.incident_count}</strong> hændelser er
              registreret som
              <strong
                >{report.incident_category_registered_the_most.name}</strong
              >.
            </p>
            <p>
              Der blev i den seneste måned registreret <strong
                >{Math.abs(
                  report.incident_difference_between_this_month_and_previous_month,
                )}</strong
              >
              hændelser {report.incident_difference_between_this_month_and_previous_month <
              0
                ? 'mindre'
                : 'mere'} end den seneste måned.
            </p>
            <br />
            <h4>Hændelser seneste 6 måneder</h4>
            <LineChart data={report.incidents_last_six_months_chart_data} />
            <br />
            <h4>Hændelser med høj påvirkningsgrad seneste 6 måneder</h4>
            {#if report.incidents_with_high_impact_chart_data.series[0].data != 0 || report.incidents_with_high_impact_chart_data.series[1].data != 0}
              <StackedBarChart
                data={report.incidents_with_high_impact_chart_data}
              />
            {:else}
              <div class="alert alert-success">
                <p class="alert-heading">
                  Ingen hændelser med høj påvirkningsgrad i den seneste måned
                </p>
              </div>
            {/if}
            <br />
            <h4>Kategorier af hændelser i den seneste måned</h4>
            {#each report.incidents_with_avg_impact_by_category as category}
              <div class="row">
                <div class="col-xs-6 col-sm-4">
                  {category.name}
                </div>
                <div class="col-xs-6 col-sm-4 text-right">
                  {category.incidents_count} hændelser
                </div>
                <div class="col-xs-12 col-sm-4 text-right">
                  <ImpactScoreCircles score={category.avg_impact} with_text />
                </div>
              </div>
              <br />
            {/each}
          {/if}
        </div>
      </div>
    {:else}
      <div class="alert alert-danger">
        Der er ikke registreret nogen hændelser i den seneste måned
      </div>
    {/if}
  </div>
</div>

<style>
  .icon-text {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }

  .metric_box_wrapper {
    display: flex;
    justify-content: space-between;
    gap: 0.75rem;
  }
</style>
