<script>
  import MembershipForm from './components/MembershipForm.svelte'
  import UserAvatar from '@/components/UserAvatar.svelte'

  export let membership
  export let user
  export let has_another_leader
  export let has_another_AMR
</script>

<div class="row">
  <div class="col-xs-12 col-md-6 col-md-offset-3">
    <h2>
      <UserAvatar {user} />
      {user.name}
    </h2>
    <hr />
    <MembershipForm {membership} {has_another_AMR} {has_another_leader} />
  </div>
</div>
