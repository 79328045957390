<script>
  import FlashMessages from '../components/Layout/FlashMessages.svelte'
</script>

<FlashMessages />
<main class="app-container">
  <slot />
</main>

<style>
  .app-container {
    margin-bottom: 3rem;
  }

  @media (max-width: 1199px) {
    .app-container {
      padding-bottom: 10rem;
    }
  }
</style>
