<script>
  import { page } from '@inertiajs/svelte'
  import { scoreForm } from '@/stores/NpsScoreStore'
</script>

<div class="text-center">
  <div class="lead">
    Hvor sandsynligt er det, at du vil anbefale {$page.props.auth.company.name} til
    andre som et godt sted at arbejde?
  </div>
  <div class="btn-group-lg">
    {#each [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] as score}
      <label class="btn btn-default">
        <input
          id="score_{score}"
          type="radio"
          name="score"
          value={score}
          bind:group={$scoreForm.score}
          required
        />
        {score}
      </label>
    {/each}
  </div>
  <small>Hvor 1 ikke er særlig sandsynligt og 10 er meget sandsynligt</small>
</div>

<style>
  .btn {
    margin: 0 10px 10px 0;
  }
</style>
