<script>
  import { chart } from 'svelte-apexcharts'

  export let data

  let options = {
    chart: {
      type: 'bar',
      stacked: true,
      stackType: '100%',
      height: 150,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: '100%',
        borderRadius: [6],
        borderRadiusApplication: 'end',
      },
    },
    series: data,
    yaxis: {
      labels: {
        show: false,
      },
    },
    xaxis: {
      categories: ['Fordeling'],
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      floating: false,
      fontSize: '16px',
      fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif;',
      onItemHover: {
        highlightDataSeries: true,
      },
      markers: {
        width: 16,
        height: 16,
        radius: 4,
        customHTML: function () {
          return '<span class="label"/>'
        },
        offsetX: 0,
        offsetY: 0,
      },
      formatter: function (seriesName, opts) {
        return (
          opts.w.globals.seriesPercent[opts.seriesIndex][0].toFixed(1) + '%'
        )
      },
    },
  }
</script>

<div use:chart={options} />
