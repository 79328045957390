<script>
  import { useForm } from '@inertiajs/svelte'
  import { fade } from 'svelte/transition'

  export let user

  let form = useForm({
    user: {
      password: null,
      password_confirmation: null,
    },
  })

  function handleSubmit() {
    if (!$form.user.password) {
      $form.errors.password = 'Indtast et kodeord'
      return
    }

    if ($form.user.password !== $form.user.password_confirmation) {
      $form.errors.password_confirmation = 'Kodeordene er ikke ens'
      return
    }

    $form.post(`/users/${user.id}/change_password`, {
      onSuccess: () => {
        $form.reset()
      },
    })
  }
</script>

{#if $form.errors.message}
  <div class="alert alert-danger" transition:fade>
    {$form.errors.message}
  </div>
{/if}

<div class="row">
  <div class="col-xs-12 col-md-6 col-md-offset-3">
    <h2>{user.name} kodeord</h2>
    <div class="lead">Indtast nyt kodeord for at skifte det</div>
    <form on:submit|preventDefault={handleSubmit}>
      <div class="form-group {$form.errors.password ? 'error' : ''}">
        <label for="password">Kodeord </label>
        <input
          type="password"
          class="form-control"
          id="password"
          bind:value={$form.user.password}
        />
        <small class="text-muted">
          <div class="glyphicon glyphicon-info-sign" />
          Kodeordet skal være minimum 8 tegn samt indeholde tal og bogstaver</small
        >
        {#if $form.errors.password}
          <small class="text-danger">{$form.errors.password}</small>
        {/if}
      </div>
      <div
        class="form-group {$form.errors.password_confirmation ? 'error' : ''}"
      >
        <label for="password_confirmation">Gentag kodeord</label>
        <input
          type="password"
          class="form-control"
          id="password_confirmation"
          bind:value={$form.user.password_confirmation}
        />
        {#if $form.errors.password_confirmation}
          <small class="text-danger">{$form.errors.password_confirmation}</small
          >
        {/if}
      </div>
      <button
        disabled={$form.processing}
        type="submit"
        class="btn btn-primary btn-block">Skift kodeord</button
      >
    </form>
  </div>
</div>

<style>
  .form-group.error input {
    border: 2px solid #f05252;
  }

  .form-group.error {
    color: #f05252;
  }
</style>
