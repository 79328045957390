import { writable } from 'svelte/store'

export const eventForm = writable({
  day: '',
  comment: '',
  event_category_id: null,
  impact_score: null,
  context_of_incident: '',
  who_was_present: '',
  description: '',
  how_it_could_have_been_avoided: '',
  incident_reference_id: null,
  form_data: [{ categories: [], incidents_today: [], references: [] }],
})
