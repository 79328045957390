<script>
  import Icon from 'svelte-awesome'
  import arrowUp from 'svelte-awesome/icons/arrowUp'
  import arrowDown from 'svelte-awesome/icons/arrowDown'
  import minus from 'svelte-awesome/icons/minus'

  export let priorCount
  export let currentCount
</script>

{#if priorCount < currentCount}
  <Icon class="text-danger" data={arrowUp} />
{:else if priorCount > currentCount}
  <Icon class="text-success" data={arrowDown} />
{:else}
  <Icon data={minus} />
{/if}
