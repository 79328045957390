<script>
  import dayjs from 'dayjs'

  export let date
  export let showDate = true
</script>

{#if showDate}
  <div class="text-muted week-seperator">
    Uge {dayjs(date).format('w')}
  </div>
{/if}

<style>
  .week-seperator {
    position: absolute;
    top: 18px;
    font-size: 1.1rem;
  }
</style>
