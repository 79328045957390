<script>
  import EmployeeRow from './components/EmployeeRow.svelte'
  import InactiveUsers from './components/InactiveUsers.svelte'
  import ReportMenu from '@/components/ReportsNavigation/ReportMenu.svelte'

  export let inactiveUsers = []
  export let activeUsersByTeams = []
  export let activeUsersByCompany = []
  export let overviewType
  export let workdayRegistrationsByUser = []
  export let vacationPeriodsByUser = []
  export let eventsByUser = []
  export let dateRange
</script>

<div class="hidden-print">
  <ReportMenu displayDateSelection current_tab={overviewType} />
</div>

<div class="row">
  <div class="col-xs-12">
    <h2>Medarbejderoverblik</h2>
    {#if inactiveUsers.length > 0}
      <a href="#inactive-users" class="btn btn-default">
        <div class="glyphicon glyphicon-warning-sign" />
        Se {inactiveUsers.length} inaktive brugere
      </a>
    {/if}
    {#if activeUsersByTeams.length > 0}
      {#each activeUsersByTeams as { team, users } (team.name)}
        <h3>{team.name}</h3>
        {#each users as user}
          <EmployeeRow
            {user}
            {dateRange}
            workdayRegistrations={workdayRegistrationsByUser[user.id]}
            vacationPeriods={vacationPeriodsByUser[user.id]}
            daily={overviewType == 'daily'}
            events={eventsByUser[user.id]}
          />
        {/each}
      {/each}
    {:else}
      {#each Object.entries(activeUsersByCompany) as [company, users] (company)}
        <h3>{company}</h3>
        {#each users as user}
          <EmployeeRow
            {user}
            {dateRange}
            workdayRegistrations={workdayRegistrationsByUser[user.id]}
            vacationPeriods={vacationPeriodsByUser[user.id]}
            daily={overviewType == 'daily'}
            events={eventsByUser[user.id]}
          />
        {/each}
      {/each}
    {/if}
    {#if inactiveUsers.length > 0}
      <InactiveUsers {inactiveUsers} />
    {/if}
  </div>
</div>
