<script>
  import { Icon, CheckCircle } from 'svelte-hero-icons'
  import DocumentUploadForm from './components/DocumentUploadForm.svelte'

  export let accountTransaction
  export let account
  export let citizen
</script>

<div class="row">
  <div class="col-xs-12 col-md-6 col-md-offset-3">
    <h2 class="icon-header">
      <Icon src={CheckCircle} size="36" />
      Postering oprettet
    </h2>
  </div>
</div>
<br />
<div class="row">
  <div class="col-xs-12 col-md-6 col-md-offset-3">
    <div class="panel panel-primary">
      <div class="panel-heading">
        <h3 class="panel-title">Vil du tilføje bilag nu?</h3>
      </div>
      <div class="panel-body">
        <div class="text-center">
          <DocumentUploadForm {accountTransaction} {account} {citizen} />
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .icon-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
</style>
