export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const truncate = (str, n) => {
  return str.length > n ? str.substr(0, n - 1) + '...' : str
}

export const formatForChart = (obj) => {
  let formatted = []
  for (let key in obj) {
    formatted.push({ x: key, y: obj[key] })
  }
  return formatted
}

export const formatForChartV2 = (obj) => {
  let formatted = [
    {
      data: [],
    },
  ]
  for (let key in obj) {
    formatted[0].data.push({ x: key, y: obj[key] })
  }
  return formatted
}

export const printPage = () => {
  window.print()
}
