<script>
  import { chart } from 'svelte-apexcharts';
  import da from 'apexcharts/dist/locales/da.json';
  import dayjs from 'dayjs';
  import { getDatesArray } from '../dataHelper';

  export let data;
  export let dateRange;

  let periodLength = getDatesArray(dateRange).length;
  let outputData = [
    {
      name: 'Bedømmelser',
      data: [],
    },
  ];

  Object.entries(data).forEach(([key, value]) => {
    outputData[0].data.push({
      x: key,
      y: value,
    });
  });

  let options = {
    chart: {
      locales: [da],
      defaultLocale: 'da',
      type: 'area',
      height: 300,
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    series: outputData,
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 4,
    },
    xaxis: {
      type: 'datetime',
      tickAmount: periodLength,
      labels: {
        formatter: function (val, timestamp) {
          return dayjs(timestamp).format('D. MMM');
        },
      }
    },
    yaxis: {
      labels: {
        show: true,
        formatter: function (value) {
          return value.toFixed(0);
        },
      },
    },
  };
</script>

<div use:chart={options} />
