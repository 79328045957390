<script>
  export let membership
  let rights = []

  if (membership.owner) {
    rights.push({
      icon: 'glyphicon glyphicon-user',
      text: ' Er leder',
    })
  }
  if (membership.manage_users_and_citizens) {
    let s = 'Kan administrere medarbejdere'
    if (membership.has_accounting_product) {
      s += ' og borgere'
    }
    rights.push({
      icon: 'glyphicon glyphicon-cog',
      text: s,
    })
  }
  if (membership.has_only_read_access_to_accounting) {
    rights.push({
      icon: 'glyphicon glyphicon-eye-open',
      text: 'Kan se regnskaber, men ikke føre dem (Kigge-adgang)',
    })
  }
  // if membership.access_any_reports?
  if (membership.can_access_all_data) {
    rights.push({
      icon: 'glyphicon glyphicon-eye-open',
      text: 'Kan se alle rapporter',
    })
  } else {
    if (membership.can_access_anonymous_data) {
      rights.push({
        icon: 'glyphicon glyphicon-eye-open',
        text: 'Kan se anonyme rapporter',
      })
    }
    if (membership.can_access_owners_data) {
      rights.push({
        icon: 'glyphicon glyphicon-eye-open',
        text: 'Kan se lederes bedømmelser',
      })
    }
    if (membership.can_access_inicdent_reports) {
      rights.push({
        icon: 'glyphicon glyphicon-eye-open',
        text: 'Kan se hændelsesrapporter',
      })
    }
  }
  if (membership.has_organizational_administrative_rights) {
    rights.push({
      icon: 'glyphicon glyphicon-cog',
      text: 'Administrator for ' + membership.organization.name,
    })
  }

  if (rights.length == 0) {
    rights.push({
      icon: 'glyphicon glyphicon-eye-close',
      text: 'Ingen ekstra rettigheder',
    })
  }
</script>

{#each rights as right}
  <span class={right.icon} />
  {right.text}
  <br />
{/each}
