<script>
  import { hasComment, getScoreName } from '@/utils/wdr_helper.js'
  import UserAvatar from '@/components/UserAvatar.svelte'
  import dayjs from 'dayjs'
  import { Icon, CalendarDays } from 'svelte-hero-icons'
  import Colorbox from '@/pages/WorkdayRegistrations/components/Colorbox.svelte'
  import UserReportWorkdayComments from '../UserReportWorkdayComments.svelte'
  import { createEventDispatcher } from 'svelte'

  export let w

  const dipsatch = createEventDispatcher()

  const openWorkdayModal = () => {
    dipsatch('open_wdr', {
      date: dayjs(w.day).format('YYYY-MM-DD'),
      workday: w,
    })
  }
</script>

{#if hasComment(w)}
  <tr on:click={openWorkdayModal}>
    <td class="meta">
      <UserAvatar user={w.user} size="25" />
      <div class="meta-name">
        <strong>{w.user.name}</strong>
        <small class="text-muted meta-date">
          <Icon src={CalendarDays} size="18" />
          <span class="date">{dayjs(w.day).format('dddd [d.] ll')}</span>
        </small>
        <div class="visible-xs">
          <Colorbox wdr={w} />
          {getScoreName(w.score)}
        </div>
      </div>
    </td>
    <td class="hidden-xs color-box">
      <Colorbox wdr={w} />
      {getScoreName(w.score)}
    </td>
    <td class="hidden-xs comment">
      <UserReportWorkdayComments wdr={w} withIcons={false} />
    </td>
    <td class="cta">
      <button class="btn btn-default">Se detaljer</button>
    </td>
  </tr>
{/if}

<style>
  tr {
    cursor: pointer;
    display: flex;
    flex-direction: row;
  }

  td {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
  }

  .meta {
    flex: 1 1 170px;
  }

  .comment {
    flex: 3 2 0px;
  }

  .color-box {
    flex: 0 0 150px;
  }

  .meta-name {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 0.25rem;
  }

  .meta-date {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
  }
  .date::first-letter {
    text-transform: uppercase;
  }
</style>
