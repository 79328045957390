<script>
  import Task from './Task.svelte'

  export let day = ''
  export let tasks = []
</script>

<div>
  <strong>{day}</strong>
  <div class="tasks-container">
    {#if tasks}
      {#each tasks as task}
        <Task {task} />
      {/each}
    {/if}
  </div>
</div>

<style>
  .tasks-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
  }
</style>
