<script>
  import { createEventDispatcher } from 'svelte'
  import Icon from 'svelte-awesome'
  import plus from 'svelte-awesome/icons/plus'

  export let date
  export let user

  let dipsatcher = createEventDispatcher()

  const openNotScoredModal = () => {
    dipsatcher('open_not_scored_modal', {
      date: date,
      user: user,
    })
  }
</script>

<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  class="progress-bar wdr-progress-block no-score"
  role="progressbar"
  on:click={openNotScoredModal}
>
  <Icon data={plus} color="#94CFFF" />
</div>

<style>
  .wdr-progress-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .no-score:hover {
    cursor: pointer;
  }
</style>
