<script>
  import { createEventDispatcher } from 'svelte'
  import { page } from '@inertiajs/svelte'

  const dispatch = createEventDispatcher()

  export let reference

  const currentCompany = $page.props.auth.company

  const deleteReference = () => {
    dispatch('delete-reference', reference.id)
  }
</script>

<div class="panel panel-default">
  <div class="panel-heading">
    <div class="panel-title reference-row">
      <h4>{reference.name}</h4>
      {#if reference.company_id == currentCompany.id}
        <div class="btn-group" role="group">
          <a
            href="/incident_references/{reference.id}/edit"
            class="btn btn-info">Redigér</a
          >
          <button on:click={deleteReference} class="btn btn-danger">Slet</button
          >
        </div>
      {/if}
    </div>
  </div>
</div>

<style>
  .reference-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
</style>
