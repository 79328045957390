<script>
  import SelectBox from './SelectBox.svelte'
  import CheckBox from './CheckBox.svelte'

  export let potentialParents
  export let customers
  export let form
  export let onSubmit
  export let companyTypes
  export let enpsFrequencyTypes
  export let leadershipTypes
</script>

<form on:submit|preventDefault={onSubmit}>
  <div class="form-group">
    <label for="name">License Name</label>
    <input
      type="text"
      id="name"
      name="name"
      bind:value={$form.company.name}
      class="form-control"
    />
  </div>

  <SelectBox
    label="Parent"
    collection={potentialParents}
    bind:value={$form.company.parent_id}
    allowNone
  />

  <SelectBox
    collection={customers}
    bind:value={$form.company.customer_id}
    label="Customer"
  />

  <SelectBox
    collection={companyTypes}
    bind:value={$form.company.company_type}
    valueDescriptor="key"
    optionDescriptor="value"
    label="Company Type"
    allowNone
  />

  <SelectBox
    collection={leadershipTypes}
    bind:value={$form.company.leadership_type}
    valueDescriptor="key"
    optionDescriptor="value"
    label="Leadership Type"
  />

  <div class="form-group">
    <label for="name">FKI UUID</label>
    <input
      type="text"
      id="fki_uuid"
      name="fki_uuid"
      bind:value={$form.company.fki_uuid}
      class="form-control"
    />
  </div>

  <fieldset>
    <legend>TeamEffect</legend>
    <CheckBox
      label="TeamEffect"
      bind:value={$form.company.has_work_enviroment_product}
    />
    <CheckBox
      label="Social posts feed"
      bind:value={$form.company.news_feed_enabled}
    />
    <CheckBox
      label="Allow to respond on workdays"
      bind:value={$form.company.wdr_responses_enabled}
    />
    <CheckBox
      label="Hændelsesregistrering"
      bind:value={$form.company.event_registration_enabled}
    />
    <CheckBox
      label="Handleplan modul (Kun hvis hændelsesregistrering er aktiveret)"
      bind:value={$form.company.incident_plan_module_enabled}
    />
    <CheckBox
      label="Enable users to register a reference for an incident"
      bind:value={$form.company.incident_reference_enabled}
    />
    <CheckBox
      label="Enable users to do a defusing"
      bind:value={$form.company.has_defusing_module_enabled}
    />
    <CheckBox
      label="Ask for incidents after registration of workday"
      bind:value={$form.company.incident_prompt_enabled}
    />
    <CheckBox
      label="Use shift types"
      bind:value={$form.company.use_shift_type}
    />
    <CheckBox
      label="Comment only responses"
      bind:value={$form.company.comment_only_responses}
    />
    <CheckBox
      label="Use workdays answers for students"
      bind:value={$form.company.use_workday_answers_for_students}
    />
    <CheckBox
      label="Include titles for workday rating colors"
      bind:value={$form.company.wdr_title_enabled}
    />
    <CheckBox
      label="Unverified login allowed"
      bind:value={$form.company.unverified_login_allowed}
    />
    <CheckBox
      label="Auto generate days off in weekends"
      bind:value={$form.company.weekend_days_off}
    />

    <div class="form-group">
      <label for="starting_date">Opstartsdato</label>
      <input
        type="date"
        id="starting_date"
        name="starting_date"
        bind:value={$form.company.starting_date}
        class="form-control"
      />
    </div>
  </fieldset>
  <fieldset>
    <legend>Borgerregnskab</legend>
    <CheckBox
      label="Borgerregnskab"
      bind:value={$form.company.has_citizen_accounting_product}
    />
  </fieldset>
  <fieldset>
    <legend>eNps Module</legend>
    <CheckBox
      label="eNps Module"
      bind:value={$form.company.enps_survey_module_enabled}
    />
    <SelectBox
      collection={enpsFrequencyTypes}
      bind:value={$form.company.enps_frequency_type}
      valueDescriptor="key"
      optionDescriptor="value"
      label="Frequency"
    />
    <div class="form-group">
      <label for="enps_survey_frequency_in_days">Frequency in days</label>
      <input
        type="number"
        min="1"
        id="enps_survey_frequency_in_days"
        name="enps_survey_frequency_in_days"
        bind:value={$form.company.enps_survey_frequency_in_days}
        class="form-control"
      />
    </div>
  </fieldset>

  <fieldset>
    <legend>FlowEffect</legend>
    <CheckBox label="FlowEffect" bind:value={$form.company.has_floweffect} />
  </fieldset>

  <fieldset>
    <legend>Misc</legend>
    <CheckBox label="Fun Features" bind:value={$form.company.fun_features} />
    <div class="form-group">
      <label for="slug">License code (URL-friendly)</label>
      <input
        type="text"
        id="slug"
        name="slug"
        bind:value={$form.company.slug}
        class="form-control"
      />
    </div>
    <div class="form-group">
      <label for="expire_logs_after_months">Expire logs after months</label>
      <input
        type="number"
        min="1"
        id="expire_logs_after_months"
        name="expire_logs_after_months"
        bind:value={$form.company.expire_logs_after_months}
        class="form-control"
      />
    </div>
  </fieldset>

  <button type="submit" class="btn btn-primary btn-block"> Update </button>
</form>
