<script>
  import { beforeUpdate } from 'svelte'
  import { page } from '@inertiajs/svelte'
  import {
    defusingUsers,
    defusingsForm,
    organizationUsers,
  } from '@/stores/DefusingFormStore'

  import MultiStepForm from '@/components/MultiStepForm.svelte'

  // Slides
  import ChooseCompany from './slides/ChooseCompany.svelte'
  import CompanyUsers from './slides/CompanyUsers.svelte'
  import OrganizationUsers from './slides/OrganizationUsers.svelte'
  import OtherUsers from './slides/OtherUsers.svelte'
  import Description from './slides/Description.svelte'
  import ShiftType from './slides/ShiftType.svelte'
  import RoleDescription from './slides/RoleDescription.svelte'
  import ImpactScore from './slides/ImpactScore.svelte'
  import TimeRegistration from './slides/TimeRegistration.svelte'

  export let questionSlides = []
  export let newDefusing
  export let users = []
  export let companiesIncludingUsers = []

  $defusingsForm = newDefusing
  $defusingUsers = users
  $organizationUsers = companiesIncludingUsers

  let steps = []

  function assignSlides() {
    questionSlides.forEach((slide) => {
      switch (slide) {
        case 'choose_company':
          steps.push({ slide: ChooseCompany, showActions: true })
          break
        case 'shift_type':
          steps.push({ slide: ShiftType, showActions: false })
          break
        case 'company_users':
          steps.push({ slide: CompanyUsers, showActions: true })
          break
        case 'organization_users':
          steps.push({ slide: OrganizationUsers, showActions: true })
          break
        case 'other_users':
          steps.push({ slide: OtherUsers, showActions: true })
          break
        case 'description':
          steps.push({ slide: Description, showActions: true })
          break
        case 'role_description':
          steps.push({ slide: RoleDescription, showActions: true })
          break
        case 'impact_score':
          steps.push({ slide: ImpactScore, showActions: false })
          break
        case 'time_registration':
          steps.push({ slide: TimeRegistration, showActions: true })
          break
        default:
          throw new Error(`${slide} Not implemented`)
      }
    })
  }

  let skipUpdate = false

  $defusingsForm.company_id = $page.props.auth.company.id

  beforeUpdate(() => {
    if (!skipUpdate) {
      assignSlides()
    }
    skipUpdate = true
  })

  $: formData = {
    defusing: $defusingsForm,
  }
</script>

<MultiStepForm {steps} {formData} submitUrl="/defusings" />
