<script>
  import { useForm, page } from '@inertiajs/svelte'
  import dayjs from 'dayjs'

  export let transactionType = ''
  export let accountTransaction
  export let account
  export let citizen

  let submitBtnTxt = transactionType == 'deposit' ? 'Put i' : "Ta' ud"

  let form = useForm({
    transaction_type: transactionType,
    account_transaction: {
      account_id: account.id,
      registered_by: $page.props.auth.user.name,
      name: '',
      datetime: dayjs(accountTransaction.datetime).format('YYYY-MM-DDTHH:mm'),
      amount: null,
      description: '',
    },
  })

  const submitTransaction = () => {
    $form.post(
      `/citizens/${citizen.id}/accounts/${account.id}/account_transactions`,
    )
  }
</script>

<div class="container">
  <div class="row" style="margin-bottom: 100px">
    <div class="col-xs-12 col-md-6 col-md-offset-3">
      <h2>Ny postering</h2>

      <form on:submit|preventDefault={submitTransaction}>
        <div>
          <label for="user_name" class="control-label">Medarbejder</label>
          <input
            type="text"
            name="user_name"
            class="form-control"
            readonly
            bind:value={$form.account_transaction.registered_by}
          />
        </div>

        <div>
          <label for="amount" class="control-label">Beløb</label>
          <input
            type="number"
            name="amount"
            step="any"
            autofocus="true"
            min="0"
            placeholder="0,00"
            class="form-control"
            bind:value={$form.account_transaction.amount}
          />
        </div>
        <div>
          <label for="name" class="control-label">Beskrivelse</label>
          <input
            type="text"
            maxlength="255"
            required="true"
            name="name"
            class="form-control"
            bind:value={$form.account_transaction.name}
          />
        </div>

        <div>
          <label for="datetime" class="control-label">Kvitteringsdato</label>
          <input
            type="datetime-local"
            class="form-control"
            id="datetime"
            required="true"
            bind:value={$form.account_transaction.datetime}
            max={dayjs().format('YYYY-MM-DDTHH:mm')}
          />
        </div>

        <div>
          <label for="description" class="control-label"
            >Yderligere beskrivelse</label
          >
          <textarea
            name="description"
            row="5"
            bind:value={$form.account_transaction.description}
            class="form-control"
          />
        </div>

        <button
          type="submit"
          value={submitBtnTxt}
          class="btn btn-primary btn-xl">{submitBtnTxt}</button
        >
      </form>
    </div>
  </div>
</div>

<style>
  form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
</style>
