<script>
  import UserAvatar from '@/components/UserAvatar.svelte'

  export let user
  export let membership = undefined
  export let unblock_user = false

  let unblockLink = unblock_user
    ? `/users/${user.id}/activate`
    : `/memberships/${membership.id}/activate`
</script>

<div class="row">
  <div class="col-xs-12">
    <hr />
  </div>
</div>
<div class="row">
  <div class="col-xs-12 vcenter">
    <UserAvatar {user} />
    <strong>{user.name}</strong>
    <div class="pull-right">
      <a href={unblockLink} class="btn btn-primary btn-sm">
        <span class="glyphicon glyphicon-ok-circle"></span>
        Aktiver
      </a>
    </div>
  </div>
</div>
