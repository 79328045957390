<script>
  import { impactScoreOptions, defusingsForm } from '@/stores/DefusingFormStore'
  import ImpactScoreCircles from '@/pages/Events/components/ImpactScoreCircles.svelte'

  export let triggerNextSlide

  const setImpact = (score) => {
    $defusingsForm.impact_score = score
    triggerNextSlide(+1)
  }
</script>

<div class="short_question text-center">
  <h2>Hvor meget har hændelsen påvirket dig?</h2>
  <br />
  <br />
  <div class="centered-inputs">
    <div class="btn-group-lg btn-group-vertical inputs" data-toggle="buttons">
      {#each $impactScoreOptions as score}
        <button
          class="btn btn-default btn-flex"
          on:click={() => setImpact(score)}
        >
          <input type="radio" name="defusing[impact_score]" />
          <span>
            <ImpactScoreCircles {score} />
          </span>
          <span class="hidden-xs" style="min-width: 100px">
            {#if score == 0}
              <span>Ikke påvirket</span>
            {:else if score == 1}
              <span>Lidt</span>
            {:else if score == 5}
              <span>Meget</span>
            {:else}
              <span>&nbsp</span>
            {/if}
          </span>
        </button>
      {/each}
    </div>
  </div>
</div>

<style>
  .inputs {
    display: flex;
    flex-direction: column;
    width: 30%;
    margin: 0 auto;
  }

  .btn-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: left;
  }

  @media (max-width: 980px) {
    .inputs {
      width: 50%;
    }
  }

  @media (max-width: 769px) {
    .btn-flex {
      justify-content: center;
    }
  }

  @media (max-width: 480px) {
    .inputs {
      width: 100%;
    }
  }
</style>
