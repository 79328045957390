<script>
  export let steps
  export let currentActive

  export const calculateProgress = () => {
    let stepValue = 100 / steps.length
    let progressValue = stepValue * currentActive

    return progressValue
  }

  let progress = calculateProgress()

  export const handleProgress = (direction) => {
    currentActive += direction
    progress = calculateProgress()
  }
</script>

<div class="progress">
  <div
    class="progress-bar progress-bar-success"
    role="progressbar"
    style="width: {progress}%;"
  />
</div>

<style>
  .progress {
    height: 0.7rem;
    border-radius: 0;
  }
</style>
