<script>
  import ProgressBar from './ProgressBar.svelte'
  import { useForm } from '@inertiajs/svelte'

  export let steps = [] // Step components
  export let formData
  export let submitUrl

  let currentActive = 1
  let CurrentSlide = steps[currentActive - 1]
  let progressBar

  function handleProgress(direction) {
    currentActive += direction
    CurrentSlide = steps[currentActive - 1]
    progressBar.handleProgress(direction)
  }

  let form = useForm(formData)

  function submit() {
    $form.post(submitUrl)
  }
</script>

<ProgressBar {steps} bind:currentActive bind:this={progressBar} />

<form on:submit|preventDefault={submit}>
  <div>
    <svelte:component
      this={CurrentSlide.slide}
      triggerNextSlide={handleProgress}
    />
  </div>

  {#if CurrentSlide.showActions}
    <div class="progress-buttons-container">
      <button
        on:click|preventDefault={() => handleProgress(-1)}
        disabled={currentActive <= 1}
        class="btn btn-default">Tilbage</button
      >
      {#if CurrentSlide.showNext != false}
        {#if currentActive == steps.length}
          <button type="submit" class="btn btn-primary">Gem</button>
        {:else}
          <button
            on:click|preventDefault={() => handleProgress(+1)}
            disabled={currentActive >= steps.length}
            class="btn btn-default">Næste</button
          >
        {/if}
      {/if}
    </div>
  {/if}
</form>

<style>
  .progress-buttons-container {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 5rem;
  }

  .progress-buttons-container > .btn {
    width: 20rem;
  }
</style>
