<script>
  import { Stethoscope, AlertTriangle } from 'lucide-svelte'

  export let user
</script>

{#if user.is_hurt && user.is_hospital_contacted}
  <div class="text-danger">
    <AlertTriangle strokeWidth={2.5} size={16} />
    <Stethoscope strokeWidth={2.5} size={16} />
    <strong>{user.name || user.user.name}</strong>
    <span>er kommet til skade og er sendt på hospitalet</span>
  </div>
{:else if user.is_hurt}
  <div class="text-warning">
    <AlertTriangle strokeWidth={2.5} size={16} />
    <strong>{user.name || user.user.name}</strong>
    <span>er kommet til skade men er ikke sendt på hospitalet</span>
  </div>
{:else}
  <div>
    <strong>{user.name || user.user.name}</strong>
    <span>er ikke kommet til skade</span>
  </div>
{/if}
