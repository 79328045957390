<script>
  import UserAvatar from "../../../components/UserAvatar.svelte";

  import dayjs from "dayjs";

  export let response;
  export let response_types;

  const response_type = response_types.find(
    (type) => type.tag == response.acknowledgement_type
  );

  const response_text = response.response || response_type.text;
</script>

{#if response_text}
  <div class="media">
    <div class="media-left">
      <UserAvatar user={response.user} size="50" />
    </div>
    <div class="media-body">
      <strong>{response.user.name}</strong>
      <small class="text-muted">
        {dayjs(response.created_at).format('LLL')}
      </small>
      <br />
      {#if response.response_type == 'acknowledgement'}
        <div class="badge acknowledgement {response.acknowledgement_type}_badge">
          <div class="glyphicon glyphicon-{response_type.icon}" />
        </div>
      {/if}
      {response_text}
    </div>
  </div>
{/if}
