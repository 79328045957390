<script>
  import { Icon, ChatBubbleBottomCenterText } from 'svelte-hero-icons'
  import { truncate } from '@/utils/helpers.js'

  export let incident
  export let length = 50

  let string = incident.comment || incident.description
</script>

<div class="comment">
  <Icon src={ChatBubbleBottomCenterText} size="18" />
  <p class="comment-text">{truncate(string, length)}</p>
</div>

<style>
  .comment {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .comment-text {
    text-wrap: wrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0;
  }
</style>
