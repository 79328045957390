<script>
  import Colorbox from './Colorbox.svelte'
  import UserAvatar from '@/components/UserAvatar.svelte'

  export let registration
</script>

<div class="flex">
  <div class="avatar">
    <UserAvatar user={registration.user} />
    <div class="image_badge">
      <Colorbox wdr={registration} no_image />
    </div>
  </div>
  <strong class="name">
    {registration.user.name}
  </strong>
</div>

<style>
  .flex {
    display: flex;
    align-items: center;

    gap: 10px;
    flex-wrap: wrap;
    flex: 1;
    min-width: 200px;
  }

  .flex .name {
    margin-left: 1rem;
    flex: 1;
  }

  .image_badge {
    position: relative;
    top: -15px;
    right: -30px;
  }
</style>
