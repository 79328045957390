<script>
  import { onMount } from 'svelte'
  import dayjs from 'dayjs'
  import { Icon, CheckCircle, ArrowPath, PlusCircle } from 'svelte-hero-icons'
  import './floweffect.css'
  import { boardStore } from '@/stores/TaskBoardStore'
  import { userStore } from '@/stores/UserStore'
  import { citizenStore, getCitizenById } from '@/stores/CitizenStore'
  import Modal from '@/components/Modal.svelte'
  import TaskForm from './components/TaskForm.svelte'
  import Task from './components/Task.svelte'
  import CheckInModal from './components/CheckInModal.svelte'
  import TaskBoard from './components/TaskBoard.svelte'
  import { headerTemplate } from './components/headerTemplate'
  import { taskTemplate } from './components/taskTemplate'
  import DateSelection from './components/DateSelection.svelte'
  import BoardSelection from './components/BoardSelection.svelte'
  import { resetTaskForm } from '@/stores/TaskFormStore'
  import axios from 'axios'

  export let users
  export let citizens
  export let tasks
  export let allUsers

  let showNewTaskModal = false
  let showCheckInModal = false

  function updateTaskBoard() {
    showNewTaskModal = false
    let paramString =
      '?start_time=' +
      dayjs().startOf('day') +
      '&end_time=' +
      dayjs().endOf('day')
    axios.get('/tasks' + paramString).then((response) => {
      $boardStore.tasks = response.data
      let newTasks = []
      response.data.forEach((task) => {
        if ($boardStore.rows.find((row) => row.id == task.user_id) != null) {
          newTasks.push(formattedTaskData(task))
        }
      })
      $boardStore.boardTasks = newTasks
    })
  }

  function taskClass(task) {
    if (task.completed_at != null) {
      return 'done'
    } else if (
      dayjs(task.start_time) < dayjs() &&
      dayjs(task.end_time) < dayjs()
    ) {
      return 'late'
    } else {
      return ''
    }
  }

  function formattedTaskData(task) {
    return {
      id: task.id,
      resourceId: task.user_id,
      from: dayjs(task.start_time),
      to: dayjs(task.end_time),
      html: taskTemplate({
        label: task.name,
        citizen: getCitizenById(task.citizen_id),
        start_time: task.start_time,
        end_time: task.end_time,
      }),
      classes: taskClass(task),
      label: task.name,
      name: task.name,
      task_id: task.id,
      card_type: 'user',
      recurring: task.recurring,
    }
  }
  function formattedUserData(user) {
    let userTasks = []
    if ($boardStore.tasks != null) {
      userTasks = $boardStore.tasks.filter((task) => {
        return task.user_id == user.id
      })
    }

    userTasks = userTasks.sort((a, b) => {
      return dayjs(a.start_time) - dayjs(b.start_time)
    })

    return {
      id: user.id,
      headerHtml: headerTemplate(user, userTasks),
    }
  }

  function updateUsers() {
    axios
      .get('/user/checked_in/')
      .then((response) => {
        let newUsers = []
        response.data.forEach((user) => {
          newUsers.push(formattedUserData(user))
        })
        $boardStore.rows = newUsers
        updateTaskBoard()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function initializeData() {
    let formattedUsers = []
    let formattedTasks = []
    $userStore = allUsers
    $citizenStore = citizens

    users.forEach((user) => {
      formattedUsers.push(formattedUserData(user))
    })

    tasks.forEach((task) => {
      if (
        task.user_id != null &&
        users.find((user) => user.id == task.user_id) == null
      ) {
        return
      }
      formattedTasks.push(formattedTaskData(task))
    })
    $boardStore.rows = formattedUsers
    $boardStore.tasks = tasks
    $boardStore.boardTasks = formattedTasks
    // TODO: Refactor this!!!! HAck to make activity dots work on first load
    updateUsers()
  }
  onMount(() => {
    initializeData()
  })

  function closeModal() {
    showNewTaskModal = false
    resetTaskForm()
  }
</script>

<!-- Board selection -->
<BoardSelection board={'user'} />

<DateSelection
  startTime={$boardStore.startTime}
  endTime={$boardStore.endTime}
  on:updateTaskBoard={updateTaskBoard}
/>

<!-- Gantt chart -->
<TaskBoard on:updateTaskBoard={updateTaskBoard} on:updateUsers={updateUsers} />

<!-- Green corner buttons -->
<div class="green-corner-btns">
  <!-- BUG: Deactivated because "no users" row update closes modal -->

  <button
    class="green-corner-btn new-task-btn"
    on:click={() => {
      showNewTaskModal = !showNewTaskModal
    }}
  >
    <Icon src={PlusCircle} size="36" />
    Ny opgave
  </button>

  <button
    class="green-corner-btn check-in-btn"
    on:click={() => {
      showCheckInModal = !showCheckInModal
    }}
  >
    <Icon src={CheckCircle} size="36" />
    Tjek ind / ud
  </button>
</div>

<!-- Modals -->

<!-- New Task -->
<Modal bind:showModal={showNewTaskModal}>
  <span slot="header">Opret ny opgave</span>

  <TaskForm {closeModal} on:updateTaskBoard={updateTaskBoard} />
</Modal>

<!-- Edit Task -->
<Modal bind:showModal={$boardStore.showTaskModal}>
  <div slot="header">
    <div>
      Opgave: {$boardStore.selectedTask.name}
      {#if $boardStore.selectedTask.recurring}
        <span class="badge badge-pill badge-primary">
          <Icon src={ArrowPath} size="12" />
          Gentagende</span
        >
      {/if}
    </div>
  </div>

  <Task on:updateTaskBoard={updateTaskBoard} />
</Modal>

<!-- Check In -->
<Modal bind:showModal={showCheckInModal}>
  <span slot="header">Tjek ind / ud</span>

  <CheckInModal
    users={allUsers}
    bind:showModal={showCheckInModal}
    on:updateUsers={updateUsers}
    on:updateTaskBoard={updateTaskBoard}
  />
</Modal>

<style>
  .green-corner-btn {
    z-index: 100;
    border: none;
    background-color: #64cc7c;
    color: white;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 20px;
  }

  .green-corner-btn:hover,
  .green-corner-btn:active {
    background-color: #4e9e5d;
  }

  .green-corner-btns {
    display: flex;
    gap: 1rem;
    position: fixed;
    bottom: 5rem;
    right: 5rem;
    z-index: 100;
  }
</style>
