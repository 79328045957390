<script>
  import { page, Link } from '@inertiajs/svelte'
  import ActiveEmployeesTab from './tabs/ActiveEmployeesTab.svelte'
  import BlockedEmployeesTab from './tabs/BlockedEmployeesTab.svelte'
  import DeactivatedEmployeesTab from './tabs/DeactivatedEmployeesTab.svelte'
  import TeamsTab from './tabs/TeamsTab.svelte'

  const company = $page.props.auth.company
  export let memberships
  export let users_with_no_team
  export let active_by_company
  export let deactivated_by_company
  export let blocked_users
  export let teams

  const active_companies = Object.keys(active_by_company)
  const deactivated_companies = Object.keys(deactivated_by_company)
  let active_users_count = 0
  let deactivated_users_count = 0

  active_companies.forEach((company) => {
    active_users_count += active_by_company[company].length
  })
  deactivated_companies.forEach((company) => {
    deactivated_users_count += deactivated_by_company[company].length
  })
</script>

{#if company.teams_count > 0 && users_with_no_team.length > 0}
  <div class="alert alert-danger">
    Der er {users_with_no_team.length} brugere der ikke er i et team
  </div>
{/if}

<div class="row">
  <div class="col-xs-12 col-md-10 col-md-offset-1">
    <ul class="nav nav-tabs" role="tablist">
      <li class="active">
        <Link href="#active-employees-tab" data-toggle="tab" role="tab"
          >{active_users_count} Aktive medlemskaber</Link
        >
      </li>
      <li>
        <Link href="#blocked-employees-tab" data-toggle="tab" role="tab">
          {#if blocked_users.length > 0}
            <span class="glyphicon glyphicon-warning-sign"></span>
          {/if}
          {blocked_users.length} Blokerede medarbejdere
        </Link>
      </li>
      <li>
        <Link href="#deleted-employees-tab" data-toggle="tab" role="tab"
          >{deactivated_users_count} Deaktiverede medarbejdere</Link
        >
      </li>
      <li>
        <Link href="#team-overview-tab" data-toggle="tab" role="tab">
          {teams.length} Teams
        </Link>
      </li>
    </ul>

    <div class="tab-content">
      <ActiveEmployeesTab memberships={active_by_company} />
      <BlockedEmployeesTab users={blocked_users} />
      <DeactivatedEmployeesTab deactivated_employees={deactivated_by_company} />
      <TeamsTab {teams} />
    </div>
  </div>
</div>
