<script>
  import { chart } from 'svelte-apexcharts'

  export let data

  let options = {
    chart: {
      type: 'scatter',
      height: 350,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    series: data.series,
    xaxis: {
      categories: data.categories,
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: true,
      },
    },
    yaxis: {
      min: 1,
      max: 5,
      tickAmount: 5,
      labels: {
        formatter: function (value) {
          return value.toFixed(0)
        },
      },
    },
  }
</script>

{#if data.categories.length > 0}
  <div use:chart={options} />
{:else}
  <div class="alert alert-warning">
    Der er ikke nok data til at vise grafen.
  </div>
{/if}
