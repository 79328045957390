<script>
  import dayjs from 'dayjs'
  import { useForm, page, inertia } from '@inertiajs/svelte'
  import AvatarUploadForm from './AvatarUploadForm.svelte'

  export let user

  const policies = $page.props.auth.policies

  let form = useForm({
    user: {
      name: user.name,
      email: user.email,
    },
  })

  async function handleSubmit() {
    const submitUrl = `/users/${user.id}`

    await $form.put(submitUrl, {
      preserveState: true,
      onSuccess: () => {
        $form.clearErrors()
        $form.success
      },
      onError: () => {
        $form.errors
      },
    })
  }
</script>

<div class="row">
  <div class="col-xs-12 col-md-6">
    <div class="row">
      <div class="col-md-12">
        <h1>{user.name}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-md-4 text-center col-md-offset-1">
        <AvatarUploadForm {user} />
      </div>
    </div>
  </div>
  <div class="col-xs-12 col-md-6">
    <h2>Redigér profil</h2>
    <form on:submit|preventDefault={handleSubmit}>
      <div class="form-group {$form.errors.name ? 'error' : ''}">
        <label for="name">Navn</label>
        <input
          type="text"
          class="form-control"
          id="name"
          bind:value={$form.user.name}
        />
        {#if $form.errors.name}<div>{$form.errors.name}</div>{/if}
      </div>
      <div class="form-group {$form.errors.email ? 'error' : ''}">
        <label for="email">E-mail</label>
        <input
          type="email"
          class="form-control"
          id="email"
          bind:value={$form.user.email}
        />
        {#if $form.errors.email}<span class="error-text"
            >{$form.errors.email}</span
          >{/if}
      </div>
      <div class="form-group">
        <input type="submit" class="btn btn-primary" value="Opdatér profil" />
      </div>
    </form>
    <h2>Brugerhandlinger</h2>
    {#if policies.user.changePassword}
      <a href="/users/{user.id}/change_password" class="btn btn-primary"
        >Skift kodeord</a
      >
    {/if}
    {#if policies.loginTracks.index}
      <a href="/users/{user.id}/login_tracks" class="btn btn-default"
        >Login Tidspunkter</a
      >
    {/if}
    {#if policies.user.destroy && user.id != $page.props.auth.user.id}
      <button
        use:inertia={{ href: `/users/${user.id}`, method: 'delete' }}
        type="button"
        class="btn btn-danger">Deaktivér bruger</button
      >
    {/if}
    <br />
    <br />
    <a href="https://teameffect.io/gdpr/" target="_blank">Privatlivspolitik</a>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 pull-right text-right text-muted">
    <br />
    <br />
    Sidste login:
    <br />
    <span class="text-success">
      {dayjs(user.last_login).format('ll, LT')}
    </span>
    {#if user.failed_logins_since_prior_succesful_login.length > 0}
      <br />
      Mislykkede loginforsøg:
      {#each user.failed_logins_since_prior_succesful_login as failed_login}
        <br />
        <span class="text-danger">
          {dayjs(failed_login.created_at).format('ll, LT')}
        </span>
      {/each}
    {/if}
  </div>
</div>

<style>
  .form-group.error input {
    border: 2px solid #f05252;
  }

  .form-group.error {
    color: #f05252;
  }
</style>
