<script>
  import { page, router } from '@inertiajs/svelte'
  import { onMount } from 'svelte'
  import dayjs from 'dayjs'
  import Incident from './components/Incident.svelte'
  import AirDatepicker from 'air-datepicker'
  import localeDa from 'air-datepicker/locale/da'
  import { filterStore } from './stores/filterStore'
  import 'air-datepicker/air-datepicker.css'
  import PieChart from '../../components/Charts/PieChart.svelte'

  const today = dayjs().format('YYYY-MM-DD')
  let params = new URLSearchParams(window.location.search)

  export let incidents
  export let incident_categories
  export let users
  export let references
  export let search_start_date
  export let search_end_date
  export let total_pages
  export let current_page

  export let pieSeries
  export let pieLabels
  export let testSeries

  console.log('Series', pieSeries)
  console.log('testSeries', testSeries)

  const current_user = $page.props.auth.user

  const pages = Array.from({ length: total_pages }, (_, index) => index + 1)

  if (
    params.get('incident_search[start_date]') &&
    search_start_date != params.get('incident_search[start_date]')
  ) {
    search_start_date = params.get('incident_search[start_date]')
  }

  if (
    params.get('incident_search[end_date]') &&
    search_end_date != params.get('incident_search[end_date]')
  ) {
    search_end_date = params.get('incident_search[end_date]')
  }

  let start_date_picker
  let end_date_picker

  onMount(() => {
    start_date_picker = new AirDatepicker(`#start_date`, {
      dateFormat: 'dd/MM/yyyy',
      locale: localeDa,
      selectedDates: [$filterStore.start_date],
      maxDate: $filterStore.end_date,
      onSelect({ date }) {
        $filterStore.start_date = dayjs(date).format('YYYY-MM-DD')
        end_date_picker.minDate = date
      },
    })
    end_date_picker = new AirDatepicker(`#end_date`, {
      dateFormat: 'dd/MM/yyyy',
      locale: localeDa,
      selectedDates: [$filterStore.end_date],
      maxDate: today,
      minDate: search_start_date,
      onSelect({ date }) {
        $filterStore.end_date = dayjs(date).format('YYYY-MM-DD')
        start_date_picker.maxDate = date
      },
    })
  })

  $filterStore = {
    incident_search: {
      user_id: getParamValue(params.get('incident_search[user_id]')),
      event_category_id: getParamValue(
        params.get('incident_search[event_category_id]'),
      ),
      impact_score_category: getParamValue(
        params.get('incident_search[impact_score_category]'),
      ),
    },
    start_date: search_start_date,
    end_date: search_end_date,
  }

  if (references && references.length > 0) {
    $filterStore.incident_search.incident_reference_id = getParamValue(
      params.get('incident_search[incident_reference_id]'),
    )
  }

  function getParamValue(param) {
    if (param != null && param.length > 0) {
      if (parseInt(param) > 0) {
        return parseInt(param) // Int -> Id
      } else {
        return param // String -> eg. reference
      }
    }
  }

  function getSearchString() {
    let paramString = ''
    let filterKeys = Object.keys($filterStore.incident_search)
    filterKeys.forEach((key) => {
      let value =
        $filterStore.incident_search[key] != null
          ? $filterStore.incident_search[key]
          : ''
      paramString += `&incident_search[${key}]=${value}`
    })
    paramString += `&start_date=${$filterStore.start_date}&end_date=${$filterStore.end_date}`

    return paramString
  }

  function updateSearch() {
    window.location = '/events' + '?' + getSearchString()
  }
</script>

<div class="row">
  <div class="col-xs-12">
    <div class="visible-xs">
      <a href="/events/new" class="btn btn-primary btn-block">
        <div class="glyphicon glyphicon-plus" />
        Opret hændelse
      </a>
    </div>
    <div class="hidden-xs pull-right">
      <a href="/events/new" class="btn btn-primary">
        <div class="glyphicon glyphicon-plus" />
        Opret hændelse
      </a>
    </div>
    <div class="visible-xs">
      <a href={'/events.pdf' + '?' + params} class="btn btn-default btn-block">
        <div class="glyphicon glyphicon-download-alt" />
        Eksporter
      </a>
    </div>
    <div class="hidden-xs pull-right">
      <!-- !TODO set correct params -->
      <a
        href={'/events.pdf' + '?' + params}
        id="export-link"
        class="btn btn-default"
      >
        <div class="glyphicon glyphicon-download-alt" />
        Eksporter
      </a>
    </div>
    <hr class="visible-xs" />
  </div>
</div>

<div class="row">
  <div class="col-xs-12">
    <form on:submit|preventDefault={updateSearch} class="form-inline">
      <div class="form-group">
        <h3 class="form-text">Viser</h3>
        <div class="form-group">
          <select
            class="form-control"
            aria-label="viser"
            name="event_category_id"
            id="event_category_id"
            bind:value={$filterStore.incident_search.event_category_id}
          >
            <option value="">Alle Hændelsestyper</option>
            {#each incident_categories as incident_category}
              <option value={incident_category.id}
                >{incident_category.name}</option
              >
            {/each}
          </select>
        </div>
      </div>
      <div class="form-group">
        <h3 class="form-text">oplevet af</h3>
        <div class="form-group">
          {#if users}
            <select
              class="form-control"
              name="user_id"
              id="user_id"
              aria-label="oplevet af"
              bind:value={$filterStore.incident_search.user_id}
            >
              <option value="">Ingen bestemte medarbejdere</option>
              {#each users as user}
                <option value={user.id}>{user.name}</option>
              {/each}
            </select>
          {:else}
            <select
              class="form-control"
              name="user_id"
              id="user_id"
              disabled
              aria-label="oplevet af"
            >
              <option value={current_user.id} selected
                >{current_user.name}</option
              >
            </select>
          {/if}
        </div>
      </div>
      <div class="form-group">
        <h3 class="form-text">med</h3>
        <div class="form-group">
          <select
            class="form-control"
            name="impact_score_category"
            id="impact_score_category"
            aria-label="med"
            bind:value={$filterStore.incident_search.impact_score_category}
          >
            <option value="">Alle påvirkningsgrader</option>
            <option value="high_impact">Høj påvirkning (4 og 5)</option>
            <option value="low_impact">Mindre påvirkning (1, 2 og 3)</option>
            <option value="no_impact">Ingen påvirkning (0)</option>
          </select>
        </div>
      </div>
      {#if references && references.length > 0}
        <div class="form-group">
          <h3 class="form-text">for reference</h3>
          <div class="form-group">
            <select
              class="form-control"
              name="incident_reference_id"
              id="incident_reference_*id"
              bind:value={$filterStore.incident_search.incident_reference_id}
            >
              <option value="">Alle referencer</option>
              {#each references as reference}
                <option value={reference.id}>{reference.name}</option>
              {/each}
            </select>
          </div>
        </div>
      {/if}
      <div class="form-group">
        <h3 class="form-text">i perioden</h3>
        <div class="form-group">
          <input
            class="form-control"
            type="text"
            name="start_date"
            id="start_date"
            aria-label="fra dato"
          />
        </div>
      </div>
      <div class="form-group">
        <h3 class="form-text">til</h3>
        <div class="form-group">
          <input
            class="form-control"
            type="text"
            name="end_date"
            id="end_date"
            aria-label="til dato"
          />
        </div>
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-default">
          <div class="glyphicon glyphicon-refresh" />
          Opdater
        </button>
      </div>
    </form>
  </div>
</div>

{#if incidents.length > 0}
  <div class="row">
    <div class="col-xs-12 col-sm-12">
      <h3>Hændelser</h3>
      {#each incidents as incident (incident.id)}
        <Incident {incident} />
      {/each}
      <div class="text-center">
        {#if total_pages > 1}
          <ul class="pagination">
            {#if current_page > 1}
              <li>
                <a href="/events?page=1" aria-label="Første">
                  <span aria-hidden="true">&laquo; Første</span>
                </a>
              </li>
              <li>
                <a href="/events?page={current_page - 1}" aria-label="Forrige">
                  <span aria-hidden="true">&lsaquo; Forrige</span>
                </a>
              </li>
            {/if}
            {#each pages as page}
              {#if page === current_page}
                <li class="active">
                  <span>{page}</span>
                </li>
              {:else}
                <li>
                  <a href="/events?page={page}{getSearchString()}">{page}</a>
                </li>
              {/if}
            {/each}
            {#if current_page < total_pages}
              <li>
                <a
                  href="/events?page={current_page + 1}{getSearchString()}"
                  aria-label="Næste"
                >
                  <span aria-hidden="true">Næste &rsaquo;</span>
                </a>
              </li>
              <li>
                <a
                  href="/events?page={total_pages}{getSearchString()}"
                  aria-label="Næste"
                >
                  <span aria-hidden="true">Sidste &raquo;</span>
                </a>
              </li>
            {/if}
          </ul>
        {/if}
      </div>
    </div>
  </div>
{/if}

<div style="margin-bottom: 50px">
  <PieChart series={pieSeries} labels={pieLabels} />
</div>

<style>
  .form-text {
    display: inline;
    margin-right: 5px;
    margin-left: 5px;
  }
</style>
