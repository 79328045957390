<script>
  import DistributionChart from './DistributionChart.svelte'
  import StackedWorkdayChart from './StackedWorkdayChart.svelte'
  import { score_color } from '@/utils/wdr_helper.js'

  export let identifier
  export let company
  export let data
  export let currentActive

  let registrations = data.registrations

  const workdays = registrations.filter((r) => r.workday_type === 'workday')
  const sickdays = registrations.filter(
    (r) => r.workday_type === 'sickness_absense',
  )
  const sickdaysCount = sickdays.length
  const workdaysByScore = workdays.reduce((acc, curr) => {
    const score = curr.score
    if (acc[score]) {
      acc[score] += 1
    } else {
      acc[score] = 1
    }
    return acc
  }, {})

  let series = Object.entries(workdaysByScore).map(([score, count]) => {
    return {
      name: score,
      data: [count],
      color: score_color(score),
    }
  })

  series.unshift({
    name: 'Sygedage',
    data: [sickdaysCount],
    color: '#D490D3',
  })
</script>

<div
  id="{identifier}-{company}"
  class="tab-pane {currentActive == company ? 'active' : ''}"
>
  {#if registrations.length > 0}
    <div class="row">
      <div class="col-xs-12">
        <div class="text-center">
          <DistributionChart data={series} />
        </div>
        <StackedWorkdayChart data={data.chart_data} />
      </div>
    </div>
  {:else}
    <div class="alert alert-warning">
      {company} har ingen arbejdsdage registeret den seneste måned
    </div>
  {/if}
</div>
