<script>
  import { citizenFlagMessageFormStore } from '@/stores/CitizenFlagMessageFormStore.js'
  import { citizenStore } from '@/stores/CitizenStore.js'
  import axios from 'axios'

  export let citizen

  $: formData = {
    citizen_flag_message: {
      message: $citizenFlagMessageFormStore.message,
      citizen_id: citizen.id,
    },
  }

  function createFlagMessage() {
    axios
      .post(`/floweffect/citizen_flag_messages`, formData)
      .then((response) => {
        $citizenStore.flag_messages = [
          ...$citizenStore.flag_messages,
          response.data,
        ]
        citizenFlagMessageFormStore.reset()
      })
  }
</script>

<div class="flex flex-col text-left">
  <form on:submit|preventDefault={createFlagMessage}>
    <div class="form-group">
      <label for="message">OBS besked</label>
      <textarea
        type="text"
        name="message"
        id="message"
        bind:value={$citizenFlagMessageFormStore.message}
        class="form-control"
        rows="2"
      ></textarea>
    </div>
    <button
      type="submit"
      class="btn btn-block btn-success"
      on:submit|preventDefault={() => {
        createFlagMessage()
      }}>Opret</button
    >
  </form>
</div>
