<script>
  import { onMount } from 'svelte'
  import dayjs from 'dayjs'
  import { taskForm } from '@/stores/TaskFormStore.js'
  import { boardStore } from '@/stores/TaskBoardStore'
  import RecurringTimeSelection from './RecurringTimeSelection.svelte'

  let wekdayNumber = dayjs($taskForm.start_time).day()
  let selectedWeekDays = $boardStore.selectedTask
    ? $boardStore.selectedTask.recurring.validations.day
    : []
  let interval = $boardStore.selectedTask
    ? `${$boardStore.selectedTask.recurring.interval}`
    : '1'

  $: recurringRule = {
    interval: interval,
    until: null, // TODO: Implement a way out
    count: null,
    validations: null,
    rule_type: 'IceCube::WeeklyRule',
    week_start: 1,
  }

  function setRecurringWeekDays(selectedWeekDays) {
    recurringRule.validations = {
      day: selectedWeekDays.sort(),
    }
  }

  $: setRecurringWeekDays(selectedWeekDays)
  $: $taskForm.recurring = JSON.stringify(recurringRule)
  $: console.log($boardStore.selectedTask)
</script>

<div><strong>Interval</strong></div>
<div class="input-container">
  <select
    name="interval"
    id="interval"
    class="form-control"
    bind:value={recurringRule.interval}
    required
  >
    <option value="1" selected>Hver uge</option>
    <option value="2">Hver anden uge</option>
  </select>
</div>

<div><strong>Ugedage</strong></div>
<div class="weekdays-container input-container">
  <div>
    <input
      type="checkbox"
      name="monday"
      id="monday"
      checked={wekdayNumber == 1}
      bind:group={selectedWeekDays}
      value={1}
    />
    <label for="monday">Mandag</label>
  </div>
  <div>
    <input
      type="checkbox"
      name="thuesday"
      id="thuesday"
      checked={wekdayNumber == 2}
      bind:group={selectedWeekDays}
      value={2}
    />
    <label for="thuesday">Tirsdag</label>
  </div>
  <div>
    <input
      type="checkbox"
      name="wednesday"
      id="wednesday"
      checked={wekdayNumber == 3}
      bind:group={selectedWeekDays}
      value={3}
    />
    <label for="wednesday">Onsdag</label>
  </div>
  <div>
    <input
      type="checkbox"
      name="thursday"
      id="thursday"
      checked={wekdayNumber == 4}
      bind:group={selectedWeekDays}
      value={4}
    />
    <label for="thursday">Torsdag</label>
  </div>
  <div>
    <input
      type="checkbox"
      name="friday"
      id="friday"
      checked={wekdayNumber == 5}
      bind:group={selectedWeekDays}
      value={5}
    />
    <label for="friday">Fredag</label>
  </div>
  <div>
    <input
      type="checkbox"
      name="saturday"
      id="saturday"
      checked={wekdayNumber == 6}
      bind:group={selectedWeekDays}
      value={6}
    />
    <label for="saturday">Lørdag</label>
  </div>
  <div>
    <input
      type="checkbox"
      name="sunday"
      id="sunday"
      checked={wekdayNumber == 0}
      bind:group={selectedWeekDays}
      value={0}
    />
    <label for="sunday">Søndag</label>
  </div>
</div>
<RecurringTimeSelection />

<style>
  .weekdays-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .input-container {
    margin-bottom: 1rem;
  }

  .weekdays-container label {
    margin-top: 10px;
    font-weight: 400 !important;
    font-size: 14px !important;
  }

  .time-container {
    margin-top: 20px;
  }

  .time-input-container {
    display: flex;
    gap: 2rem;
  }
</style>
