<script>
  import { page, router } from '@inertiajs/svelte'
  import UserAvatar from '@/components/UserAvatar.svelte'
  import Rights from '@/components/Rights.svelte'

  export let membership

  const company = $page.props.auth.company
  const policies = $page.props.auth.policies
  const current_user = $page.props.auth.user

  function deactivate() {
    router.delete(`/memberships/${membership.id}`)
  }
</script>

<div class="row">
  <div class="col-xs-12">
    <hr />
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-sm-3 col-md-3">
    <UserAvatar user={membership.user} size="50" />
    <strong>{membership.user.name}</strong>
  </div>
  <div class="col-xs-12 col-sm-3 col-md-3">
    <div class="visible-xs">
      <br />
    </div>
    <a href="/memberships/{membership.id}/edit">
      <small>
        <Rights {membership} />
      </small>
    </a>
  </div>
  <div class="col-xs-12 col-sm-3 col-md-3">
    {#if company.teams_count > 0}
      {#if membership.user.teams.length > 0}
        {#each membership.user.teams as team}
          <a href="/teams/{team.id}">
            <span class="label label-default">{team.name}</span>
          </a>
        {/each}
        <div class="visible-xs">
          <br />
        </div>
      {:else if policies.team.index}
        <a
          href="#team-overview-tab"
          data-toggle="tab"
          role="tab"
          class="team-tab-link"
        >
          <div class="alert alert-danger" style="margin-bottom: 0px;">
            <div class="glyphicon glyphicon-warning-sign"></div>
            Ikke en del af et team
            <span class="alert-link"> Klik her for at løse problemet </span>
          </div>
        </a>
      {/if}
    {/if}
  </div>
  <div class="col-xs-12 col-sm-3 col-md-3">
    {#if current_user.id != membership.user.id}
      <button
        on:click={deactivate}
        class="btn btn-sm btn-block btn-default"
        style="margin-bottom: 2px"
      >
        <span class="glyphicon glyphicon-ban-circle"></span>
        Deaktivér medlemskab
      </button>
    {/if}
    <div class="dropdown">
      <button
        class="btn btn-sm btn-default btn-block dropdown-toggle"
      >
        Mere
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu">
        <li>
          <a href="users/{membership.user.id}/login_tracks">Login Log</a>
        </li>
        <li>
          <a href="/users/{membership.user.id}/change_password">
            <span class="glyphicon glyphicon-lock"></span>
            Skift kodeord
          </a>
        </li>
        <li>
          <a href="/memberships/{membership.id}/edit"> Rettigheder </a>
        </li>
        <li>
          <a href="/users/{membership.user.id}"> Rediger bruger </a>
        </li>
      </ul>
    </div>
  </div>
</div>
