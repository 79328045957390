<script>
  import { score_color } from '/utils/wdr_helper'
  import { workdayRegistrationForm } from '../../../stores/WorkdayRegistrationFormStore'

  export let triggerNextSlide

  function setScore(score) {
    triggerNextSlide(+1)
    $workdayRegistrationForm.score = score
  }

  let scores = [5, 4, 3, 2, 1]
</script>

<div class="container">
  <div class="row" style="margin-top: 3rem">
    <div class="col-xs-12 col-md-8 col-md-offset-2 wdr-container">
      <h2 class="text-center">Hvordan har din dag været?</h2>

      {#each scores as score}
        <div>
          <input
            type="radio"
            name="impact-score"
            id="score-{score}"
            value={score}
            on:change={() => setScore(score)}
          />
          <label
            for="score-{score}"
            class="btn btn-primary btn-block btn-lg"
            style="background-color: {score_color(score)}"
          >
            {#if score == 1}
              <div class="glyphicon glyphicon-thumbs-down" />
            {:else if score == 5}
              <div class="glyphicon glyphicon-thumbs-up" />
            {:else}
              &nbsp;
            {/if}
          </label>
        </div>
      {/each}
    </div>
  </div>
</div>

<style>
  .wdr-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
  }

  input[type='radio'] {
    display: none;
  }
</style>
