<script>
  import { useForm } from '@inertiajs/svelte'

  export let defusingUser
  export let showModal

  let form = useForm({
    defusing_user: {
      is_hurt: defusingUser.is_hurt,
      is_hospital_contacted: defusingUser.is_hospital_contacted,
    },
  })

  const submit = () => {
    $form.put(`/defusing_users/${defusingUser.id}`, {
      onSuccess: () => {
        showModal = false
      },
    })
  }
</script>

<div class="row">
  <div class="col-xs-12 col-sm-8 col-sm-offset-2">
    <h3>{defusingUser.name || defusingUser.user.name}</h3>

    <form on:submit|preventDefault={submit}>
      <div class="checkbox">
        <label>
          <input type="checkbox" bind:checked={$form.defusing_user.is_hurt} />
          Er kommet til skade
        </label>
      </div>

      <div class="checkbox">
        <label>
          <input
            type="checkbox"
            bind:checked={$form.defusing_user.is_hospital_contacted}
          />
          Er taget på hospitalet
        </label>
      </div>

      <button type="submit" class="btn btn-primary">Opdater</button>
    </form>
  </div>
</div>
