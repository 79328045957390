<script>
  import SameDayScore from './SameDayScore.svelte'

  export let registrations = []
  export let teams = false
</script>

<div class="row">
  <div class="col-xs-12">
    <div class="grid">
      {#if teams}
        {#each Object.entries(registrations) as [name, registrations]}
          <h4 class="team-name">{name ? name : 'Ikke i team'}</h4>
          {#each registrations as registration}
            <SameDayScore {registration} />
          {/each}
        {/each}
      {:else}
        {#each registrations as registration}
          <SameDayScore {registration} />
        {/each}
      {/if}
    </div>
  </div>
</div>

<style>
  .grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }

  .team-name {
    grid-column: 1 / -1;
  }
</style>
