<script>
  import { organizationUsers, defusingsForm } from '@/stores/DefusingFormStore'
  import { page } from '@inertiajs/svelte'
  import UserSelect from '../components/UserSelect.svelte'
  import { onDestroy } from 'svelte'

  const currentCompany = $page.props.auth.company

  let selected = []

  onDestroy(() => {
    $defusingsForm.user_ids = [...$defusingsForm.user_ids, ...selected]
  })
</script>

<h2 class="text-center">Var der personale tilstede fra andre afdelinger?</h2>

<div class="row">
  <div class="col-xs-12 col-sm-8 col-sm-offset-2">
    <div class="panel-group" role="tablist" aria-multiselectable="true">
      {#each $organizationUsers as c}
        {#if c.id != currentCompany.id}
          <div class="panel panel-default">
            <div class="panel-heading" role="tab">
              <a
                href="#company-list-{c.id}"
                data-toggle="collapse"
                aria-controls="#company-list-{c.id}"
              >
                <div class="panel-title">
                  {c.name}
                </div>
              </a>
            </div>
            <div
              class="panel-collapse collapse"
              role="tabpanel"
              id="company-list-{c.id}"
            >
              <div class="panel-body">
                <UserSelect
                  users={c.active_users}
                  bind:selectedUsers={selected}
                />
              </div>
            </div>
          </div>
        {/if}
      {/each}
    </div>
  </div>
</div>
