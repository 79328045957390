<script>
  export let title
</script>

<div class="row">
  <div class="col-xs-12">
    <div class="flex flex-row">
      <div>
        <h2>
          {title}
        </h2>
        {#if $$slots.subtitle}
          <h4 class="text-muted">
            <slot name="subtitle" />
          </h4>
        {/if}
      </div>
      {#if $$slots.rightHeader}
        <div>
          <slot name="rightHeader" />
        </div>
      {/if}
    </div>
  </div>
</div>
<div class="row">
  <div class="col-xs-12">
    <slot />
  </div>
</div>

<style>
  .flex {
    display: flex;
  }
  .flex-row {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
</style>
