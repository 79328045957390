<script>
  import UserAvatar from '@/components/UserAvatar.svelte'
  import pluralize from 'pluralize'
  import ColumnChart from '../ColumnChart.svelte'
  import ScoreBlock from './ScoreBlock.svelte'
  import ScoreSummary from './ScoreSummary.svelte'

  pluralize.addPluralRule(/e$/i, 'er')

  export let sample
  export let priorSample
  export let eventsCount
  export let data
</script>

<div class="row period-sample">
  <div class="col-xs-12 col-md-3">
    <UserAvatar user={sample.user} size="25" />
    <strong>{sample.user.name}</strong>
  </div>
  <div class="col-xs-12 col-md-4">
    <small class="text-muted">
      {pluralize('bedømmelse', sample.score_registrations, true)}
      {#if eventsCount > 0}
        og {pluralize('hændelse', eventsCount, true)}
      {/if}
      i perioden
    </small>
    <ColumnChart {data} periodSample height="20" />
  </div>
  <div class="col-xs-12 col-md-3">
    <div class="hidden-md" style="display: inline-block;">
      <ScoreBlock score={sample.average_score} />
      <ScoreSummary
        currentScore={sample.average_score}
        priorScore={priorSample.average_score}
        ignoreChanged
      />
    </div>
    <div class="visible-md pull-right">
      <ScoreBlock score={sample.average_score} />
      <ScoreSummary
        currentScore={sample.average_score}
        priorScore={priorSample.average_score}
      />
    </div>
  </div>
  <div class="col-xs-12 col-md-2">
    <div class="hidden-xs pull-right">
      <a
        href="/workday_registration_reports/user_report/{sample.user
          .id}?start_date={sample.from_date}&end_date={sample.to_date}"
        class="btn btn-default"
      >
        Se detaljer
      </a>
    </div>
    <div class="visible-xs" style="margin: 1rem 0;">
      <a
        href="/workday_registration_reports/user_report/{sample.user
          .id}?start_date={sample.from_date}&end_date={sample.to_date}"
        class="btn btn-default btn-block"
      >
        Se detaljer
      </a>
    </div>
  </div>
</div>

<style>
  .period-sample {
    height: 45px;
  }
</style>
