<script>
  import ReportMenu from '@/components/ReportsNavigation/ReportMenu.svelte'
  import CompanySelect from './CompanySelect.svelte'

  export let companies
  export let npsSurvey
</script>

<ReportMenu />

<div class="row">
  <div class="col-xs-12 col-md-8 col-md-offset-2 text-center">
    {#if companies.length > 1}
      <h3>Du kan vælge en anden enhed</h3>
      <CompanySelect {companies} />
    {/if}

    <span style="font-size: 10em">⚠️</span>
    <h2>eNPS rapporten kan ikke vises</h2>
    <div class="lead">
      Der skal minimum være 5 medarbejdere, der har svaret på undersøgelsen for
      at sikre, at undersøgelsens deltagere har den rette anonymitet.
    </div>
    <br />
    {#if npsSurvey}
      <div class="lead">
        {npsSurvey.nps_scores_count} har svaret på undersøgelsen
      </div>
    {/if}
  </div>
</div>
