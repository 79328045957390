<script>
  import { page } from '@inertiajs/svelte'

  const company = $page.props.auth.company

  export let teams
</script>

<div class="tab-pane" id="team-overview-tab" role="tabpane">
  <div class="row">
    <div class="col-xs-12">
      <div class="pull-right">
        <br />
        <a href="/teams/new" class="btn btn-primary">Opret team</a>
      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-xs-12">
      <div class="alert alert-info">
        Oprettelse af teams kan være brugbart, hvis I er mere end ét team på
        arbejdspladsen.
      </div>
      <ul class="list-group">
        {#if teams.length > 0}
          {#each teams as team (team.id)}
            <a href="/teams/{team.id}">
              <li class="list-group-item">
                <div class="pull-right text-sm text-muted">
                  {team.users.length} Medarbejdere
                  {#if company.has_citizen_accounting_product}
                    <br />
                    {team.citizens.length} Borgere
                  {/if}
                </div>
                <div style="padding-top: 10px">{team.name}</div>
              </li>
            </a>
          {/each}
        {:else}
          <div class="text-center">Der eksisterer ingen teams</div>
        {/if}
      </ul>
    </div>
  </div>
</div>

<style>
  .list-group-item {
    height: 60px;
    margin-bottom: 10px;
  }
</style>
