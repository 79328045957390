<script>
  import dayjs from 'dayjs';
  import ImpactScoreCircles from './ImpactScoreCircles.svelte';

  export let event;
</script>

<div class="list-group-item">
  <div class="row">
    <div class="col-xs-5 col-sm-2 col-md-2">
      {event.event_category.name}
      <br />
      <small class="text-muted">
        {dayjs(event.day).format('ll')}
      </small>
    </div>
    <div class="col-xs-7 col-sm-4 col-md-5">
      <div class="text-center">
        <ImpactScoreCircles score={event.impact_score} with_text />
      </div>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-5 event-bar-buttons">
      <div class="visible-xs">
        <br />
      </div>
      {#if event.comment || event.impact_score >= 4}
        <a href="/events/{event.id}" class="btn btn-sm btn-default">
          <div class="glyphicon glyphicon-comment" />
          Se detaljer
        </a>
      {/if}
    </div>
  </div>
</div>
