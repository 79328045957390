import { writable } from 'svelte/store'
import dayjs from 'dayjs'
import { formatDate } from '@/utils/dateHelper'

function defaultStartTime(scoped = false) {
  const time = dayjs().subtract(2, 'hour').startOf('hour')

  if (scoped) {
    return time.isBefore(dayjs().startOf('day'))
      ? formatDate(dayjs().startOf('day'))
      : formatDate(time)
  }

  return formatDate(dayjs().startOf('day'))
}
function defaultEndTime(scoped = false) {
  const time = dayjs().add(7, 'hour').startOf('hour')
  if (scoped) {
    return time.isAfter(dayjs().endOf('day'))
      ? formatDate(dayjs().endOf('day'))
      : formatDate(time)
  }

  return formatDate(dayjs().endOf('day'))
}

function createStore() {
  const { subscribe, set, update } = writable({
    rows: [],
    tasks: [],
    boardTasks: [],
    selectedTask: null,
    taskGrabbingActivated: false,
    grabbedTasks: [],
    userGrabbingTasks: null,
    showTaskModal: false,
    safeToReload: true,
    startTime: defaultStartTime(true),
    endTime: defaultEndTime(true),
    stickyTime: true,
  })

  return {
    subscribe,
    set,
    update,
    addGrabbedTask: (task) => {
      update((store) => {
        store.grabbedTasks.push(task)
        return store
      })
    },
    resetGrabbedTasks: () => {
      update((store) => {
        store.grabbedTasks = []
        store.taskGrabbingActivated = false
        return store
      })
    },
    toggleStickyTime: () => {
      update((store) => {
        store.stickyTime = !store.stickyTime
        if (store.stickyTime) {
          store.startTime = defaultStartTime(true)
          store.endTime = defaultEndTime(true)
        } else {
          store.startTime = defaultStartTime(false)
          store.endTime = defaultEndTime(false)
        }
        return store
      })
    },
    resetTime: () => {
      update((store) => {
        store.startTime = defaultStartTime(store.stickyTime)
        store.endTime = defaultEndTime(store.stickyTime)
        return store
      })
    },
  }
}

export const boardStore = createStore()
