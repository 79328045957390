<script>
  import AirDatepicker from 'air-datepicker'
  import localeDa from 'air-datepicker/locale/da'
  import 'air-datepicker/air-datepicker.css'
  import { onMount } from 'svelte'
  import { useForm } from '@inertiajs/svelte'
  import dayjs from 'dayjs'

  export let vacation_period

  let today = dayjs().format('DD/MM/YYYY')

  let form = useForm({
    vacation_period: {
      user_id: vacation_period.user_id,
      title: vacation_period.title || 'Ferie',
      start_date: vacation_period.start_date || today,
      end_date: vacation_period.end_date || today,
    },
  })

  let dateRange

  onMount(() => {
    dateRange = new AirDatepicker('#date_range', {
      range: true,
      multipleDatesSeparator: ' - ',
      dateFormat: 'dd/MM/yyyy',
      locale: localeDa,
      selectedDates: [today, today],
    })
  })

  function handleSubmit() {
    $form.vacation_period.start_date = dayjs(dateRange.selectedDates[0]).format(
      'DD/MM/YYYY',
    )
    $form.vacation_period.end_date = dayjs(dateRange.selectedDates[1]).format(
      'DD/MM/YYYY',
    )
    $form.post('/vacation_periods')
  }
</script>

<div class="row">
  <div class="col-xs-12 col-md-8 col-md-offset-2">
    <div class="row">
      <div class="col-xs-12">
        <h3>Ny fri-periode</h3>
        <div class="text-muted text-small">
          <div class="glyphicon glyphicon-exclamation-sign" />
          Her kan du registere en fri-periode hvis du f.eks. skal på ferie, kursus,
          barsel eller på anden måde har flere dage hvor du ikke er på din arbejdsplads
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <br />
        <form on:submit|preventDefault={handleSubmit}>
          <div class="form-group">
            <label for="title">Årsag til fri-periode</label>
            <input
              type="text"
              class="form-control"
              id="title"
              bind:value={$form.vacation_period.title}
            />
            <p class="help-block">F.eks. ferie, barsel, kursus</p>
          </div>
          <div class="form-group">
            <label for="start">Periode</label>
            <input type="text" class="form-control" id="date_range" />
          </div>
          <div class="from-group text-center">
            <button type="submit" class="btn btn-primary"
              >Gem fri-periode</button
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
