<script>
  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()

  export let moneyTypes

  $: index = 0
  $: moneyType = moneyTypes[index]

  const moneyTypeTitle = (moneyType) => {
    let amount = moneyType.amount
    let type = ''
    if (amount >= 1) {
      type = 'kr.'
      if (amount >= 50) {
        type += ' sedler'
      } else {
        type += ' mønter'
      }
    } else {
      amount = amount * 100
      type = 'øre mønter'
    }

    return `Antal ${amount} ${type}`
  }

  const continueEntry = () => {
    index += 1
    if (index >= moneyTypes.length) {
      // close keyboard
      index = 0
    }
  }

  const updateAmount = (e, moneyType) => {
    dispatch('updateAmount')
  }
</script>

<div class="container money-container text-center">
  <div class="row">
    <div class="col-xs-12 col-md-4 col-md-offset-4">
      <img
        src="/assets/money/{moneyType.name}"
        class="keyboard-image"
        alt=""
        width={moneyType.width}
        height={moneyType.height}
      />
    </div>
  </div>
  <h2 class="text-center">{moneyTypeTitle(moneyType)}</h2>
  <div class="row">
    <div class="col-xs-12 col-md-4 col-md-offset-4">
      <span class="input-group input-group-lg btn-block">
        <input
          id="money-type-count"
          type="number"
          min="0"
          step="1"
          placeholder="0"
          class="form-control"
          on:change={(e) => updateAmount(e, moneyType)}
          bind:value={moneyType.count}
        />
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-md-4 col-md-offset-4">
      <div class="actionBtns">
        <button class="actionBtn btn btn-default btn-lg">Slut</button>
        <button
          class="actionBtn btn btn-primary btn-lg"
          on:click={continueEntry}
        >
          Videre
        </button>
      </div>
    </div>
  </div>
</div>

<style>
  .money-container {
    margin-bottom: 1rem;
  }

  .actionBtns {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }

  .actionBtn {
    flex: 1;
  }
</style>
