<script>
  import { eventForm } from '@/stores/EventFormStore'

  function setReference(reference) {
    $eventForm.incident_reference_id = reference.id
  }
</script>

<div class="row" style="margin-top: 6rem">
  <div class="col-xs-12 col-md-8 col-md-offset-2">
    <h2 style="text-align: center; margin-bottom: 4rem">
      Vil du knytte hændelsen til en reference?
    </h2>
    <h4>Eksisterende referencer</h4>
    <div class="reference-button-group">
      {#each $eventForm.form_data.references as reference}
        <button
          class="btn btn-default {$eventForm.incident_reference_id ==
          reference.id
            ? 'active'
            : ''}"
          on:click|preventDefault={() => {
            setReference(reference)
          }}>{reference.name}</button
        >
      {/each}
    </div>
  </div>
</div>

<style>
  .reference-button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 4rem;
  }

  .reference-button-group > button {
    flex: 1;
  }
</style>
