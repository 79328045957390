import { writable } from 'svelte/store'
import { get } from 'svelte/store'

function createStore() {
  const { subscribe, set, update } = writable([])

  return {
    subscribe,
    set,
    update,
    get: () => get({ subscribe }),
  }
}

export const citizenStore = createStore()

export function getCitizenById(citizen_id) {
  return citizenStore.get().find((citizen) => citizen.id === citizen_id)
}
