<script>
  export let citizen = null
  export let objectives = []
</script>

<div class="container">
  <h2>Udviklingsmål</h2>

  <div class="objectives-container">
    {#each objectives as objective}
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">{objective.title}</h4>
          <div class="card-text">
            {#if objective.description}
              {objective.description}
            {/if}
          </div>
        </div>
      </div>
    {/each}
  </div>
</div>

<style>
  .container {
    margin-top: 50px;
  }

  .objectives-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .card {
    width: 30%;
    margin-bottom: 20px;
  }
</style>
