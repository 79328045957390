<script>
  import { useForm } from '@inertiajs/svelte'
  import AvatarUploadForm from '@/pages/Users/AvatarUploadForm.svelte'

  export let citizen

  let form = useForm({
    citizen: {
      name: citizen.name,
      notes: citizen.notes,
    },
  })

  const handleSubmit = async () => {
    await $form.put(`/citizens/${citizen.id}`)
  }

  const deactivateCitizen = async () => {
    if (confirm('Er du sikker på at du vil deaktivere denne bruger?')) {
      await $form.delete(`/citizens/${citizen.id}`)
    }
  }
</script>

<svelte:head>
  <title>{citizen.name}</title>
</svelte:head>

<div class="row">
  <div class="col-xs-12 col-md-6">
    <div class="row">
      <div class="col-md-12">
        <h1>{citizen.name} profil</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-md-4">
        <AvatarUploadForm user={citizen} type="citizen" />
      </div>
    </div>
  </div>
  <div class="col-xs-12 col-md-6">
    <h1 class="user-edit-profile-container">Redigér profil</h1>
    <form on:submit|preventDefault={handleSubmit}>
      <div class="form-group {$form.errors.name ? 'has-error' : ''}">
        <label class="control-label" for="name">Navn</label>
        <input
          type="text"
          class="form-control"
          id="name"
          bind:value={$form.citizen.name}
        />
        {#if $form.errors.name}
          <span class="error-text help-block">{$form.errors.name}</span>
        {/if}
      </div>
      <div class="form-group {$form.errors.notes ? 'has-error' : ''}">
        <label class="control-label" for="notex">Noter</label>
        <textarea
          class="form-control"
          id="notes"
          rows="5"
          bind:value={$form.citizen.notes}
        />
        {#if $form.errors.notes}
          <span class="error-text help-block">{$form.errors.notes}</span>
        {/if}
      </div>
      <div class="form-group">
        <input
          type="submit"
          class="btn btn-primary"
          value="Opdatér profil"
          disabled={$form.processing}
        />
        <button
          on:click|preventDefault={deactivateCitizen}
          class="btn btn-danger citizen-profile-btn pull-right"
          >Deaktivér bruger</button
        >
      </div>
    </form>
  </div>
</div>
