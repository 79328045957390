<script>
  export let wdr
  export let width = '25px'
  export let height = '25px'

  let cssClass = ''

  switch (wdr.workday_type) {
    case 'workday':
      cssClass = `score-${wdr.score}`
      break
    default:
      cssClass = `workday-type-${wdr.workday_type}`
      break
  }
  console.log(cssClass)
</script>

<span
  style="width: {width}; height: {height}; line-height: {height}"
  class="label label-default {cssClass} score-{wdr.score}"
>
  &nbsp;&nbsp;
</span>

<style>
  span {
    padding: 0px;
    display: inline-block;
    margin-right: 3px;
    color: black;
    text-align: center;
    vertical-align: middle;
    margin-right: 3px;
  }

  .label-icon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .score-1 {
    background-color: #ca0000 !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
  .score-2 {
    background-color: #ecc600 !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  .score-3 {
    background-color: #d9dfb8 !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  .score-4 {
    background-color: #a9d95a !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  .score-5 {
    background-color: #00b425 !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  .no-score {
    background-color: #428bca !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  .workday-type-day_off,
  .workday-type-week_off {
    background-color: #00dfeb !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  .workday-type-sickness_absense {
    background-color: #d490d3 !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
</style>
