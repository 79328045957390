<script>
  import dayjs from 'dayjs'
  import { chart } from 'svelte-apexcharts'

  export let data

  let options = {
    chart: {
      type: 'bar',
      height: 300,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: [6],
        borderRadiusApplication: 'end',
      },
    },
    series: data.series,
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(0)
        },
      },
    },
    xaxis: {
      categories: data.categories,
      labels: {
        formatter: function (val) {
          return dayjs(val, 'DD/MM YYYY').format('ll')
        },
      },
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
  }
</script>

<div use:chart={options} />
