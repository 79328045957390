<script>
  import { page } from '@inertiajs/svelte'

  import dayjs from 'dayjs'
  import isToday from 'dayjs/plugin/isToday'
  import isYesterday from 'dayjs/plugin/isYesterday'

  dayjs.extend(isToday)
  dayjs.extend(isYesterday)

  const urlParams = new URLSearchParams(window.location.search)
  const dateString = urlParams.get('date')

  export let triggerNextSlide

  const dateText = () => {
    const date = new Date(dateString)

    if (dayjs(date).isToday()) {
      return 'i dag'
    } else if (dayjs(date).isYesterday()) {
      return 'i går'
    } else {
      return dayjs(date).format('dddd [d.] D. MMMM')
    }
  }
</script>

<div class="row" style="margin-top: 6rem">
  <div class="col-xs-12 col-md-4 col-md-offset-4">
    <h2 class="text-center">Udfyld {dateText()}</h2>
    <div class="buttons-container">
      <button
        class="btn btn-primary btn-block btn-lg"
        on:click={triggerNextSlide(1)}
      >
        Bedøm arbejdsdag
      </button>
      <a
        href="/workday_registrations/create_absense_day/{$page.props.auth.user
          .id}/{dateString}/day_off"
        class="btn btn-primary btn-block btn-lg">Fridag</a
      >
      <a
        href="/workday_registrations/create_absense_day/{$page.props.auth.user
          .id}/{dateString}/sickness_absense"
        class="btn btn-primary btn-block btn-lg">Syg</a
      >
    </div>
  </div>
</div>

<style>
  .buttons-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
</style>
