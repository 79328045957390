<script>
  import { createEventDispatcher } from 'svelte'
  import { Icon, CalendarDays, Clock } from 'svelte-hero-icons'
  import Button from '@/components/Button.svelte'
  import { boardStore } from '@/stores/TaskBoardStore'

  // Passed as props because we want to hold the values until clicking "save"
  export let startTime
  export let endTime

  let saveButtonDisabled = true
  let saveButtonVariant = 'default'
  const dispatch = createEventDispatcher()

  function handleSubmit() {
    $boardStore.stickyTime = false
    $boardStore.startTime = startTime
    $boardStore.endTime = endTime
    dispatch('updateTaskBoard')
  }

  function enableSaveButton() {
    saveButtonDisabled = false
    saveButtonVariant = 'primary'
  }

  function toggleStickyTime() {
    boardStore.toggleStickyTime()
    dispatch('updateTaskBoard')
  }
</script>

<form class="form-inline" on:submit|preventDefault={handleSubmit}>
  <div class="dateSelectionContainer">
    <div>
      <button
        class={$boardStore.stickyTime
          ? 'btn btn-default sticky-time-btn'
          : 'btn btn-primary sticky-time-btn'}
        on:click|preventDefault={toggleStickyTime}
      >
        <Icon src={Clock} size="16" class="font-bold" />
        {#if $boardStore.stickyTime}
          Stop automatisk tid
        {:else}
          Start automatisk tid
        {/if}
      </button>
    </div>
    <div style="display: flex; align-items: center; gap: 0.5em;">
      <Icon src={CalendarDays} size="24" />
      <input
        type="datetime-local"
        name="start_date"
        id="start_date"
        class="form-control"
        bind:value={startTime}
        on:click={enableSaveButton}
        disabled={$boardStore.stickyTime}
      />
    </div>
    <span class="dateSeperator">-</span>
    <input
      type="datetime-local"
      name="end_date"
      id="end_date"
      class="form-control"
      bind:value={endTime}
      on:click={enableSaveButton}
      disabled={$boardStore.stickyTime}
    />
    <Button
      size={'sm'}
      variant={saveButtonVariant}
      disabled={saveButtonDisabled}>Gem</Button
    >
  </div>
</form>

<style>
  .dateSelectionContainer {
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 1em;
    margin-right: 2rem;
    margin-bottom: 2rem;
  }
  .dateSeperator {
    font-size: 1.5em;
  }
  .sticky-time-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
</style>
