<script>
  import DateRangeSelectionForm from './DateRangeSelectionForm.svelte'
  import { page } from '@inertiajs/svelte'
  import dayjs from 'dayjs'
  import { startDate, endDate } from './store.js'

  const urlParams = new URLSearchParams(window.location.search)

  if (urlParams.has('start_date')) {
    $startDate = dayjs(urlParams.get('start_date'))
  }
  if (urlParams.has('end_date')) {
    $endDate = dayjs(urlParams.get('end_date'))
  }
</script>

{#if $page.props.meta.app_client}
  <div class="text-center">
    <a href="#date-selection-container" data-toggle="collapse" class="btn">
      <div class="glyphicon glyphicon-calendar" />
      Vælg periode
    </a>
  </div>
  <br />
  <div id="date-selection-container" class="collapse">
    <DateRangeSelectionForm />
    <br />
    <br />
  </div>
{:else}
  <DateRangeSelectionForm />
{/if}
