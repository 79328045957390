<script>
  import ReportMenu from '@/components/ReportsNavigation/ReportMenu.svelte'
  import OrganizationSelect from './components/OrganizationSelect.svelte'
  import AreaChartBox from './components/AreaChartBox.svelte'
  import WorkdayDistributionChart from './components/WorkdayDistributionChart.svelte'
  import WorkdayCompaniesCards from './components/WorkdayCompaniesCards.svelte'

  export let companies
  export let report
  export let workdayReport
  export let incidentReport
  export let companiesWithFullAccess

  const fmtNumber = new Intl.NumberFormat('da-DK').format

  const columnWidth = incidentReport.incidents ? 4 : 6
</script>

<ReportMenu current_tab="organization_report" />

<div class="row">
  <div class="col-xs-12 col-md-8 col-md-offset-2">
    <OrganizationSelect {companies} reportType="all" />

    <h1>Rapport for {report.company.name}</h1>
    <h3>Overordnet aktivitetsniveau seneste 6 måneder</h3>
    <p>
      I de forløbne 6 måneder er der totalt set blevet opsamlet <strong
        >{fmtNumber(
          workdayReport.work_and_sickdays_last_six_months.length,
        )}</strong
      >
      daglige bedømmelser blandt medarbejderne.
      <strong
        >{fmtNumber(
          workdayReport.work_and_sickdays_last_six_months_with_comments_count,
        )}</strong
      >
      af dem indeholder også en kommentar direkte til lederen.
      {#if incidentReport && incidentReport.incidents.length > 0}
        I samme periode er der oprettet <strong
          >{fmtNumber(incidentReport.incidents.length)}</strong
        >
        hændelser - heraf
        <strong
          >{fmtNumber(incidentReport.incidents_with_high_impact.length)}</strong
        > med "høj påvirkning".
      {/if}
    </p>
    <br />
    <p>
      Ser vi på den seneste måned, blev der bedømt <strong
        >{fmtNumber(workdayReport.registrations_last_month.length)}</strong
      >
      arbejdsdage med
      <strong
        >{fmtNumber(
          workdayReport.registrations_last_month_with_comments_count,
        )}</strong
      >
      kommentarer.
      {#if incidentReport && incidentReport.incidents_last_month.length > 0}
        Der blev i samme periode oprettet <strong
          >{fmtNumber(incidentReport.incidents_last_month.length)}</strong
        >
        hændelser - heraf
        <strong
          >{fmtNumber(
            incidentReport.incidents_last_month_with_high_impact.length,
          )}</strong
        > med "høj påvirkning".
      {/if}
    </p>
    <div class="row">
      <div class="col-xs-12 col-md-{columnWidth}">
        <AreaChartBox
          title="arbejdsdage bedømt"
          range="seneste 6 måneder"
          total_number={fmtNumber(
            workdayReport.work_and_sickdays_last_six_months.length,
          )}
          bottom_data="{fmtNumber(
            workdayReport.registrations_last_month.length,
          )} bedømmelser den seneste måned"
          data={workdayReport.work_and_sickdays_last_six_months_chart_data}
        />
      </div>
      <div class="col-xs-12 col-md-{columnWidth}">
        <AreaChartBox
          title="kommentarer knyttet til dagen"
          range="seneste 6 måneder"
          total_number={fmtNumber(
            workdayReport.work_and_sickdays_last_six_months_with_comments_count,
          )}
          bottom_data="{fmtNumber(
            workdayReport.registrations_last_month_with_comments_count,
          )} kommentarer den seneste måned"
          data={workdayReport.work_and_sickdays_last_six_months_with_comments_chart_data}
        />
      </div>
      {#if incidentReport}
        <div class="col-xs-12 col-md-{columnWidth}">
          <AreaChartBox
            title="hændelser"
            range="seneste 6 måneder"
            total_number={fmtNumber(incidentReport.incidents.length)}
            bottom_data="{fmtNumber(
              incidentReport.incidents_last_month.length,
            )} hændelser den seneste måned"
            data={incidentReport.chart_data.incidents}
          />
        </div>
      {/if}
    </div>
    <div class="row">
      <WorkdayDistributionChart {report} />
    </div>
    <div class="row">
      <WorkdayCompaniesCards {report} {companiesWithFullAccess} />
    </div>
  </div>
</div>
