<script>
  import { defusingsForm, organizationUsers } from '@/stores/DefusingFormStore'
  import { page } from '@inertiajs/svelte'

  const currentCompany = $page.props.auth.company
</script>

<div class="row">
  <div class="col-xs-12 col-sm-8 col-sm-offset-2 text-center">
    {#if $defusingsForm.defusing_type == 'mutual'}
      <h2>Hvilken afdeling blive defusingen gennemført på?</h2>
    {:else}
      <h2>Hvilken afdeling løb du til?</h2>
    {/if}
  </div>
</div>
<div class="row text-center company-select">
  <div class="form-group">
    <div class="input-group">
      <select
        name="company_id"
        id="company_id"
        class="form-control"
        bind:value={$defusingsForm.company_id}
      >
        {#each $organizationUsers as c}
          <option value={c.id} selected={c.id == currentCompany.id}
            >{c.name}</option
          >
        {/each}
      </select>
    </div>
  </div>
</div>

<style>
  .company-select {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
  }

  .company-select .form-group {
    width: 50%;
  }

  .company-select .form-group .input-group {
    width: 100%;
  }
</style>
