<script>
  import { useForm } from '@inertiajs/svelte'
  import UserRow from './components/UserRow.svelte'
  import CitizenRow from './components/CitizenRow.svelte'
  import axios from 'axios'

  export let team
  export let companyUsers
  export let teamUsers
  export let citizens
  export let teamCitizens

  const companies = Object.keys(companyUsers)

  let form = useForm({
    team: {
      name: team.name,
      user_ids: teamUsers.map((user) => user.id),
      citizen_ids: teamCitizens.map((citizen) => citizen.id),
    },
  })

  const handleSubmit = () => {
    const url = `/teams/${team.id}`

    $form.put(url, {
      preserveState: true,
      onSuccess: () => {
        $form.clearErrors()
        $form.success
      },
      onError: () => {
        $form.errors
      },
    })
  }

  const deleteTeam = () => {
    const url = `/teams/${team.id}`

    axios.delete(url).then(() => {
      window.location = '/memberships#team-overview-tab'
    })
  }
</script>

<div class="content">
  <div class="row">
    <div class="col-xs-12 col-md-6 col-md-offset-3">
      <h1>{team.name}</h1>
      <div class="lead">
        Her kan du vælge hvem der skal være en del af teamet
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <form on:submit|preventDefault={handleSubmit}>
        <div class="row">
          <div
            class="col-md-6 col-md-offset-3 form-group {$form.errors.name
              ? 'has-error'
              : ''}"
          >
            <label class="control-label" for="name">Name</label>
            <input
              id="name"
              type="text"
              class="form-control"
              bind:value={$form.team.name}
            />
            {#if $form.errors.name}
              <span class="help-block">Navn {$form.errors.name}</span>
            {/if}
          </div>
        </div>
        <div class="row">
          <div
            class="user-rows {citizens.length > 0
              ? ''
              : 'col-md-6 col-md-offset-3'}"
          >
            <fieldset class="form-group text-center">
              <label for="users">Medarbejdere</label>
              <div class="row">
                <div class="col-xs-12">
                  {#each companies as company}
                    {#if companies.length > 1}
                      <h4>{company}</h4>
                    {/if}
                    {#each companyUsers[company] as user}
                      <UserRow {user} companyName={company} bind:form />
                    {/each}
                  {/each}
                </div>
              </div>
            </fieldset>

            {#if citizens.length > 0}
              <fieldset class="form-group text-center">
                <label for="users">Borgere</label>
                <div class="row">
                  <div class="col-xs-12">
                    {#each citizens as citizen}
                      <CitizenRow {citizen} bind:form />
                    {/each}
                  </div>
                </div>
              </fieldset>
            {/if}
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6 col-md-offset-3">
            <button type="submit" class="btn btn-block btn-primary"
              >Opdater Team</button
            >
            <div class="seperator">
              <hr />
              <span>Eller</span>
              <hr />
            </div>
            <button
              type="button"
              on:click={deleteTeam}
              class="btn btn-block btn-danger">Slet Team</button
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<style>
  .content {
    margin-bottom: 5rem;
  }
  .seperator {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 1rem 0;
  }

  .seperator hr {
    flex: 1;
    border: none;
    height: 1px;
    background: #333;
  }

  .seperator span {
    padding: 0 1rem;
    color: #333;
    font-weight: bold;
  }

  .user-rows {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 1rem;
  }

  fieldset {
    flex: 1;
  }

  @media (max-width: 1000px) {
    .user-rows {
      flex-direction: column;
    }
  }
</style>
