<script>
  import UserAvatar from '@/components/UserAvatar.svelte'

  export let inactiveUsers = []
</script>

<div style="margin-top: 100px">
  <h2>
    Inaktive medarbejdere <small>Under 5 registreringer i perioden</small>
  </h2>
  <div id="inactive-users">
    {#each inactiveUsers as user}
      <a href="/workday_registration_reports/user_report/{user.id}">
        <div class="inactive-user-link">
          <UserAvatar {user} size="35" />
          <div class="inactive-user-info">
            <div class="user-name">
              {user.name}
            </div>
            <div>
              {user.name} har ingen registreringer
            </div>
          </div>
        </div>
      </a>
    {/each}
  </div>
</div>

<style>
  .inactive-user-link {
    display: flex;
    color: black;
    margin-bottom: 30px;
    gap: 40px;
    margin-left: 15px;
  }

  .inactive-user-info {
    line-height: 20px;
  }
</style>
