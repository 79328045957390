import { writable } from 'svelte/store'

export const workdayRegistrationForm = writable({
  day: null,
  score: null,
  comment: null,
  emotion_id: null,
  shift_type: 'not_registered',
  postive_comment: null,
  negative_comment: null,
  feelings: [],
  form_data: [
    {
      emotions: [],
      positive_selections: null,
      negative_selections: null,
    },
  ],
})
