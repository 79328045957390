<script>
  import InboxCard from '@/components/InboxCard.svelte'
  import ReportMenu from '@/components/ReportsNavigation/ReportMenu.svelte'

  export let workday_registrations
  export let response_types
</script>

<ReportMenu current_tab={'inbox'} />

{#if workday_registrations.length > 0}
  <div class="row">
    <div class="col-xs-12 col-md-8 col-md-offset-2">
      <h2>
        Bedømmelser med en kommentar
        <a
          href="/inbox/mark_unread_wdrs_as_read"
          class="btn btn-default pull-right hidden-xs"
        >
          <div class="glyphicon glyphicon-flag" />
          Markér alle som læst
        </a>
      </h2>
      <a
        href="/inbox/mark_unread_wdrs_as_read"
        class="btn btn-default btn-block pull-right visible-xs"
        style="margin-bottom: 2rem"
      >
        <div class="glyphicon glyphicon-flag" />
        Markér alle som læst
      </a>

      {#each workday_registrations as wdr}
        <InboxCard {wdr} {response_types} />
      {/each}
    </div>
  </div>
{:else}
  <div class="text-center">
    <h3>
      <div style="font-size: 80px">👏</div>
      Du er up-to-date!
    </h3>
  </div>
{/if}
