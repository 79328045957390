<script>
  import { createEventDispatcher } from "svelte";

  export let value = ''; // Selected Value
  export let label = ''; // Label for the input
  export let collection = []; // Collection of items
  export let valueKey = 'id' // The key to use as value
  export let labelKey = 'name' // The key to show in list
  export let allowNone = false; // Allow to select none

  const dispatch = createEventDispatcher();

  let localCollection = collection;

  $: searchQuery = '';
  $: showContent = false;
  $: selectedValue = value;
  $: selectedItem = collection.find(item => {
    return item[valueKey] == value
  });

  $: selectedLabel = selectedItem ? selectedItem[labelKey] : 'None';

  $: if (searchQuery) {
    localCollection = collection.filter(item => {
      return item[labelKey].toLowerCase().includes(searchQuery.toLowerCase())
    });
  } else {
    localCollection = collection;
  }


  const toggleContent = () => {
    showContent = !showContent;
    if (!showContent) {
      searchQuery = '';
    }
  }

  const setSelectedValue = (event) => {
    if (!event.target.value) {
      selectedValue = null;
    } else {
      selectedValue = collection.find(item => {
        return item[valueKey] == event.target.value
      });
    }

    dispatch('selected', selectedValue);

    toggleContent()
  }

</script>

<div class="dropdown-box">
  <label for="selected">{label}</label>
  <div class="selected-item" on:click={toggleContent} role="button" tabindex="0">
    <input type="text" name="selected" value={selectedLabel} id="selected" readonly />
  </div>
  <div class="dropdown-content {showContent ? 'active' : ''}">
    <div class="search-input">
      <input type="text" placeholder="Search..." id="search" bind:value={searchQuery} />
    </div>
    <ul class="dropdown-items">
      {#if allowNone}
      <option class="dropdown-item" value={null} on:click={setSelectedValue}>
        None
      </option>
      {/if}
      {#each localCollection as item (item[valueKey])}
      <option class="dropdown-item {selectedValue == item[valueKey] ? "active" : ""}" value={item[valueKey]} on:click={setSelectedValue}>
        {item[labelKey]}
      </option>
      {/each}
    </ul>
  </div>
</div>

<style>
  .dropdown-box {
    position: relative;
    width: 100%;
  }

  .dropdown-box .selected-item,
  .dropdown-box .selected-item input{
    cursor: pointer;
  }

  .dropdown-box input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
  }

  .dropdown-box .dropdown-content {
    width: 100%;
    background-color: #fff;
    z-index: 999;
    box-shadow: 0 5px 15px rgb(0 0 0 / 15%);
    border-radius: 5px;
    max-height: 240px;
    overflow-y: auto;
    display: none;
    position: absolute;
  }

  .dropdown-box .dropdown-content.active {
    display: block;
  }

  .dropdown-box .dropdown-content .search-input {
    padding: 5px;
  }

  .dropdown-box .dropdown-content .dropdown-items {
    list-style: none;
    padding: 0;
  }

  .dropdown-box .dropdown-item {
    padding: 2px 5px;
    cursor: pointer;
  }

  .dropdown-box .dropdown-item:hover {
    background-color: #f9f9f9;

  }

  .dropdown-box .dropdown-item.active {
    background-color: #7aafff;
  }
</style>
