<script>
  import { taskTemplate } from '@/pages/Tasks/components/taskTemplate'
  import '@/pages/Tasks/floweffect.css'
  import { boardStore } from '@/stores/TaskBoardStore'
  import { modalStore as taskModal } from '@/stores/ModalStore'

  export let task

  function openTaskModal() {
    $boardStore.selectedTask = task
    taskModal.openModal()
  }
</script>

<div
  class="sg-task"
  on:click={() => {
    openTaskModal()
  }}
>
  <div class="sg-task-content">
    {@html taskTemplate({
      label: task.name,
      citizen: task.user,
      start_time: task.start_time,
      end_time: task.end_time,
    })}
  </div>
</div>

<style>
  .sg-task {
    cursor: pointer;
  }

  .sg-task-content {
    padding: 14px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 100px;
    max-width: 150px;
  }
</style>
