<script>
  import {
    startDate,
    endDate,
  } from '../../../components/ReportsNavigation/store.js'
  import dayjs from 'dayjs'
  import ImprovementArrow from './ImprovementArrow.svelte'
  import ImpactScoreCircles from '../../Events/components/ImpactScoreCircles.svelte'

  export let eventCategoriesByImpact

  let start_date
  let end_date

  startDate.subscribe((value) => {
    start_date = dayjs(value).format('YYYY-MM-DD')
  })

  endDate.subscribe((value) => {
    end_date = dayjs(value).format('YYYY-MM-DD')
  })
</script>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <div class="grid-col">
        {#each eventCategoriesByImpact as category (category.id)}
          <div class="grid">
            <div>
              <a
                href="/events?incident_search[event_category_id]={category.id}&start_date={start_date}&end_date={end_date}"
              >
                <h4>{category.name}</h4>
              </a>
            </div>
            <div class="improvement">
              <ImprovementArrow
                currentCount={category.incidents_count}
                priorCount={category.incidents_count_last_month}
              />
              <strong>{category.incidents_count}</strong> Hændelser
            </div>
            <div>
              <ImpactScoreCircles score={category.avg_impact} with_text />
            </div>
            <div class="high_impact">
              {#if category.high_impact_count_this_period > 0}
                <a
                  href="/events?incident_search[event_category_id]={category.id}&incident_search[impact_score_category]=high_impact&start_date={start_date}&end_date={end_date}"
                  class="btn btn-sm btn-default pull-right"
                >
                  <strong class="text-danger">
                    {category.high_impact_count_this_period} med Høj påvirkning
                  </strong>
                </a>
              {/if}
            </div>
          </div>
        {/each}
      </div>
    </div>
  </div>
</div>

<style>
  .improvement {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
  }

  .grid-col {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
</style>
