<script>
  import dayjs from 'dayjs'

  export let events
  export let eventCategory
</script>

<div class="row">
  <div class="col-xs-12 col-md-6 col-md-offset-3">
    <h1>Hændelseskategori: {eventCategory.name}</h1>

    <div class="panel panel-default">
      <div class="panel-body total-events-bar">
        <span class="text-muted">
          <div class="pull-left">Totalt antal registreret</div>
          <div class="pull-right">
            <span class="text-danger">
              {eventCategory.events_count}
            </span>
          </div>
        </span>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 col-md-6 col-md-offset-3">
    <div
      class="panel-group"
      id="accordion"
      role="tablist"
      aria-multiselectable="true"
    >
      {#each events as [month, events], i (month)}
        <div class="panel panel-primary">
          <div class="panel-heading" role="tab" id="heading{month}">
            <h4 class="panel-title">
              <a
                class="collapsed"
                role="button"
                data-toggle="collapse"
                data-parent="#accordion"
                href="#collapse{i}"
                aria-expanded="false"
                aria-controls="collapse{i}"
              >
                {dayjs(month).format('MMMM YYYY')} ({events.length})
              </a>
            </h4>
          </div>
          <div
            id="collapse{i}"
            class="panel-collapse collapse"
            role="tabpanel"
            aria-labelledby="heading{month}"
          >
            <div class="panel-body">
              {#each events as event (event.id)}
                <div class="list-group">
                  <li class="list-group-item">
                    <span class="pull-left">
                      {event.user.name}
                    </span>
                    <span class="pull-right">
                      {dayjs(event.day).format('ll')}
                    </span>
                  </li>
                </div>
              {/each}
            </div>
          </div>
        </div>
      {/each}
    </div>
  </div>
</div>
