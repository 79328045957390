<script>
  import { chart } from 'svelte-apexcharts'

  export let lastFiveSurveys
  export let colors

  let options = {
    chart: {
      type: 'bar',
      stacked: true,
      height: 350,
    },
    series: lastFiveSurveys,
    colors: colors,
  }
</script>

<div use:chart={options} />
