<script>
  import { beforeUpdate } from 'svelte'

  import MultiStepForm from '@/components/MultiStepForm.svelte'

  // Slides
  import RegistrationTypeSlide from './Slides/RegistrationTypeSlide.svelte'
  import WorkdayColorSelectionSlide from './Slides/WorkdayColorSelectionSlide.svelte'
  import CommentSlide from './Slides/CommentSlide.svelte'
  import { workdayRegistrationForm } from '@/stores/WorkdayRegistrationFormStore'
  import ShiftType from './Slides/ShiftType.svelte'
  import PositiveQuestions from './Slides/PositiveQuestions.svelte'
  import NegativeQuestions from './Slides/NegativeQuestions.svelte'

  export let emotions = []
  export let question_slides = []
  export let positive_selections = []
  export let negative_selections = []
  let steps = []

  // Setting default store data
  const urlParams = new URLSearchParams(window.location.search)
  $workdayRegistrationForm.day = urlParams.get('date')
  $workdayRegistrationForm.form_data.emotions = emotions
  $workdayRegistrationForm.form_data.positive_selections = positive_selections
  $workdayRegistrationForm.form_data.negative_selections = negative_selections

  function assignSlides() {
    question_slides.forEach((slide) => {
      switch (slide) {
        case 'workday_type':
          steps.push({ slide: RegistrationTypeSlide, showActions: false })
          break
        case 'shift_type':
          steps.push({ slide: ShiftType, showActions: true })
          break
        case 'overall_question':
          steps.push({
            slide: WorkdayColorSelectionSlide,
            showActions: true,
            showNext: false,
          })
          break
        case 'comment':
          steps.push({ slide: CommentSlide, showActions: true })
          break
        case 'positive_questions':
          steps.push({ slide: PositiveQuestions, showActions: true })
          break
        case 'negative_questions':
          steps.push({ slide: NegativeQuestions, showActions: true })
          break
        default:
          break
      }
    })
  }

  let skipUpdate = false // Issue in svelete where beforeUpdate is called twice -  https://github.com/sveltejs/svelte/issues/6016
  beforeUpdate(() => {
    if (!skipUpdate) {
      assignSlides()
    }
    skipUpdate = true
  })

  $: formData = { workday_registration: $workdayRegistrationForm }
</script>

<MultiStepForm {steps} submitUrl="/workday_registrations" {formData} />
