<script>
  import { chart } from 'svelte-apexcharts'

  export let series = []
  export let labels = []
  export let colors = []
  export let height = 380

  let options = {
    chart: {
      type: 'donut',
      height: 380,
    },
    series: series,
    labels: labels,
    colors: colors,
    legend: {
      show: true,
      position: 'bottom',
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: { show: true, color: 'black' },
            total: {
              showAlways: false,
              show: false,
              color: 'black',
              label: 'Score',
            },
          },
        },
      },
    },
  }
</script>

<div use:chart={options} />
