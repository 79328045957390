<script>
  import { objectiveFormStore } from '@/stores/ObjectiveFormStore.js'
  import { citizenStore } from '@/stores/CitizenStore.js'
  import axios from 'axios'

  export let citizen

  $: formData = {
    citizen_objective: {
      title: $objectiveFormStore.title,
      description: $objectiveFormStore.description,
    },
  }

  function createObjective() {
    axios
      .post(`/citizens/${citizen.id}/citizen_objectives/`, formData)
      .then((response) => {
        $citizenStore.objectives = [...$citizenStore.objectives, response.data]
        objectiveFormStore.reset()
      })
  }
</script>

<div class="flex flex-col text-left">
  <form on:submit|preventDefault={createObjective}>
    <div class="form-group">
      <label for="title">Mål overskrift</label>
      <input
        type="text"
        name="title"
        id="title"
        bind:value={$objectiveFormStore.title}
        class="form-control"
      />
    </div>
    <div class="form-group">
      <label for="description">Beskrivelse</label>
      <textarea
        type="text"
        name="description"
        id="description"
        bind:value={$objectiveFormStore.description}
        class="form-control"
        rows="10"
      ></textarea>
    </div>
    <button
      type="submit"
      class="btn btn-block btn-success"
      on:submit|preventDefault={() => {
        createObjective()
      }}>Opret</button
    >
  </form>
</div>
