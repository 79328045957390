<script>
  import dayjs from 'dayjs'
  import WeekSeperator from './WeekSeperator.svelte'
  import NotScoredBlock from './HistoryBar/NotScoredBlock.svelte'
  import NotScoredPopover from './HistoryBar/NotScoredPopover.svelte'
  import WorkdayBlock from './HistoryBar/WorkdayBlock.svelte'
  import VacationBlock from './HistoryBar/VacationBlock.svelte'
  import WorkdayModal from './HistoryBar/WorkdayModal.svelte'

  export let workdayRegistrations = []
  export let vacationPeriods = null
  export let events = []
  export let user
  export let dateRange

  let historyWidth = 0

  $: showModal = false
  $: modalWorkday = null

  $: showNotScoredModal = false
  $: dateNotScored = null
  $: offset = 0
  $: index = 0

  const eventDates = Object.keys(events)

  const showWorkdayModal = (e) => {
    showModal = true
    modalWorkday = e.detail
  }

  const showNotScored = (e) => {
    console.log('HELLO')
    const date = e.detail.date
    offset = historyWidth / workdayRegistrations.length

    index = dayjs(date).diff(dayjs(dateRange.start), 'day')
    showNotScoredModal = true
    dateNotScored = date
  }

  const dateInVacation = (date) => {
    if (vacationPeriods) {
      return vacationPeriods.some((vp) => {
        return dayjs(date).isBetween(vp.start_date, vp.end_date, null, '[]')
      })
    }
    return false
  }

  const update = (e) => {
    let { _, date, workday_type } = e.detail
    let unitIndex = workdayRegistrations.findIndex((u) => u.date === date)
    workdayRegistrations[unitIndex].wdr = { workday_type }
    workdayRegistrations[unitIndex].type = 'workday'
    workdayRegistrations = [...workdayRegistrations]
  }

  const eventOnDate = (date) => {
    return eventDates.includes(date)
  }
</script>

<div class="progress wdr-history-progress-bar" bind:clientWidth={historyWidth}>
  {#each workdayRegistrations as { date, wdr, type } (user.id + '_' + date)}
    <div class="unit">
      {#if type === 'workday'}
        <WorkdayBlock
          {date}
          {wdr}
          on:open_wdr={showWorkdayModal}
          event={eventOnDate(date)}
        />
      {:else if type === 'sickness_absense'}
        <WorkdayBlock {date} {wdr} on:open_wdr={showWorkdayModal} sickday />
      {:else if type === 'day_off' || dateInVacation(date)}
        <VacationBlock {date} />
      {:else}
        <NotScoredBlock
          {date}
          {user}
          on:open_not_scored_modal={showNotScored}
        />
      {/if}
      {#if dayjs(date).day() == 1}
        <WeekSeperator {date} showDate={workdayRegistrations.length <= 64} />
      {/if}
    </div>
  {/each}
</div>

<WorkdayModal bind:showModal bind:modalWorkday />

<NotScoredPopover
  {user}
  bind:date={dateNotScored}
  bind:show={showNotScoredModal}
  bind:index
  bind:offset
  on:update_wdr={update}
/>

<style>
  .wdr-history-progress-bar {
    display: flex;
    justify-content: space-evenly;
    gap: 1px;
  }

  .unit {
    width: 100%;
  }
</style>
