<script>
  import UserAvatar from '@/components/UserAvatar.svelte'

  export let user
  export let form
  export let companyName

  $: checked = $form.team.user_ids.includes(user.id)

  const handleChange = () => {
    checked = !checked
    if (checked) {
      $form.team.user_ids.push(user.id)
    } else {
      $form.team.user_ids = $form.team.user_ids.filter((id) => id !== user.id)
    }
  }
</script>

<div
  on:click={handleChange}
  class="user panel {checked ? 'panel-success' : 'panel-default'}"
>
  <div class="panel-heading">
    <div class="start">
      <UserAvatar {user} />
      <h3 class="panel-title">{user.name}</h3>
    </div>
    <h5>{companyName}</h5>
  </div>
</div>

<style>
  .user {
    min-width: 400px;
  }
  .panel-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .start {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .user {
    cursor: pointer;
  }
</style>
