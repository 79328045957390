<script>
  import Modal from '@/components/Modal.svelte'
  import { createEventDispatcher } from 'svelte'
  import axios from 'axios'

  const dispatch = createEventDispatcher()

  export let showModal = false

  const handleSelect = (emoji) => {
    dispatch('emojiSelcted', { emoji })
    showModal = false
  }

  const getEmojis = async () => {
    const response = await axios.get(`/reaction/emojis`)
    const emojis = await response.data
    console.log(emojis)
    return emojis
  }
</script>

<Modal bind:showModal>
  <div class="header" slot="header">
    <h4 class="modal-title">Vælg Reaktion</h4>
  </div>
  <div class="content">
    {#await getEmojis()}
      Henter emojis
    {:then response}
      <div class="emojis">
        {#each Object.entries(response) as [emoji]}
          <button
            class="btn emoji-container"
            on:click={() => handleSelect(emoji)}
          >
            <span class="emoji">{emoji}</span>
          </button>
        {/each}
      </div>
    {:catch error}
      {console.log(error)}
      Der skete en fejl
    {/await}
  </div>
</Modal>

<style>
  .header {
    flex: 1;
  }

  .emojis {
    width: 100%;
    margin: 1rem auto;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(5, 1fr);
  }

  .emoji-container {
    padding: 0.25rem;
    height: 5rem;
    font-size: 3.5rem;
    background-color: #f1f5ff;
    border: 1px solid #b6d8ff;
    border-radius: 9px;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 954px) {
    .emojis {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media (max-width: 769px) {
    .emojis {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (max-width: 450px) {
    .emojis {
      grid-template-columns: repeat(1, 1fr);
    }
  }
</style>
