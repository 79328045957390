<div class="panel panel-default text-center disclaimer">
  <div class="panel-body bg-info">
    <h4>Her er du helt anonym!</h4>
    <p>
      Modsat det meste andet på TeamEffect, så er besvarelsen af dette spørgsmål
      anonymt, og ingen vil derfor kunne se, hvad du har svaret. Din leder kan
      se, hvilken score der tilknytter sig til hver kommentar, men ikke hvem der
      har lavet den.
    </p>
  </div>
</div>

<style>
  .disclaimer {
    margin-top: 20px;
  }
</style>
