<script>
  import { getCookie } from 'svelte-cookie'

  console.log(getCookie('requested_url_before_login'))

  export let intented_url = ''
  let intentCookie = getCookie('requested_url_before_login')
  window.setTimeout(() => {
    if (intentCookie != null && intentCookie != '' && intentCookie != 'null') {
      window.location.href = intentCookie
    } else {
      window.location.href = 'https://app.teameffect.io'
    }
  }, 1000)
</script>

<div class="message">
  <h1>Henter...</h1>
</div>

<style>
  .message {
    display: flex;
    align-items: center;
  }
</style>
