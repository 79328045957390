<script>
  import dayjs from 'dayjs'
  import ReportMenu from '@/components/ReportsNavigation/ReportMenu.svelte'
  import AreaChartBox from '../components/AreaChartBox.svelte'
  import IncidentDistributionChart from './IncidentDistributionChart.svelte'
  import IncidentCompanyCard from './IncidentCompanyCard.svelte'
  import OrganizationSelect from '../components/OrganizationSelect.svelte'

  export let report
  export let companies

  const fmtNumber = new Intl.NumberFormat('da-DK').format
</script>

<ReportMenu displayDateSelection />

<div class="row main-content">
  <div class="col-xs-12 col-md-10-col-md-offset-1">
    <OrganizationSelect {companies} reportType="incident" />
    <h1>{report.company.name}</h1>
    <h3>
      Overordnet aktivitetsniveau for {dayjs(report.date_range.start).format(
        'll',
      )} - {dayjs(report.date_range.end).format('ll')}
    </h3>

    <p>
      I den forløbne periode er der totalt set blevet opsamlet
      <strong
        >{fmtNumber(report.incident_report.incidents.length)} hændelser</strong
      >
      hvoraf
      <strong
        >{fmtNumber(
          report.incident_report.incidents_with_high_impact.length,
        )}</strong
      > havde høj påvirkning.
    </p>
    <p>
      Ser vi på den seneste måned, blev der registreret <strong
        >{fmtNumber(report.incident_report.incidents_last_month.length)} hændelser</strong
      >
      hvoraf
      <strong
        >{fmtNumber(
          report.incident_report.incidents_last_month_with_high_impact.length,
        )}</strong
      > havde en høj påvirkning.
    </p>
    <div class="row">
      <div class="col-xs-6">
        <AreaChartBox
          title="hændelser i perioden"
          total_number={report.incident_report.incidents.length}
          data={report.incident_report.chart_data.incidents}
          bottom_data="{report.incident_report.incidents_last_month
            .length} hændelser den seneste måned"
        />
      </div>
      <div class="col-xs-6">
        <AreaChartBox
          title="hændelser med høj påvirkning i perioden"
          total_number={report.incident_report.incidents_with_high_impact
            .length}
          data={report.incident_report.chart_data.high_impact}
          bottom_data="{report.incident_report
            .incidents_last_month_with_high_impact
            .length} hændelser med høj påvirkning den seneste måned"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <h3>Fordeling af påvirkning</h3>
        <IncidentDistributionChart
          data={report.incident_report.chart_data.incident_distribution}
        />
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <h3>
          Enheder
          <small>Med hændelser i perioden</small>
        </h3>
        {#if report.incident_report.company_incident_data.length > 0}
          {#each report.incident_report.company_incident_data as company}
            <IncidentCompanyCard {company} />
          {/each}
        {/if}
      </div>
    </div>
  </div>
</div>

<style>
  .main-content {
    margin-bottom: 5rem;
  }
</style>
