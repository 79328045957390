<script>
  import ActivateMembershipRow from '../components/ActivateMembershipRow.svelte'

  export let users
</script>

<div class="tab-pane" id="blocked-employees-tab" role="tabpane">
  {#each users as user (user.id)}
    <ActivateMembershipRow {user} unblock_user={true} />
  {/each}
</div>
