<script>
  import { chart } from 'svelte-apexcharts'
  import da from 'apexcharts/dist/locales/da.json'

  export let data
  export let height = 300

  let options = {
    chart: {
      locales: [da],
      defaultLocale: 'da',
      type: 'bar',
      height: height,
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {},
    series: data,
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 5,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value.toFixed(0)
        },
      },
    },
  }
</script>

<div use:chart={options} />
