<script>
  import dayjs from 'dayjs'
  import { Link, inertia } from '@inertiajs/svelte'

  export let vacationPeriod
</script>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <h4>Er du sikker på at du vil slette fri-perioden?</h4>
    </div>
  </div>
  <br />
  <div class="thumbnail">
    <strong>Beskrivelse</strong>
    {vacationPeriod.title}
    <br />
    <strong>Periode:</strong>
    <br />
    {dayjs(vacationPeriod.start_date).format('dddd [d.] D. MMMM')} - {dayjs(
      vacationPeriod.end_date,
    ).format('dddd [d.] D. MMMM')}
    <br />
    Uge
    {dayjs(vacationPeriod.start_date).week()}
  </div>
  <div class="row">
    <div class="col-xs-6">
      <button
        use:inertia={{
          href: `/vacation_periods/${vacationPeriod.id}`,
          method: 'delete',
        }}
        class="btn btn-primary btn-block">Slet</button
      >
      <Link href="/vacation_periods" class="btn btn-default btn-block"
        >Fortryd</Link
      >
    </div>
  </div>
</div>
