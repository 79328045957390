<script>
  import { taskForm } from '@/stores/TaskFormStore.js'
  import RecurringWeeklyOptions from './RecurringWeeklyOptions.svelte'
  import RecurringTimeSelection from './RecurringTimeSelection.svelte'

  export let recurringType = ''

  if (recurringType == 'IceCube::DailyRule') {
    $taskForm.recurring = JSON.stringify({
      interval: 1,
      validations: {},
      rule_type: 'IceCube::DailyRule',
    })
  }
</script>

<div class="recurring-options-container">
  {#if recurringType == 'IceCube::WeeklyRule'}
    <RecurringWeeklyOptions />
  {:else}
    <RecurringTimeSelection />
  {/if}
</div>

<style>
  .recurring-options-container {
    margin-top: 10px;
  }
</style>
