<script>
  export let user
  export let size = 50
  let src = user.avatar.thumb.url

  $: {
    if (size > 100) {
      src = user.avatar.url
    }
  }
</script>

<img {src} class="img-circle" height={size} width={size} alt={user.name} />
