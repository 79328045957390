<script>
  import WorkdayRegistration from './components/WorkdayRegistration.svelte'

  export let workday
  export let workdayResponses
  export let canDestroy
  export let createNewResponse
  export let canShare

  let wdrProps = {
    workday,
    workdayResponses,
    canDestroy,
    createNewResponse,
    canShare,
  }
</script>

<div class="row">
  <div class="col-xs-12 col-md-8 col-md-offset-2">
    <WorkdayRegistration {...wdrProps} />
  </div>
</div>
