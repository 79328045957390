<script>
  import Modal from '@/components/Modal.svelte'
  import DefusingHurtText from './components/DefusingHurtText.svelte'
  import { Stethoscope, AlertTriangle } from 'lucide-svelte'
  import DefusingUserForm from './components/DefusingUserForm.svelte'

  export let defusingUsers = []
  export let defusing

  let showModal = false
  let modalUser = null

  const updateUser = (user) => {
    modalUser = user
    showModal = true
  }

  const btnColor = (user) => {
    if (user.is_hurt && user.is_hospital_contacted) return 'btn-danger'
    else if (user.is_hurt) return 'btn-warning'
    else return 'btn-default'
  }
</script>

<div class="row">
  <div class="col-xs-12 col-sm-8 col-sm-offset-2">
    <div class="text-center">
      <h3>Er nogen af de medvirkende kommer til skade?</h3>
      <br />
      <div class="lead">
        Vælg tilskadekommende nedenfor, og tryk gem de er registeret
      </div>
      <div class="row users-hurt-container">
        {#each defusingUsers as du (du.id)}
          <button
            on:click={() => updateUser(du)}
            class="btn user-hurt-btn btn-icon {btnColor(du)}"
          >
            {#if du.is_hospital_contacted}
              <Stethoscope strokeWidth={2.5} size={16} />
            {:else if du.is_hurt}
              <AlertTriangle strokeWidth={2.5} size={16} />
            {/if}
            {du.user_id ? du.user.name : du.name}
          </button>
        {/each}
      </div>
      <div class="row users-hurt-information">
        {#each defusingUsers as du (du.id)}
          <DefusingHurtText user={du} />
        {/each}
      </div>
      <div class="row">
        <a href="/defusings/{defusing.id}" class="btn btn-primary"
          >Gem defusing</a
        >
      </div>
    </div>
  </div>
</div>

{#if showModal && modalUser}
  <Modal bind:showModal>
    <DefusingUserForm bind:defusingUser={modalUser} bind:showModal />
  </Modal>
{/if}

<style>
  .users-hurt-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1em;
  }

  .users-hurt-information {
    margin-bottom: 2rem;
  }
</style>
