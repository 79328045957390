<script>
  import { useForm } from '@inertiajs/svelte'

  export let citizen

  let form = useForm({
    citizen: {
      name: '',
    },
  })

  function handleSubmit() {
    $form.post('/floweffect/citizens')
  }
</script>

<div class="row">
  <div class="col-xs-12 col-md-8 col-md-offset-2">
    <form on:submit|preventDefault={handleSubmit}>
      <div class="form-group">
        <label for="name" class="form-label">Navn</label>
        <input type="text" name="name" id="name" class="form-control" bind:value={$form.citizen.name} />
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-primary btn-block">Opret borger</button>
      </div>
    </form>
  </div>
</div>
