<script>
  import UserLoginForm from './components/UserLoginForm.svelte'
</script>

<div class="col-xs-12 col-md-4 col-md-offset-4">
  <h3>Log ind</h3>
  <UserLoginForm />
  <br />
  <br />
  <div class="text-muted">
    <strong>Problemer med login?</strong>
    <p>
      Kan du ikke logge ind med din email og kodeord, kan du også forsøge at
      logge ind ved at oplyse din arbejdsplads' licenskode.
    </p>
  </div>
  <br />
  <div class="text-center">
    <a href="/i" class="btn btn-default">Log ind med licenskode</a>
  </div>
</div>
