import { writable } from 'svelte/store'

function createStore() {
  const { subscribe, set, update } = writable([
    {
      showModal: false,
    },
  ])

  return {
    subscribe,
    set,
    update,
    closeModal: () => set({ showModal: false }),
    openModal: () => set({ showModal: true }),
  }
}

export const modalStore = createStore()
