<script>
  import OrganizationSelect from '../components/OrganizationSelect.svelte'
  import WorkdayCompaniesCards from '../components/WorkdayCompaniesCards.svelte'
  import WorkdayDistributionChart from '../components/WorkdayDistributionChart.svelte'
  import AreaChartBox from '../components/AreaChartBox.svelte'

  export let companies = []
  export let report
  export let companiesWithFullAccess = []

  const fmtNumber = new Intl.NumberFormat('da-DK').format

  const dailyRatingsSixMonth =
    report.workday_report.work_and_sickdays_last_six_months.length
  const dailyRatingsOneMonth =
    report.workday_report.registrations_last_month.length
  const ratingsWithCommentSixMonth =
    report.workday_report.work_and_sickdays_last_six_months_with_comments_count
  const ratingsWithCommentOneMonth =
    report.workday_report.registrations_last_month_with_comments_count
</script>

<div class="row">
  <div class="col-md-8 col-md-offset-2">
    <div class="row">
      <OrganizationSelect {companies} reportType="workday" />
      <h1>Overordnet aktivitetsniveau seneste 6 måneder</h1>

      <p>
        I de forløbne 6 måneder er der totalt set blevet opsamlet <strong
          >{fmtNumber(dailyRatingsSixMonth)}</strong
        >
        daglige bedømmelser blandt medarbejderne.
        <strong>{fmtNumber(ratingsWithCommentSixMonth)}</strong> af dem indeholder
        også en kommentar direkte til lederen.
      </p>
      <p>
        Ser vi på den seneste måned, blev der bedømt <strong
          >{fmtNumber(dailyRatingsOneMonth)}</strong
        >
        arbejdsdage med <strong>{fmtNumber(ratingsWithCommentOneMonth)}</strong>
        kommentarer.
      </p>
    </div>

    <div class="row">
      <div class="col-xs-6">
        <AreaChartBox
          title="arbejdsdage bedømt seneste 6 måneder"
          total_number={dailyRatingsSixMonth}
          data={report.workday_report
            .work_and_sickdays_last_six_months_chart_data}
          bottom_data="{fmtNumber(
            dailyRatingsOneMonth,
          )} bedømmelser den seneste måned"
        />
      </div>
      <div class="col-xs-6">
        <AreaChartBox
          title="kommentarer knyttet til dagen seneste 6 måneder"
          total_number={ratingsWithCommentSixMonth}
          data={report.workday_report
            .work_and_sickdays_last_six_months_with_comments_chart_data}
          bottom_data="{fmtNumber(
            ratingsWithCommentOneMonth,
          )} kommentarer den seneste måned"
        />
      </div>
    </div>

    <div class="row">
      <WorkdayDistributionChart {report} />
    </div>
    <hr />
    <div class="row">
      <WorkdayCompaniesCards {report} {companiesWithFullAccess} />
    </div>
  </div>
</div>
