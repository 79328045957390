<script>
  import { useForm, page } from '@inertiajs/svelte'

  export let user = null
  export let allowRememberPassword

  const path = user != null ? '/session?id=' + user.id : '/email_login'

  const company = $page.props.auth.company
  const isTestCompany = $page.props.meta.isTestCompany

  const personalDeviceDefault =
    $page.props.meta.app_client && $page.props.meta.device_type != 'tablet'
  const passwordDefault =
    isTestCompany || company.license_expires_at != null ? 'abcd1234' : ''

  let form = useForm({
    session: {
      email: user && user.email ? user.email : '',
      password: passwordDefault,
      personal_device: personalDeviceDefault,
    },
  })

  $: console.log(form)

  function handleSubmit() {
    $form.post(path)
  }
</script>

<div id="login-form">
  {#if user && user.login_attempt == 3}
    <div class="alert alert-warning">
      <strong>Obs!</strong>
      Du har nu brugt 2 ud af 3 loginforsøg. Indtaster du forkert kode igen, vil
      din bruger blive midlertidigt blokeret. Kontakt en superbruger, hvis du har
      behov for en ny kode.
    </div>
  {/if}

  <form on:submit|preventDefault={handleSubmit}>
    {#if !user}
      <div class="form-group {$form.errors.email ? 'has-error' : ''}">
        <label for="email">E-mail</label>
        <input
          type="email"
          class="form-control input-lg text-center"
          id="email"
          bind:value={$form.session.email}
          required
        />
        {#if $form.errors.email}
          <p class="help-block">{$form.errors.email}</p>
        {/if}
      </div>
    {/if}
    <div class="form-group {$form.errors.password ? 'has-error' : ''}">
      <label for="password" class="control-label">Kodeord</label>
      <input
        type="password"
        class="form-control input-lg text-center"
        id="password"
        bind:value={$form.session.password}
        required
      />
      {#if $form.errors.password}
        <p class="help-block">{$form.errors.password}</p>
      {/if}
    </div>
    {#if allowRememberPassword}
      <div class="form-group">
        <div class="checkbox">
          <label>
            <input
              type="checkbox"
              bind:checked={$form.session.personal_device}
            />
            Husk mit kodeord
          </label>
        </div>
      </div>
    {/if}
    <div class="form-group">
      <button type="submit" class="btn btn-default btn-lg btn-block">
        Log ind
      </button>
    </div>
  </form>
</div>
