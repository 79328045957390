<script>
  import axios from 'axios'
  import { fade } from 'svelte/transition'
  import { createEventDispatcher } from 'svelte'
  import { page } from '@inertiajs/svelte'
  import WorkdayColorBox from './WorkdayColorBox.svelte'

  export let wdr
  export let response_types
  export let modal = false
  export let stay = false
  let visible = true
  let response_text = ''

  let dispatcher = createEventDispatcher()

  const handleResponseSubmit = (type, response_tag) => {
    let button = document.getElementById(`button-${response_tag}`)
    const { tag, icon, text } = {
      tag: button.dataset.tag,
      icon: button.dataset.icon,
      text: button.dataset.text,
    }

    button.innerHTML = `
      <div class="spinner">
        <img src="/assets/loading-small.gif" alt="Loading..." />
      </div>
    `
    let newWdr = {
      wdr_response: {
        workday_registration_id: wdr.id,
        response_type: type,
        response: response_text,
        acknowledgement_type:
          type == 'acknowledgement' ? response_tag : 'thanks_for_sharing',
      },
    }

    dispatcher('response', {
      id: wdr.id + Math.random() * 1000, // Random temp id to make unique key for
      ...newWdr.wdr_response,
      created_at: new Date(),
      user: $page.props.auth.user,
    })

    axios
      .post(`/workday_registrations/${wdr.id}/wdr_responses/`, newWdr)
      .then(() => {
        visible = stay
        if (stay) {
          if (tag && icon && text) {
            button.disabled = true
            button.innerHTML = `
            <div class="acknowledgement glyphicon glyphicon-${icon} ${tag}_glyph"></div>
            ${text}
          `
          } else {
            button.innerHTML = 'Sendt'
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
</script>

{#if visible}
  <div class="card" transition:fade={{ duration: 500 }}>
    <div class="card-body">
      {#if !modal}
        <div class="workday-info">
          <div style="display: flex; item-">
            <img
              src=".{wdr.user.avatar.thumb.url}"
              alt={wdr.user.name}
              class="img-circle avatar-image"
            />
            <div
              style="display: flex; align-items: flex-end; margin-left: -25px"
            >
              <WorkdayColorBox {wdr} height="20px" width="20px" />
            </div>
          </div>
          <div style="display: flex; flex-direction: column;">
            <strong>{wdr.user.name}</strong>
            <small class="text-muted">{wdr.day}</small>
            {#if wdr.comment}
              <div>{wdr.comment}</div>
            {:else if wdr.positive_comment}
              <div>{wdr.positive_comment}</div>
            {:else if wdr.negative_comment}
              <div>{wdr.negative_comment}</div>
            {/if}
          </div>
        </div>
      {/if}
      <div style="margin-bottom: 2rem">
        <h4>Kvittér for kommentaren</h4>
        <div class="text-center">
          {#each response_types as rt}
            <button
              class="btn btn-sm btn-default"
              id="button-{rt.tag}"
              data-tag={rt.tag}
              data-icon={rt.icon}
              data-text={rt.text}
              on:click={() => handleResponseSubmit('acknowledgement', rt.tag)}
            >
              <div
                class="acknowledgement glyphicon glyphicon-{rt.icon} {rt.tag}_glyph"
              />
              {rt.text}
            </button>
          {/each}
        </div>
      </div>
      <form
        on:submit|preventDefault={() =>
          handleResponseSubmit('message', 'message')}
      >
        <h4>eller skriv en tilbagemelding</h4>
        <textarea
          class="form-control"
          rows="2"
          placeholder="Hej {wdr.user.name} ..."
          style="margin-bottom: 2rem"
          bind:value={response_text}
        />
        <button
          type="submit"
          class="btn btn-primary btn-block"
          id="button-message">Send</button
        >
      </form>
    </div>
  </div>
{/if}

<style>
  .card {
    margin-bottom: 2rem;
  }

  .workday-info {
    display: flex;
    justify-content: left;
    margin-bottom: 30px;
  }

  .avatar-image {
    width: 50px;
    height: 50px;
    margin-right: 1rem;
  }
</style>
