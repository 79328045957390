<script>
  import { scoreTitle } from '@/utils/wdr_helper'

  export let wdr
</script>

{#if wdr.comment}
  <p>
    <span class="score-text score-{wdr.score}"> </span>{wdr.comment}
  </p>
{:else}
  {#if wdr.positive_comment}
    <div class="glyphicon glyphicon-plus-sign text-muted" />
    <p>{wdr.positive_comment}</p>
  {/if}
  {#if wdr.negative_comment}
    <br />
    <div class="glyphicon glyphicon-minus-sign text-muted" />
    <p>{wdr.negative_comment}</p>
  {/if}
{/if}
{#if wdr.emotion}
  <br />
  <small class="text-muted">
    {wdr.user.name} følte sig {wdr.emotion}
  </small>
{/if}
