<script>
  import { eventForm } from '@/stores/EventFormStore'
  import ImpactScoreCircles from '../components/ImpactScoreCircles.svelte'

  export let triggerNextSlide

  function impactText(score) {
    if (score == 0) return 'Ikke påvirket'
    if (score == 1) return 'Lidt'
    if (score == 5) return 'Meget'
    return ''
  }

  function setImpactScore(score) {
    $eventForm.impact_score = score
    triggerNextSlide(1)
  }
</script>

<div class="row" style="margin-top: 6rem">
  <div class="col-xs-12 col-md-6 col-md-offset-3" style="text-align: center">
    <h2>Hvor påvirket er du af hændelsen?</h2>
    <div class="btn-group-lg btn-group-vertical">
      {#each { length: 6 } as _, score}
        <button
          class="btn btn-default impact-score-btn {score ==
          $eventForm.impact_score
            ? 'active'
            : ''}"
          id={score}
          on:click|preventDefault={() => {
            setImpactScore(score)
          }}
        >
          <div style="text-align: left">{impactText(score)}</div>
          <div class="event-impact-circles">
            <ImpactScoreCircles {score} />
          </div>
          <div />
        </button>
      {/each}
    </div>
  </div>
</div>

<style>
  .impact-score-btn {
    display: flex;
  }

  .impact-score-btn > div {
    flex: 1;
  }

  .event-impact-circles {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  .btn-group-lg {
    width: 100%;
  }
</style>
