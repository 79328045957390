<script>
  import dayjs from 'dayjs'

  export let date
</script>

<div
  class="progress-bar workday-type-day_off wdr-progress-block"
  data-toggle="tooltip"
  data-placement="top"
  title="{dayjs(date).format('dddd [d.] D MMMM')}: Ferie"
/>

<style>
  .wdr-progress-block {
    width: 100%;
  }
</style>
