<script>
  export let allowNone = false
  export let collection
  export let valueDescriptor = 'id'
  export let optionDescriptor = 'name'
  export let label = ''
  export let value
</script>

<div class="form-group">
  <label for="select">{label}</label>
  <select id="select" class="form-control selectpicker" bind:value>
    {#each collection as entry (entry[valueDescriptor])}
      <option
        value={entry[valueDescriptor]}
        selected={value === entry[valueDescriptor]}
        >{entry[optionDescriptor]}</option
      >
    {/each}
    {#if allowNone}
      <option disabled>──────────</option>
      <option value={null} selected={value === null}>None</option>
    {/if}
  </select>
</div>
