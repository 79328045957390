<script>
  import { workdayRegistrationForm } from '@/stores/WorkdayRegistrationFormStore'
  import AnswerSelection from './Components/AnswerSelection.svelte'
</script>

<div class="container">
  <div class="row" style="margin-top: 6rem">
    <div class="col-xs-12 col-md-8 col-md-offset-2">
      <AnswerSelection
        selections={$workdayRegistrationForm.form_data.negative_selections}
      />
      <label for="negativeComment">Andet?</label>
      <input
        type="text"
        name="negativeComment"
        placeholder=""
        class="form-control"
        bind:value={$workdayRegistrationForm.negative_comment}
      />
    </div>
  </div>
</div>
