<script>
  import { defusingsForm } from '@/stores/DefusingFormStore'
  import { onDestroy } from 'svelte'

  let users = []

  if (!$defusingsForm.defusing_users_attributes) {
    users = [
      {
        name: '',
      },
    ]
  } else {
    users = $defusingsForm.defusing_users_attributes
  }

  const addField = () => {
    users = [...users, { name: '' }]
  }

  const removeField = (index) => {
    users = users.filter((_, i) => i !== index)
  }

  onDestroy(() => {
    $defusingsForm.defusing_users_attributes = users
  })
</script>

<h2 class="text-center">Er der andre deltagere i denne defusing?</h2>

<br />
<br />

<div class="container">
  {#each users as v, i}
    <div class="input-group">
      <input
        type="text"
        id={i}
        class="form-control"
        bind:value={users[i].name}
        placeholder="Jens Jensen"
        aria-label="Deltagers Navn"
      />
      {#if users.length >= 2}
        <span
          class="input-group-addon btn btn-danger"
          id="basic-addon2"
          on:click={() => removeField(i)}>Fjern</span
        >
      {/if}
    </div>
  {/each}
  <div class="form-group">
    <button on:click|preventDefault={addField} class="btn btn-default"
      >Tilføj deltager</button
    >
  </div>
</div>

<style>
  .container {
    width: 60%;
    margin: auto;
  }

  .input-group {
    width: 100%;
  }
</style>
