<script>
  import CompanyLoginForm from './components/CompanyLoginForm.svelte'
</script>

<div class="row">
  <div class="col-xs-12 col-md-8 col-md-offset-2">
    <div id="change-company-form">
      <CompanyLoginForm />
    </div>
  </div>
</div>
