<script>
  import { workdayRegistrationForm } from '../../../stores/WorkdayRegistrationFormStore'

  export let triggerNextSlide

  function setShiftType(shift_type) {
    $workdayRegistrationForm.shift_type = shift_type
    triggerNextSlide(+1)
  }
</script>

<div class="row" style="margin-top: 6rem">
  <div class="col-xs-12 col-md-4 col-md-offset-4">
    <h2 class="text-center">Hvilken vagt havde du?</h2>
    <div class="buttons-container">
      <div>
        <input
          type="radio"
          name="shift_type"
          id="day"
          value="day"
          on:change={() => {
            setShiftType('day')
          }}
        />
        <label for="day" class="btn btn-primary btn-block btn-lg"> Dag </label>
      </div>
      <div>
        <input
          type="radio"
          name="shift_type"
          id="evening"
          value="evening"
          on:change={() => {
            setShiftType('evening')
          }}
        />
        <label for="evening" class="btn btn-primary btn-block btn-lg">
          Aften
        </label>
      </div>
      <div>
        <input
          type="radio"
          name="shift_type"
          id="night"
          value="night"
          on:change={() => {
            setShiftType('night')
          }}
        />
        <label for="night" class="btn btn-primary btn-block btn-lg">
          Nat
        </label>
      </div>
    </div>
  </div>
</div>

<style>
  .buttons-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  input[type='radio'] {
    display: none;
  }
</style>
