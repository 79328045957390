<script>
  import ReactionBox from './ReactionBox.svelte'
  import axios from 'axios'
  import ReactionModal from './ReactionModal.svelte'
  import PostLink from './PostLink.svelte'

  export let post
  export let showPost = false

  $: showModal = false

  $: reactions = post.reactions

  $: groupedReactions = reactions.reduce((acc, reaction) => {
    const emoji = reaction.emoji
    if (!acc[emoji]) {
      acc[emoji] = [reaction]
    } else {
      acc[emoji].push(reaction)
    }
    return acc
  }, {})

  const addReaction = (e) => {
    const emoji = e.detail.emoji
    axios
      .post(`/posts/${post.id}/reactions`, { emoji: emoji })
      .then((response) => {
        return response.data
      })
      .then((data) => {
        console.log(data)
        reactions = [...reactions, data]
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const deleteReaction = (e) => {
    const reaction = e.detail.reaction
    axios
      .delete(`/posts/${post.id}/reactions/${reaction.id}`, { id: reaction.id })
      .then(() => {
        reactions = reactions.filter((r) => r.id !== reaction.id)
      })
      .catch((error) => {
        console.log(error)
      })
  }
</script>

<div class="reactions">
  <div class="reaction-row">
    {#each Object.entries(groupedReactions) as [emoji, reactions]}
      <ReactionBox
        {emoji}
        {reactions}
        on:addSameReaction={(e) => addReaction(e)}
        on:deleteReaction={(e) => deleteReaction(e)}
      />
    {/each}
    <ReactionBox newReaction on:addNewReaction={() => (showModal = true)} />
  </div>
  {#if reactions.length > 0 && !showPost}
    <PostLink {reactions} {post} />
  {/if}
</div>

{#if showModal}
  <ReactionModal bind:showModal on:emojiSelcted={(e) => addReaction(e)} />
{/if}

<style>
  .reactions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .reaction-row {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    flex-direction: row;
    align-items: center;
  }
</style>
