import { writable } from 'svelte/store'

const defaultTaskState = {
  title: '',
  description: '',
  showModal: false,
}

function createStore() {
  const { subscribe, set, update } = writable({ ...defaultTaskState })

  return {
    subscribe,
    set,
    update,
    reset: () => {
      set(defaultTaskState)
    },
  }
}

export const objectiveFormStore = createStore()
