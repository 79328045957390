<script>
  import { Link } from '@inertiajs/svelte'
  import UserAvatar from '@/components/UserAvatar.svelte'
  import UserLoginForm from './components/UserLoginForm.svelte'

  export let user
  export let allowRememberPassword
</script>

<div class="row">
  <div class="col-md-6 col-md-offset-3 flex">
    <UserAvatar {user} />
    <h2>{user.name}</h2>
  </div>
</div>
<br />
<div class="row">
  <div class="col-xs-12 col-md-6 col-md-offset-3">
    <UserLoginForm {user} {allowRememberPassword} />
    <br />
    <br />
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-md-6 col-md-offset-3 text-muted text-center">
    <a href="/request_reset/{user.id}" class="btn">Glemt kodeord?</a>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 text-center">
    <br />
    <br />
    <Link href="/session/new" class="btn btn-default btn-lg">
      <div class="glyphicon glyphicon-arrow-left" />
      Tilbage til personaleoversigten
    </Link>
  </div>
</div>

<style>
  h2 {
    margin: 0;
  }

  .flex {
    display: flex;
    align-items: center;
    gap: 1.2rem;
  }
</style>
