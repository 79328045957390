<script>
  import dayjs from 'dayjs'
  import { formatForChartV2, formatForChart } from '@/utils/helpers.js'

  import ReportMenu from '@/components/ReportsNavigation/ReportMenu.svelte'
  import LineChart from '../components/LineChart.svelte'
  import ColumnChart from '@/pages/IncidentReports/charts/ColumnChart.svelte'
  import ImpactScoreCircles from '@/pages/Events/components/ImpactScoreCircles.svelte'
  import { Icon, InformationCircle } from 'svelte-hero-icons'

  export let company
  export let dateRange
  export let incidents
  export let incidentsWithHightImpactCount
  export let incidentsByCategory
  export let incidentsByDay
  export let incidentsWithHighImpactChartData
  export let incidentsAvgImpactByCategory
</script>

<ReportMenu displayDateSelection />

<div class="row main-content">
  <div class="col-xs-12 col-md-8 col-md-offset-2">
    <div class="row">
      <h1>
        Hændelsesrapport for {company.name}
      </h1>
      <h3>
        For perioden {dayjs(dateRange.start).format('ll')} - {dayjs(
          dateRange.end,
        ).format('ll')}
      </h3>
      <p>
        I den seneste måned er der registreret <strong
          >{incidents.length}</strong
        >
        hændelser, hvoraf <strong>{incidentsWithHightImpactCount}</strong> havde
        en påvirkningsgrad på 4 eller derover.
      </p>
    </div>
    <div class="row">
      <h4>Hændelser i perioden</h4>
      <LineChart data={formatForChart(incidentsByDay)} />
    </div>
    <div class="row">
      <h4>Hændelser med høj påvirkningsgrad</h4>
      <div class="icon-text">
        <Icon src={InformationCircle} size="18" />
        <small> Kun måneder der har hændelser registeret vises </small>
      </div>
      <ColumnChart data={formatForChartV2(incidentsWithHighImpactChartData)} />
    </div>
    <div class="row">
      <h4>Kategorier af hændelser i perioden</h4>
      {#if Object.keys(incidentsByCategory).length > 0}
        {#each Object.entries(incidentsByCategory) as [category, incidents]}
          <div class="row">
            <div class="col-xs-6 col-sm-4">
              {category}
            </div>
            <div class="col-xs-6 col-sm-4 text-right">
              {incidents.length} hændelser
              <br />
              <div class="text-sm text-danger">2 med høj påvirkning</div>
            </div>
            <div class="col-xs-12 col-sm-4 text-right">
              <ImpactScoreCircles
                score={incidentsAvgImpactByCategory[
                  incidents[0].event_category_id
                ]}
                with_text
              />
            </div>
          </div>
          <hr />
        {/each}
      {/if}
    </div>
  </div>
</div>

<style>
  .main-content {
    margin-bottom: 5rem;
  }

  .icon-text {
    display: flex;
    align-items: center;
    gap: 0.7rem;
  }
</style>
