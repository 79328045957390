<script>
  export let post

  const postImage = post.image.url
</script>

<div class="post-content">
  <p>{post.text}</p>
  {#if postImage}
    <a href="posts/{post.id}/show_image">
      <img class="img-thumbnail" src={post.image.url} alt={post.text} />
    </a>
  {/if}
</div>

<style>
  /* your styles go here */
</style>
