<script>
  export let wdr;
  export let withIcons = true;
</script>

{#if wdr.comment}
  {wdr.comment}
{:else}
  {#each wdr.feelings as f}
    {#if withIcons}
      <div class="glyphicon glyphicon-plus-sign text-muted" />
    {/if}
    {f}
    <br />
  {/each}
  {#if wdr.positive_comment}
    {#if withIcons}
      <div class="glyphicon glyphicon-plus-sign text-muted" />
    {/if}
    {wdr.positive_comment}
    <br />
  {/if}

  {#if wdr.negative_comment}
    {#if withIcons}
      <div class="glyphicon glyphicon-minus-sign text-muted" />
    {/if}
    {wdr.negative_comment}
    <br />
  {/if}
{/if}
