<script>
  // Slides
  import MultiStepForm from '@/components/MultiStepForm.svelte'
  import CategorySelection from './Slides/CategorySelection.svelte'
  import DescriptionSlide from './Slides/DescriptionSlide.svelte'
  import ImpactSelection from './Slides/ImpactSelection.svelte'
  import ReferenceSelection from './Slides/ReferenceSelection.svelte'

  import { eventForm } from '@/stores/EventFormStore'

  // Props
  export let categories = []
  export let incidents_today = []
  export let references = []
  export let references_enabled = false

  let steps = [
    { slide: CategorySelection, showActions: false },
    { slide: ImpactSelection, showActions: true, showNext: false },
    { slide: DescriptionSlide, showActions: true, showNext: true },
  ]

  if (references_enabled)
    steps.push({ slide: ReferenceSelection, showActions: true, showNext: true })

  // Setting default data for the form
  $eventForm.form_data.categories = categories
  $eventForm.form_data.incidents_today = incidents_today
  $eventForm.form_data.references = references

  $: formData = { incident: $eventForm }
</script>

<MultiStepForm {steps} submitUrl="/events" {formData} />
