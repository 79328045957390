<script>
  import UserAvatar from '@/components/UserAvatar.svelte'
  import dayjs from 'dayjs'

  export let reaction
</script>

<div class="media">
  <div class="media-left">
    <UserAvatar user={reaction.user} size="35" />
    <span class="pull-right avatar-emoji">{reaction.emoji}</span>
  </div>
  <div class="media-body" style="padding-left: 5px">
    {reaction.user.name}
    <br />
    <small class="text-muted">
      {dayjs().to(dayjs(reaction.created_at))}
    </small>
  </div>
  <div class="media-right" style="padding-top: 5px">
    <a
      href="/appreciations/new?recipient_id={reaction.user.id}"
      class="btn btn-default btn-sm">Send besked</a
    >
  </div>
</div>

<style>
  .avatar-emoji {
    position: relative;
    margin-top: -18px;
    margin-right: -5px;
    font-size: 22px;
  }
</style>
