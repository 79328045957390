import dayjs from 'dayjs'
import { writable } from 'svelte/store'

export const storedCompanies = writable([])
export const storedSelectedCompany = writable('')
export const storedTeams = writable([])
export const storedSelectedTeam = writable('')

export const startDate = writable(
  dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
)
export const endDate = writable(dayjs().format('YYYY-MM-DD'))
