<script>
  import { useForm, page } from '@inertiajs/svelte'
  import dayjs from 'dayjs'

  export let accountTransaction
  export let accountTransactions
  export let account
  export let citizen

  const current_user = $page.props.auth.user

  let form = useForm({
    transaction_type: 'revise',
    account_transaction: {
      account_id: account.id,
      registered_by: current_user.name,
      name: accountTransaction.name,
      datetime: dayjs(accountTransaction.datetime).format('YYYY-MM-DDTHH:mm'),
      amount: undefined,
    },
  })

  let latestTransaction = accountTransactions.filter((t) => {
    return dayjs($form.account_transaction.datetime).isAfter(t.datetime)
  })

  $: balance = latestTransaction[0]?.current_balance_cents / 100 || 0

  $: balanceDiff = $form.account_transaction.amount
    ? $form.account_transaction.amount - balance
    : 0
  $: diffClass = balanceDiff === 0 ? 'text-success' : 'text-danger'

  const reviseAccount = () => {
    $form.post(
      `/citizens/${citizen.id}/accounts/${account.id}/account_transactions`,
    )
  }
</script>

<div class="row">
  <div class="col-xs-12 col-md-6 col-md-offset-3">
    <h3>Afstem konto</h3>
    <form on:submit|preventDefault={reviseAccount}>
      <div class="form-group">
        <label for="registered_by">Udført af</label>
        <input
          type="text"
          class="form-control"
          id="registered_by"
          placeholder="Registreret af"
          bind:value={$form.account_transaction.registered_by}
          readonly
        />
      </div>
      <div class="form-group">
        <label for="name">Beskrivelse</label>
        <input
          type="text"
          class="form-control"
          id="name"
          placeholder="Beskrivelse"
          bind:value={$form.account_transaction.name}
          maxlength="255"
        />
      </div>
      <div class="form-group">
        <label for="datetime">Dato</label>
        <input
          type="datetime-local"
          class="form-control"
          id="datetime"
          placeholder="Dato"
          max={dayjs().format('YYYY-MM-DDTHH:mm')}
          bind:value={$form.account_transaction.datetime}
        />
      </div>
      <div class="form-group">
        <label for="amount">Saldo</label>
        <input
          type="number"
          class="form-control"
          id="amount"
          step="any"
          placeholder="f.eks. {(account.balance.cents / 100).toLocaleString(
            'da-DK',
            { style: 'currency', currency: account.balance.currency_iso },
          )}"
          bind:value={$form.account_transaction.amount}
        />
      </div>

      {#if account.account_type == 'physical'}
        <div class="current-balance">
          <h4>
            Regnskabssaldo: {balance.toLocaleString('da-DK', {
              style: 'currency',
              currency: account.balance.currency_iso,
            })}
          </h4>
        </div>
        <div class="balance-diff">
          <h4>
            Difference: <span class={diffClass}
              >{balanceDiff.toLocaleString('da-DK', {
                style: 'currency',
                currency: account.balance.currency_iso,
              })}</span
            >
          </h4>
        </div>
      {/if}
      <button type="submit" class="btn btn-primary">Afstem</button>
      {#if account.account_type == 'physical'}
        <br /><br />
        OBS: Hvis der er difference, vil saldoen blive ændret til det indtastede
        beløb og der vil blive oprettet en transaktion, hvor differencen vises.
      {/if}
    </form>
  </div>
</div>
