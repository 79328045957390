<script>
  import { storedSelectedCompany, storedSelectedTeam } from './store.js'

  export let units
  export let type

  const isCompany = type === 'company'
  const selectName = isCompany ? 'company_id' : 'team_id'
  const searchParams = new URLSearchParams(window.location.search)

  let selected

  if ($storedSelectedCompany) {
    selected = $storedSelectedCompany
  }

  function handleChange(e) {
    if (e.target.value == 0) {
      searchParams.delete('company_id')
    } else if (isCompany) {
      $storedSelectedCompany = e.target.value
      searchParams.set('company_id', $storedSelectedCompany)
    } else {
      $storedSelectedTeam = e.target.value
    }

    window.location.search = searchParams.toString()
  }
</script>

<div class="form-group form-group-sm">
  <div class="input-group">
    <div class="input-group-addon">
      {isCompany ? 'Enheder' : 'Teams'}
    </div>
    <select
      name={selectName}
      id={selectName}
      class="form-control"
      bind:value={selected}
      on:change|preventDefault={handleChange}
    >
      <option value="0">{isCompany ? 'Alle enheder' : 'Alle teams'}</option>
      {#each units as unit}
        <option value={unit.id} selected={unit.id == $storedSelectedCompany}
          >{unit.name}</option
        >
      {/each}
    </select>
  </div>
</div>
