<script>
  import { beforeUpdate } from 'svelte'
  import { scoreForm, survey } from '@/stores/NpsScoreStore'

  import MultiStepForm from '@/components/MultiStepForm.svelte'
  import ScoreRating from './slides/ScoreRating.svelte'
  import Comment from './slides/Comment.svelte'
  import Disclaimer from './Disclaimer.svelte'

  export let npsSurvey
  export let npsScore
  export let slides

  $scoreForm = npsScore
  $survey = npsSurvey

  let steps = []

  function assignSlides() {
    slides.forEach((slide) => {
      switch (slide) {
        case 'score_rating':
          steps.push({ slide: ScoreRating, showActions: true })
          break
        case 'comment':
          steps.push({ slide: Comment, showActions: true })
          break
      }
    })
  }

  let skipUpdate = false

  beforeUpdate(() => {
    if (!skipUpdate) {
      assignSlides()
    }
    skipUpdate = true
  })

  $: formData = {
    nps_score: $scoreForm,
  }
</script>

<MultiStepForm
  {steps}
  {formData}
  submitUrl={`/nps_surveys/${npsSurvey.id}/nps_scores`}
/>

<Disclaimer />
