<script>
  import Post from './components/Post.svelte'
  import Reaction from './components/Reaction.svelte'

  export let post
  export let current_user
  export let emojis

  const handlePostDeleted = () => {
    window.location = '/news_feed'
  }
</script>

<div class="row">
  <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
    <Post
      {post}
      {current_user}
      {emojis}
      on:postDeleted={handlePostDeleted}
      showPost
    />
  </div>
</div>
<br />

<div class="row">
  <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
    <h4 class="hidden-xs">Reaktioner fra</h4>
    {#each post.reactions as reaction (reaction.id)}
      <Reaction {reaction} />
    {/each}
  </div>
</div>
