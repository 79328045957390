<script>
  import { Link } from '@inertiajs/svelte'

  export let user
</script>

<div class="row">
  <div class="col-xs-12">
    <div class="text-center">
      <span>👍</span>
      <h2>{user.name} er nu aktiveret og kan logge ind igen.</h2>
      <br />
      <br />
      <br />
      <div class="lead">
        Kan {user.name} ikke huske sit kodeord?
      </div>
      <a href="/users/{user.id}/change_password" class="btn btn-default">
        <div class="glyphicon glyphicon-pencil" />
        Lav et nyt kodeord
      </a>
      <a href="/memberships" class="btn btn-primary">
        <div class="glyphicon glyphicon-home" />
        Tilbage
      </a>
    </div>
  </div>
</div>

<style>
  span {
    font-size: 7rem;
  }
</style>
