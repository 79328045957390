<script>
  import { useForm } from '@inertiajs/svelte'
  import EventCategoryForm from './components/EventCategoryForm.svelte'

  export let eventCategory

  let form = useForm({
    event_category: {
      name: eventCategory.name,
      id: eventCategory.id,
      active_state: eventCategory.active_state,
    },
  })

  const handleSubmit = () => {
    $form.put('/event_categories/' + eventCategory.id)
  }
</script>

<svelte:head>
  <title>{eventCategory.name}</title>
</svelte:head>

<EventCategoryForm bind:form {handleSubmit} type="edit" />
