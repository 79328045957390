<script>
  import { useForm } from '@inertiajs/svelte'

  import SearchableSelect from "@/components/SearchableSelect.svelte";
  import Checkbox from "@/components/Checkbox.svelte";

  export let membership
  export let companies
  export let organizations
  export let users
  export let membershipType
  export let currentUser

  let form = useForm({
    membership: {
      company_id: membership.company_id,
      organization_id: membership.organization_id,
      membership_type: membershipType,
      owner: membership.owner,
      can_access_anonymous_data: membership.can_access_anonymous_data,
      can_access_owners_data: membership.can_access_owners_data,
      can_access_all_data: membership.can_access_all_data,
      included_in_reports: membership.included_in_reports,
      can_access_incident_reports: membership.can_access_incident_reports,
      user_id: membership.user_id,
      has_access_to_organization_reports: membership.has_access_to_organization_reports,
      has_organizational_administrative_rights: membership.has_organizational_administrative_rights,
      manage_users_and_citizens: membership.manage_users_and_citizens,
      external_person: membership.external_person,
      ignored_by_fki: membership.ignored_by_fki,
    }
  })

  const handleSubmit = (e) => {
    $form.post(`/te_admin/users/${currentUser.id}/memberships`)
  }
</script>

<h3>Create a new membership</h3>

<form on:submit|preventDefault={handleSubmit}>
  <div class="form-group">
    <label for="user">Bruger</label>
    <input class="form-control" name="user" type="text" value={currentUser.name} readonly />
  </div>
  {#if membershipType == 'company'}
    <div class="form-group">
      <SearchableSelect
        bind:value={$form.membership.company_id}
        label="Vælg Company"
        collection={companies}
        valueKey="id"
        labelKey="name"
        on:selected = {e => $form.membership.company_id = e.detail.id}
      />
    </div>
    <Checkbox label="Owner" bind:value={$form.membership.owner} />
    <Checkbox label="Kan se anonyme data" bind:value={$form.membership.can_access_anonymous_data} />
    <Checkbox label="Kan se leders bedømmelser" bind:value={$form.membership.can_access_owners_data} />
    <Checkbox label="Kan se alle brugeres bedømmelser" bind:value={$form.membership.can_access_all_data} />
    <Checkbox label="Inkluderet i rapporter" bind:value={$form.membership.included_in_reports} />
    <Checkbox label="Adgang til hændelsesrapporter" hint="Typisk har leder og AMR adgang til at se hændelsesrapporter" bind:value={$form.membership.can_access_incident_reports} />
  {:else}
    <div class="form-group">
      <SearchableSelect
        bind:value={$form.membership.organization_id}
        label="Vælg organization"
        collection={organizations}
        valueKey="id"
        labelKey="name"
        on:selected = {e => $form.membership.organization_id = e.detail.id}
      />
    </div>
    <Checkbox label="Organisationsadministrator" hint="Typisk IT afdelingen eller HR" bind:value={$form.membership.has_organizational_administrative_rights} />
    <Checkbox label="Organisationsleder" hint="Giver adgang til at se organisationsrapporter" bind:value={$form.membership.has_access_to_organization_reports} />
  {/if}

  <button type="submit" class="btn btn-primary">Create Membership</button>
</form>
