<script>
  import UserAvatar from '@/components/UserAvatar.svelte'

  export let admins
</script>

<div class="row">
  <div class="col-xs-12 col-md-6 col-md-offset-3">
    <h1>Ups, din bruger er blokeret</h1>
    <br />

    <div class="lead">
      <p>Din bruger er blevet blokeret grundet tre mislykkede loginforsøg</p>
      <p>
        For at få din bruger åbnet igen kan du kontakte en af følgende kollegaer
        fra din arbejdsplads:
      </p>
    </div>
    <div class="grid">
      {#each admins as admin}
        <div class="grid_item">
          <UserAvatar user={admin} size="100" />
          <p class="text-center">{admin.name}</p>
        </div>
      {/each}
    </div>
  </div>
</div>

<style>
  .grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    column-gap: 20px;
    row-gap: 20px;
    justify-items: center;
  }
</style>
