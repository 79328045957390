<script>
  import { chart } from 'svelte-apexcharts'

  export let data

  let options = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    series: [
      {
        name: 'Hændelser',
        data: data,
      },
    ],
    stroke: {
      show: true,
      curve: 'monotoneCubic',
      lineCap: 'round',
      width: 2,
    },
    markers: {
      size: 5,
      hover: {
        size: 6,
      },
    },
    xaxis: {
      type: 'datetime',
    },
  }
</script>

<div use:chart={options} />
