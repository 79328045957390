<script>
  import dayjs from 'dayjs'
  import axios from 'axios'
  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()

  export let date
  export let user
  export let show = false
  export let offset
  export let index

  let selfWidth = 0

  $: popoverClass = show ? 'popover_show' : 'popover_hidden'

  const handleSubmit = (e) => {
    let workday_type = e.submitter.value
    let url = `/workday_registrations/create_absense_day/${user.id}/${date}/${workday_type}`

    axios.get(url).then((response) => {
      if (response.status == 200) {
        dispatch('update_wdr', { user, date, workday_type })
        show = false
      }
    })
  }
</script>

<div
  bind:clientWidth={selfWidth}
  class="popover {popoverClass}"
  role="tooltip"
  style="left: {index * offset - selfWidth / 2}px;"
>
  <h3 class="popover-title">
    {dayjs(date).format('dddd [d.] D MMMM')}
  </h3>
  <div class="popover-content text-center">
    <form on:submit|preventDefault={handleSubmit}>
      <div class="form-group">
        <button type="submit" value="day_off" class="btn btn-primary btn-block"
          >Fridag</button
        >
      </div>
      <div class="form-group">
        <button
          type="submit"
          value="sickness_absense"
          class="btn btn-primary btn-block">Sygedag</button
        >
      </div>
      <div class="form-group">
        <button
          on:click={() => (show = !show)}
          class="btn btn-default btn-block"
        >
          Fortryd
        </button>
      </div>
    </form>
  </div>
</div>

<style>
  .popover-title:first-letter {
    text-transform: capitalize;
  }
  .popover_show {
    display: block;
    position: absolute;
    top: 20px;
  }
</style>
