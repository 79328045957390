import dayjs from 'dayjs'

export function taskTemplate(task, useLabel = true) {
  let label = useLabel ? task.label : task.name
  let start_time = dayjs(task.start_time).format('HH:mm')
  let end_time = dayjs(task.end_time).format('HH:mm')
  let isRecurring = task.recurring != null
  let recurringSymbol = isRecurring
    ? `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" height="10px" width="10px" style="margin-top: -20px">
  <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
</svg>

`
    : ''
  let citizenInfo = task.citizen
    ? `
          <img src="${task.citizen.avatar.thumb.url}" height="20px" width="20px" class="img-circle" />
          <div class="subject-name">${task.citizen.name}</div>
        `
    : ''

  return `
    <div class="task-card-container">
      <div class="task-card-subject">
        ${citizenInfo} 
        ${recurringSymbol}
      </div>
      <small class="text-muted">${start_time} - ${end_time}</small>
      <div class="task-card-content">${label}</div>
    </div>
  `
}
