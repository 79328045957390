<script>
  export let form;
  export let incidents;

  const addIncidentPoint = () => {
    form.incident_plan.incident_plan_points = [
      ...form.incident_plan.incident_plan_points,
      {
        text: "",
      },
    ];
  };

  const removePoint = (index) => {
    form.incident_plan.incident_plan_points =
      form.incident_plan.incident_plan_points.filter((_, i) => i !== index);
  };

  form.incident_plan.event_ids = incidents.map((incident) => incident.id);
</script>

<form class="incident-plan-form">
  <div class="inputs">
    {#if form.errors.length > 0}
      <div class="alert alert-warning text-left">
        <strong class="alert-heading">Følgende fejl forhindrede handleplanen i at blive oprettet:</strong>
        <hr>
        <ol>
          {#each Object.values(form.errors) as error}
            <li>{error}</li>
          {/each}
        </ol>
      </div>
    {/if}
    <div class="form-group">
      <label for="comment">Kommentar</label>
      <textarea
        id="comment"
        name="comment"
        class="form-control"
        rows="3"
        bind:value={form.incident_plan.description}
      />
    </div>
    <div class="plan-points">
      <strong>Handlepunkter:</strong>
      {#each form.incident_plan.incident_plan_points as point, index}
        <div class="form-group row-form">
          <input type="text" class="form-control" bind:value={point.text} />
          {#if form.incident_plan.incident_plan_points.length > 1}
            <button
              class="btn btn-danger"
              on:click|preventDefault={() => removePoint(index)}>Fjern</button
            >
          {/if}
        </div>
      {/each}
      <button class="btn btn-primary" on:click|preventDefault={addIncidentPoint}
        >Tilføj Punkt</button
      >
    </div>
  </div>
</form>

<style>
  textarea {
    resize: vertical;
  }

  .incident-plan-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
  }

  .row-form {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }
</style>
