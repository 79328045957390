<script>
  import UserAvatar from '@/components/UserAvatar.svelte'
  import { Icon, User, Users } from 'svelte-hero-icons'

  export let report
  export let companiesWithFullAccess
</script>

<h2>{report.company.name} enheder</h2>
{#each Object.entries(report.companies_by_organization) as [organization, companies]}
  {#if organization}
    <h4>{organization}</h4>
  {/if}
  {#each companies as company}
    {@const leader = company.active_owner_users[0]}
    {#if leader}
      {@const companyReportLink = `/organization_reports/company_report?company_id=${company.id}&leader_id=${leader.id}&start_date=${report.date_range.start}&end_date=${report.date_range.end}`}
      <a href={companyReportLink}>
        <div class="btn btn-default btn-block" style="margin-bottom: 10px;">
          <div class="flex">
            <div class="flex">
              <div class="leader-avatar">
                <UserAvatar user={leader} size="50" />
              </div>
              <div class="leader-name">
                {leader.name}
                <div class="leaderpanel-company text-muted">
                  {company.name}
                </div>
              </div>
            </div>
          </div>
          <div class="flex text-left">
            {#if companiesWithFullAccess.filter((c) => c.id == company.id).length > 0}
              <div>
                <h4>Trivselsscore</h4>
                <Icon src={User} solid size="18" />
                Leder:
                <strong
                  >{report.workday_report.companies_avg_wdr_scores_hash[
                    company.name
                  ].leader_avg_score}</strong
                >
                <span class="text-muted">ud af 5</span>
                <br />
                <Icon src={Users} solid size="18" />
                Medarbejdere:
                <strong
                  >{report.workday_report.companies_avg_wdr_scores_hash[
                    company.name
                  ].employee_avg_score}</strong
                >
                <span class="text-muted">ud af 5</span>
              </div>
            {/if}
          </div>
        </div>
      </a>
    {/if}
  {/each}
{/each}

<style>
  .leader-name {
    font-size: 22px;
  }
</style>
