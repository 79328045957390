<script>
  import { chart } from 'svelte-apexcharts'
  import dayjs from 'dayjs'

  export let total_number
  export let title = ''
  export let range = ''
  export let bottom_data
  export let data
  export let height = 250
  export let minimal = false
  export let showFullDate = false

  let options = {
    chart: {
      type: 'area',
      height: height,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    series: [
      {
        name: title,
        data: data.values,
      },
    ],
    xaxis: {
      categories: data.categories,
      tickAmount: 28,
      labels: {
        show: true,
        hideOverlappingLabels: true,
        formatter: function (value) {
          if (showFullDate) {
            return dayjs(value).format('l')
          } else {
            return dayjs(value).format('MMM YYYY')
          }
        },
      },
    },
  }
</script>

{#if minimal}
  <div use:chart={options} />
{:else}
  <div class="panel panel-default text-center">
    <div class="panel-body">
      <div use:chart={options} />
      <span>{total_number}</span>
      {title}
      {range}
      <br />
      <small class="text-muted">{bottom_data}</small>
    </div>
  </div>
{/if}
