<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <h4>
        Er du sikker på at du vil tvinge alle brugere til at skifte deres
        kodeord?
      </h4>

      <br />
      <div class="text-muted">
        Hvis du trykker 'Ja' vil alle brugere af systemet blive tvunget til at
        skifte deres kodeord næste gang de bruger systemet.
        <br />
        Efter du har trykket 'Ja' vil du også selv blive bedt om at skifte kodeord.
      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-xs-6">
      <a href="/users/reset_user_passwords" class="btn btn-primary btn-block"
        >Ja</a
      >
    </div>
    <div class="col-xs-6">
      <a href="/administration" class="btn btn-default btn-block">Nej</a>
    </div>
  </div>
</div>
