<script>
  import { page } from '@inertiajs/svelte'
  import dayjs from 'dayjs'
  import PostWorkdayRegistration from './PostWorkdayRegistration.svelte'
  import PostText from './PostText.svelte'
  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()

  export let post

  const currentUser = $page.props.auth.user

  const wdr = post.workday_registration

  const deletePost = () => {
    dispatch('deletePost')
  }
</script>

<div class="feed-item-content">
  <div class="feed-item-meta">
    <div class="feed-item-meta-user">
      <strong>{post.user.name}</strong>
      <small class="text-muted">
        {#if currentUser.memberships.length > 1}
          <span>{post.company.name}</span>
        {/if}
        {#if post.workday_registration}
          <span
            >{dayjs(post.workday_registration.day).format('dddd [d.] ll')}</span
          >
        {:else}
          <span>{dayjs(post.created_at).from(dayjs())}</span>
        {/if}
      </small>
    </div>
    <div class="post-actions">
      {#if post.user_id === currentUser.id}
        <span class="dropdown">
          <button
            class="btn dropdown-toggle"
            id="optionsDropdown"
            data-toggle="dropdown"
          >
            <div class="glyphicon glyphicon-option-vertical text-muted" />
          </button>
          <ul
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="optionsDropdown"
          >
            <li>
              <a on:click={deletePost} role="button" tabindex="0">
                <div class="glyphicon glyphicon-trash" />
                Slet
              </a>
            </li>
          </ul>
        </span>
      {/if}
    </div>
  </div>
  <div class="feed-item-text">
    {#if wdr}
      <PostWorkdayRegistration {wdr} />
    {:else}
      <PostText {post} />
    {/if}
  </div>
</div>

<style>
  .feed-item-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .feed-item-meta {
    display: flex;
  }

  .feed-item-meta-user {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .feed-item-meta-user small {
    display: flex;
    flex-direction: column;
  }

  .feed-item-text {
    padding: 0;
  }
</style>
