<script>
  import UserAvatar from '@/components/UserAvatar.svelte'

  export let post
</script>

<div class="feed-item-avatar">
  <UserAvatar user={post.user} />
  {#if post.workday_registration_id}
    <span
      class="label label-default user-post-color-box pull-right score-{post
        .workday_registration.score}">&nbsp;</span
    >
  {/if}
</div>

<style>
  .feed-item-avatar {
    width: 50px;
  }
</style>
