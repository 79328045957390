<script>
  export let label = ''
  export let hint = null
  export let value
</script>

<div class="form-group">
  <div class="form-check">
    <input
      type="checkbox"
      id="checkbox"
      class="form-check-input"
      bind:checked={value}
    />
    <label for="checkbox">{label}</label>
  </div>
  {#if hint}
    <small class="form-text text-muted">{hint}</small>
  {/if}
</div>
