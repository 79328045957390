import '@/styles/shared.css'

import { createInertiaApp } from '@inertiajs/svelte'
import Layout from '@/layouts/Layout.svelte'
import axios from 'axios'
import './dayjs.config.js'

const pages = import.meta.glob('../pages/**/*.svelte', { eager: true })
const csrfToken = document.querySelector('meta[name=csrf-token]').content
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken

createInertiaApp({
  resolve: (name) => {
    const page = pages[`../pages/${name}.svelte`]
    return { default: page.default, layout: page.layout || Layout }
  },
  setup({ el, App, props }) {
    new App({ target: el, props })
  },
})
