<script>
  export let reactions = []
  export let post

  let link = `/posts/${post.id}`

  const firstUser = reactions[0].user.name
  const users = reactions.map((reaction) => reaction.user.name)
  const uniqueUsers = [...new Set(users)]
  const count = uniqueUsers.length - 1

  const linkText = `Fra ${firstUser}` + (count > 0 ? ` og ${count} andre` : '')
</script>

<a href={link}>{linkText}</a>
