<script>
  import EditMembershipRow from '../components/EditMembershipRow.svelte'

  export let memberships
  const companies = Object.keys(memberships)
</script>

<div class="tab-pane active" id="active-employees-tab" role="tabpane">
  <div class="row">
    <div class="col-xs-12">
      <div class="pull-right">
        <br />
        <a href="/users/new" class="btn btn-primary"> Invitér medarbejder </a>
      </div>
      {#each companies as company}
        <h3>{company}</h3>
        {#each memberships[company] as membership (membership.id)}
          <EditMembershipRow {membership} />
        {/each}
      {/each}
    </div>
  </div>
</div>
