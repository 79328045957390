<script>
  import { useForm, page } from '@inertiajs/svelte'
  import UserAvatar from '../../components/UserAvatar.svelte'

  export let user
  export let type = 'user'

  const device_type = $page.props.meta.device_type
  const ios_app_client = $page.props.meta.ios_app_client

  const behavior =
    (device_type == 'mobile' || device_type == 'tablet') && ios_app_client
      ? 'trigger-camera-guide'
      : 'trigger-file-field'

  let form
  let submitUrl

  if (type == 'user') {
    submitUrl = `/users/${user.id}`
    form = useForm({
      user: {
        avatar: null,
      },
    })
  } else if (type == 'citizen') {
    submitUrl = `/citizens/${user.id}`
    form = useForm({
      citizen: {
        avatar: null,
      },
    })
  }

  async function handleChange() {
    $form.patch(submitUrl)
  }

  function triggerFileField() {
    if (behavior == 'trigger-camera-guide') {
      window.webkit.messageHandlers.triggerCameraGuide.postMessage(null)
      return
    } else if (behavior == 'trigger-file-field') {
      let fileField = document.querySelector('.hidden-file-field')
      fileField.click()
    }
  }
</script>

<form on:change|preventDefault={handleChange}>
  <input
    type="file"
    on:input={(e) => ($form[type].avatar = e.target.files[0])}
    class="hidden-file-field"
  />

  <div class="text-center" data-behavior={behavior}>
    {#if !$form.processing}
      <div>
        <UserAvatar {user} size="150" />
      </div>
    {/if}
    {#if $form.processing}
      <div class="upload-spinner">
        <img src="/assets/loading-circle.gif" alt="Indlæser..." />
      </div>
    {/if}
    <br />
    <br />
    <button
      disabled={$form.processing}
      class="btn btn-default"
      on:click|preventDefault={triggerFileField}
    >
      <div class="glyphicon glyphicon-camera" />
      Skift billede
    </button>
  </div>
</form>
