<script>
  import { scoreForm } from '@/stores/NpsScoreStore'
</script>

<div class="short-question">
  <div class="form-group comment">
    <label for="comment">Tilføj evt. en kommentar til din bedømmelse</label>
    <textarea
      class="form-control"
      id="comment"
      name="comment"
      rows="5"
      data-auto-update="true"
      bind:value={$scoreForm.comment}
    ></textarea>
  </div>
</div>
