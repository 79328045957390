<script>
  import Incident from '../Events/components/Incident.svelte'
  import IncidentPlanForm from './components/IncidentPlanForm.svelte'
  import { currentPage, pageCount } from '@/stores/IncidentPlanStore'
  import dayjs from 'dayjs'
  import { Icon, CheckCircle, ChevronRight } from 'svelte-hero-icons'
  import axios from 'axios'

  export let group
  export let incidents
  export let index
  export let progressBar

  let title = `${dayjs().format('YYYY-MM-DD')} ${group}`

  $: form = {
    incident_plan: {
      title: title,
      description: '',
      incident_plan_points: [
        {
          text: '',
        },
      ],
      event_ids: [],
    },
    errors: [],
  }

  $: active = index == $currentPage

  const handleSubmit = () => {
    axios
      .post('/incident_plans', form, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then(() => {
        if ($currentPage + 1 == $pageCount) {
          window.location.replace('/incident_plans')
        } else {
          currentPage.update((n) => n + 1)
          progressBar.handleProgress(1)
        }
      })
      .catch((err) => {
        form.errors = err.response.data.errors
      })
  }
</script>

<div class={active ? 'active' : 'hidden'}>
  <div class="plan-container">
    <div class="plan-info">
      <div class="col-md-10 col-md-offset-1 text-center">
        <h1>{group}</h1>
      </div>
      <div class="col-md-10 col-md-offset-1 text-center">
        {#each incidents as incident}
          <Incident {incident} anonymous />
        {/each}
      </div>
    </div>
    <div class="plan-form col-md-10 col-md-offset-1 text-center">
      <IncidentPlanForm bind:form {group} {incidents} />
    </div>
    <div class="controls">
      {#if $currentPage + 1 == $pageCount}
        <button
          on:click|preventDefault={handleSubmit}
          class="btn btn-success btn-icon"
        >
          Afslut
          <Icon src={CheckCircle} size="18" solid />
        </button>
      {:else}
        <button
          on:click|preventDefault={handleSubmit}
          class="btn btn-primary btn-icon"
        >
          Næste
          <Icon src={ChevronRight} size="18" solid />
        </button>
      {/if}
    </div>
  </div>
</div>

<style>
  .plan-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .plan-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: space-between;
  }

  .plan-form {
    flex: 2;
  }

  .hidden {
    display: none;
  }

  .active {
    height: 100%;
    display: flex;
  }

  .controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .btn-icon {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
</style>
