import { readable, writable } from 'svelte/store'

export const defusingUsers = writable([])
export const organizationUsers = writable([])
export const impactScoreOptions = readable([0, 1, 2, 3, 4, 5])

export const defusingsForm = writable({
  company_id: null,
  description: '',
  impact_score: null,
  shift_type: '',
  role_description: '',
  time_registration_hours: 0,
  time_registration_minutes: 0,
  user_ids: [],
  defusing_users_attributes: ['name'],
})
