<script>
  import { page, Link } from '@inertiajs/svelte'
  import { truncate } from '@/utils/helpers'
  import UserAvatar from '@/components/UserAvatar.svelte'

  export let user
  export let sso_login_enabled

  const unverifiedLoginAllowed =
    $page.props.auth.company.unverified_login_allowed
  const personalDevice = $page.props.meta.personalDevice

  let path = ''

  if (unverifiedLoginAllowed && !personalDevice) {
    path = `/unverified_login_as/${user.id}`
  } else if (sso_login_enabled && user.fki_uuid) {
    path = '/saml/login'
  } else {
    path = `/verified_login_as/${user.id}`
  }
</script>

<div class="text-center">
  <a href={path} class="btn text-center">
    <UserAvatar {user} size="100" />
    <p>{truncate(user.name, 18)}</p>
  </a>
</div>

<style>
  p {
    color: black;
  }
</style>
