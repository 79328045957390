<script>
  import CompanyWorkdaySummary from './CompanyWorkdaySummary.svelte'

  export let report

  $: currentActive = 'all'

  const setActive = (company) => {
    currentActive = company
  }

  const isActive = (company) => {
    return currentActive == company ? 'active' : null
  }
</script>

<h3>Farvefordeling af arbejdsdage den seneste måned</h3>

<div class="row">
  <div class="col-xs-12">
    <ul class="nav nav-pills" role="tablist">
      <li class="nav-item active" on:click={() => setActive('all')}>
        <a class="nav-link" data-toggle="tab" href="#all">Alle Enheder</a>
      </li>
      <li class="nav-item" on:click={() => setActive(report.company.name)}>
        <a
          class="nav-link"
          data-toggle="tab"
          href="#organization-{report.company.name}">{report.company.name}</a
        >
      </li>
      {#each Object.entries(report.workday_report.registrations_last_month_by_company) as [company, _]}
        {#if company != report.company.name}
          <li class="nav-item" on:click={() => setActive(company)}>
            <a class="nav-link" data-toggle="tab" href="#company-{company}"
              >{company}</a
            >
          </li>
        {/if}
      {/each}
    </ul>
    <div class="tab-content">
      <CompanyWorkdaySummary
        bind:currentActive
        company={report.company.name}
        report={report.workday_report}
        data={report.workday_report.registrations_last_month_by_company[
          report.company.name
        ]}
        identifier="organization"
        activeState={isActive(report.company.name)}
      />
      {#each Object.entries(report.workday_report.registrations_last_month_by_company) as [company, data]}
        {#if company != report.company.name}
          <CompanyWorkdaySummary
            bind:currentActive
            {company}
            {data}
            identifier="company"
            activeState={isActive(company)}
          />
        {/if}
      {/each}
      <CompanyWorkdaySummary
        bind:currentActive
        company="all"
        report={report.workday_report}
        data={report.workday_report.registrations_last_month}
        identifier="all"
        activeState={isActive('all')}
      />
    </div>
  </div>
</div>
