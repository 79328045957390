<script>
  import { createEventDispatcher } from 'svelte'
  import Colorbox from './Colorbox.svelte'
  import axios from 'axios'
  import InboxCard from '../../../components/InboxCard.svelte'
  import dayjs from 'dayjs'
  import { workdayTypeToTitle, shiftTypeToTitle } from '@/utils/wdr_helper.js'
  import {
    Icon,
    Calendar,
    Trash,
    ChatBubbleBottomCenterText,
    FaceSmile,
    FaceFrown,
    Share,
  } from 'svelte-hero-icons'
  import { page } from '@inertiajs/svelte'
  import WdrResponse from './WdrResponse.svelte'
  import CompactEvent from '../../Events/components/CompactEvent.svelte'
  import OtherRegistrations from './OtherRegistrations.svelte'

  export let workday
  export let workdayResponses
  export let canDestroy = false
  export let createNewResponse = false
  export let canShare = false
  export let userEvents = []
  export let otherWorkdayRegistrationsSameDay = []

  const hasComment = (workday) => {
    return (
      workday.comment || workday.positive_comment || workday.negative_comment
    )
  }

  const dispatch = createEventDispatcher()

  $: responses = workdayResponses.responses

  const company = $page.props.auth.company
  const current_user = $page.props.auth.user
  const policies = $page.props.auth.policies

  const newReponse = (e) => {
    responses = [e.detail, ...responses]
    responses = responses.sort((a, b) => {
      return new Date(a.created_at) - new Date(b.created_at)
    })
  }

  const shareAsPost = () => {
    if (hasComment(workday)) {
      axios
        .get(`/workday_registrations/${workday.id}/share_as_post`)
        .then((response) => {
          if (response.status == 200) {
            dispatch('close')
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      window.location.href = `/workday_registrations/${workday.id}/edit`
    }
  }

  const deleteWorkday = () => {
    axios
      .delete(`/workday_registrations/${workday.id}`)
      .then(() => {
        window.location.reload()
      })
      .catch((error) => {
        console.log(error)
      })
  }
</script>

<div class="flex">
  <div class="row">
    <div class="col-xs-12">
      <div class="header">
        <div class="info_left">
          <Colorbox wdr={workday} size="lg" />
          <h4>{workdayTypeToTitle(workday)}</h4>
        </div>
        <div class="info_right text-muted">
          <div class="date">
            <Icon src={Calendar} solid size="16" />
            {dayjs(workday.day).format('dddd [d.] D MMMM')}
          </div>
          {#if company.use_shift_type && workday.shift_type}
            {shiftTypeToTitle(workday.shift_type)}
          {/if}

          {#if canDestroy}
            <button on:click={deleteWorkday} class="btn btn-danger">
              <Icon src={Trash} size="16" solid />
            </button>
          {/if}
        </div>
      </div>
    </div>
  </div>
  {#if hasComment(workday)}
    <div class="row">
      <div class="col-xs-12">
        {#if workday.comment}
          <div class="comment">
            <Icon src={ChatBubbleBottomCenterText} size="48" />
            <h5 class="lead">{workday.comment}</h5>
          </div>
        {/if}
        {#if workday.positive_comment}
          <div class="comment">
            <Icon src={FaceSmile} size="48" />
            <h5 class="lead">{workday.positive_comment}</h5>
          </div>
        {/if}
        {#if workday.negative_comment}
          <div class="comment">
            <Icon src={FaceFrown} size="48" />
            <h5 class="lead">{workday.negative_comment}</h5>
          </div>
        {/if}
      </div>
    </div>
  {/if}
  {#if workday.emotion}
    <div class="row">
      <div class="col-xs-12">
        <h5>
          {workday.user.name} følte sig: {workday.emotion.emoji}
        </h5>
      </div>
    </div>
  {/if}
  {#each workday.feelings as feeling}
    <div class="row">
      <div class="col-xs-12">
        <h5>
          {workday.user.name} følte sig: {feeling.emoji}
        </h5>
      </div>
    </div>
  {/each}
  {#if workday.post}
    <div class="row">
      <div class="col-xs-12 text-center">
        <a href="/posts/{workday.post.id}" class="btn">
          <Icon src={Share} size="16" solid />
          {workday.user.id == current_user.id ? 'Du' : workday.user.name} har delt
          bedømmelsen med dine kolleger.
        </a>
      </div>
    </div>
  {/if}
  {#each responses as response (response.id)}
    <WdrResponse {response} response_types={workdayResponses.response_types} />
  {/each}
  {#if createNewResponse}
    <InboxCard
      wdr={workday}
      response_types={workdayResponses.response_types}
      modal
      on:response={newReponse}
      stay
    />
  {/if}
  {#if company.event_registration_enabled}
    <div class="row">
      <div class="col-xs-12">
        <h3>Hændelser</h3>
        {#if userEvents.length > 0}
          <!-- content here -->
          {#each userEvents as event (event.id)}
            <CompactEvent {event} />
          {/each}
        {:else}
          {workday.user.name} har ikke registreret hændelser denne dag
        {/if}
      </div>
    </div>
  {/if}
  {#if policies.workday_registration_report.employee_overview && otherWorkdayRegistrationsSameDay}
    <div class="row">
      <div class="col-xs-12">
        <h3>Andre samme dag</h3>
        <OtherRegistrations
          registrations={otherWorkdayRegistrationsSameDay}
          teams={company.teams_count > 0}
        />
      </div>
    </div>
  {:else}
    <p>Ingen bedømmelser</p>
  {/if}
  {#if !workday.post && canShare && current_user.id === workday.user.id}
    <br />
    <br />
    <div class="row">
      <div class="col-xs-12 col-md-6 col-md-offset-3 text-center">
        <button on:click={shareAsPost} class="btn btn-default btn-block">
          <Icon src={Share} size="16" solid />
          Del bedømmelse med kolleger
        </button>
      </div>
    </div>
  {/if}
</div>

<style>
  .flex {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 7rem;
  }

  a.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .info_left,
  .date {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .info_right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .comment {
    display: flex;
    align-items: top;
    gap: 1rem;
  }

  .comment .lead {
    margin-top: 0;
  }
</style>
