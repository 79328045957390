<script>
  import { page, useForm } from '@inertiajs/svelte'
  import dayjs from 'dayjs'
  import { workdaySentence } from '@/utils/wdr_helper.js'
  import Colorbox from '@/pages/WorkdayRegistrations/components/Colorbox.svelte'

  export let future_vacation_periods
  export let historical_vacation_periods
  export let workday_registrations

  function workday_registrations_in_vacation(vacation) {
    return workday_registrations.filter((w) => {
      return dayjs(w.day).isBetween(
        vacation.start_date,
        vacation.end_date,
        null,
        '[]',
      )
    })
  }

  let delteForm = useForm({
    vacation_period: {
      id: null,
    },
  })

  const deleteVacation = (id) => {
    $delteForm.vacation_period.id = id
    $delteForm.delete(`/vacation_periods/${id}`)
  }
</script>

<div class="row">
  <div class="col-xs-12 col-md-8 col-md-offset-2">
    <h3>
      Kommende fri-perioder
      <div class="pull-right">
        <a href="/vacation_periods/new" class="btn btn-primary"
          >Tilføj fri-periode</a
        >
      </div>
    </h3>
    <span class="text-muted">
      <div class="glyphicon glyphicon-info-sign" />
      Hvis du vil rette en fri-periode, kan du slette den og oprette en ny
    </span>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-md-8 col-md-offset-2">
    {#if Object.keys(future_vacation_periods).length > 0}
      <table class="table table-condensed table-hover">
        {#each Object.keys(future_vacation_periods) as period}
          <h3>{dayjs(period).year()}</h3>
          <tr>
            <th>Uge</th>
            <th>Beskrivelse</th>
            <th>Start Dato</th>
            <th>Slut Dato</th>
          </tr>
          {#each future_vacation_periods[period] as vacation (vacation.id)}
            <tr>
              <td>{dayjs(vacation.start_date).week()}</td>
              <td>{vacation.title}</td>
              <td>{dayjs(vacation.start_date).format('dddd [d.] D. MMMM')}</td>
              <td>{dayjs(vacation.end_date).format('dddd [d.] D. MMMM')}</td>
              <td>
                {#if $page.props.meta.device_type == 'mobile' || $page.props.meta.device_type == 'tablet'}
                  <a
                    href="/vacation_periods/{vacation.id}/confirm_delete_prompt"
                    class="btn btn-default pull-right">Slet</a
                  >
                {:else}
                  <form
                    on:submit|preventDefault={() => deleteVacation(vacation.id)}
                  >
                    <input
                      type="hidden"
                      id="vacationID"
                      name="vacationID"
                      value={vacation.id}
                    />
                    <button type="submit" class="btn btn-default pull-right"
                      >Slet</button
                    >
                  </form>
                {/if}
              </td>
            </tr>
          {/each}
        {/each}
      </table>
    {:else}
      <br />
      <div class="alert alert-warning">
        Du har ikke oprettet nogen kommende fri-periode endnu
      </div>
    {/if}
  </div>
</div>

{#if Object.keys(historical_vacation_periods).length > 0}
  <div class="row">
    <div class="col-xs-12 col-md-8 col-md-offset-2">
      <h3>Tidligere fri-perioder</h3>
      <table class="table table-condensed table-hover">
        {#each Object.keys(historical_vacation_periods) as period}
          <h3>{dayjs(period).year()}</h3>
          <tr>
            <th>Uge</th>
            <th>Beskrivelse</th>
            <th>Start Dato</th>
            <th>Slut Dato</th>
            <th>Arbejdsdage i fri-perioden</th>
          </tr>
          {#each historical_vacation_periods[period] as vacation (vacation.id)}
            <tr>
              <td>{dayjs(vacation.start_date).week()}</td>
              <td>{vacation.title}</td>
              <td>{dayjs(vacation.start_date).format('dddd [d.] D. MMMM')}</td>
              <td>{dayjs(vacation.end_date).format('dddd [d.] D. MMMM')}</td>
              <td>
                <ul>
                  {#each workday_registrations_in_vacation(vacation) as w}
                    <li>
                      <Colorbox wdr={w} />
                      {workdaySentence(w, $page.props.auth)}
                    </li>
                  {/each}
                </ul>
              </td>
            </tr>
          {/each}
        {/each}
      </table>
    </div>
  </div>
{/if}

<style>
  tr {
    border-top: 1px solid #ddd;
  }

  tr:hover {
    background-color: #f5f5f5;
  }

  td,
  th {
    padding: 5px;
    margin: 1px;
  }

  ul {
    padding-left: 0;
    list-style: none;
  }
  li {
    margin-bottom: 2px;
  }
</style>
