<script>
  import { chart } from 'svelte-apexcharts'
  import dayjs from 'dayjs'
  import { getScoreColor } from '../dataHelper.js'

  export let data

  const sampleTitle = (from_date, to_date) => {
    return `Uge ${dayjs(from_date).week()} - ${dayjs(to_date).week()}`
  }

  let options = {
    chart: {
      type: 'scatter',
      height: 300,
      toolbar: {
        show: false,
      },
    },
    series: [
      {
        name: 'Trivselscore',
        data: data.map((d) => {
          return {
            x: sampleTitle(d.from_date, d.to_date),
            y: d.average_score,
            fillColor: getScoreColor(d.average_score),
          }
        }),
      },
    ],
    xaxis: {
      tickPlacement: 'between',
    },
  }
</script>

<div use:chart={options} />
