<script>
  import { score_color } from '/utils/wdr_helper'
  import { workdayRegistrationForm } from '../../../stores/WorkdayRegistrationFormStore'
</script>

<div class="container">
  <div class="row" style="margin-top: 3rem">
    <div class="col-xs-12 col-md-8 col-md-offset-2 wdr-container">
      <div>
        <h2 class="text-center">
          Hvad gjorde din dag
          <span
            class="color-box"
            style="background-color: {score_color(
              $workdayRegistrationForm.score,
            )}"
          />
          ?
        </h2>
      </div>
      <div>
        <label for="comment">Kommentar (del det der fylder)</label>
        <textarea
          name="comment"
          id="comment"
          class="form-control"
          rows="4"
          bind:value={$workdayRegistrationForm.comment}
        />
      </div>
      <div>
        <strong>Tilknyt en følelse til din dag</strong>
        <div class="emotions-container">
          {#each $workdayRegistrationForm.form_data.emotions as emotion}
            <div>
              <input
                type="radio"
                name="emotion-id"
                id="emotion-{emotion.id}"
                value={emotion.id}
                on:change={() =>
                  ($workdayRegistrationForm.emotion_id = emotion.id)}
              />
              <label
                for="emotion-{emotion.id}"
                class="btn btn-default emotion-label {$workdayRegistrationForm.emotion_id ==
                emotion.id
                  ? 'active'
                  : ''}"
              >
                {emotion.emoji}
              </label>
            </div>
          {/each}
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .wdr-container {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }

  .color-box {
    display: inline-block;
    width: 4rem;
    height: 4rem;
    border-radius: 20%;
    top: 10px;
    margin-bottom: -1rem;
  }

  .emotions-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1rem;
    flex-wrap: wrap;
  }

  input[type='radio'] {
    display: none;
  }

  .emotion-label {
    font-size: 2rem;
    padding: 5px 10px 5px 10px;
  }
</style>
