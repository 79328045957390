<script>
  import { User, LogIn, Accessibility, ReceiptText } from 'lucide-svelte'
  import { useForm } from '@inertiajs/svelte'

  import CompanyEditForm from './components/CompanyEditForm.svelte'

  export let company
  export let metrics
  export let potentialParents
  export let customers
  export let companyTypes
  export let enpsFrequencyTypes
  export let leadershipTypes

  let form = useForm({
    company: {
      id: company.id,
      name: company.name,
      parent_id: company.parent_id,
      slug: company.slug,
      has_work_enviroment_product: company.has_work_enviroment_product,
      has_citizen_accounting_product: company.has_citizen_accounting_product,
      has_defusing_module_enabled: company.has_defusing_module_enabled,
      expire_logs_after_months: company.expire_logs_after_months,
      locale: company.locale,
      unverified_login_allowed: company.unverified_login_allowed,
      comment_only_responses: company.comment_only_responses,
      use_workday_answers_for_students:
        company.use_workday_answers_for_students,
      use_shift_type: company.use_shift_type,
      allow_remember_password: company.allow_remember_password,
      incident_prompt_enabled: company.incident_prompt_enabled,
      event_registration_enabled: company.event_registration_enabled,
      incident_plan_module_enabled: company.incident_plan_module_enabled,
      organization_id: company.organization_id,
      weekend_days_off: company.weekend_days_off,
      fun_features: company.fun_features,
      news_feed_enabled: company.news_feed_enabled,
      wdr_responses_enabled: company.wdr_responses_enabled,
      wdr_title_enabled: company.wdr_title_enabled,
      incident_reference_enabled: company.incident_reference_enabled,
      license_expires_at: company.license_expires_at,
      company_type: company.company_type,
      license_expires_at: company.license_expires_at,
      enps_survey_module_enabled: company.enps_survey_module_enabled,
      enps_frequency_type: company.enps_frequency_type,
      enps_survey_frequency_in_days: company.enps_survey_frequency_in_days,
      starting_date: company.starting_date,
      customer_id: company.customer_id,
      fki_uuid: company.fki_uuid,
      has_floweffect: company.has_floweffect,
      leadership_type: company.leadership_type,
    },
  })

  const onSubmit = () => {
    $form.patch(`/te_admin/companies/${company.id}`)
  }
</script>

<div class="editForm">
  <h3>Edit {company.name}</h3>

  <CompanyEditForm
    {company}
    {potentialParents}
    {customers}
    {companyTypes}
    {enpsFrequencyTypes}
    {leadershipTypes}
    bind:form
    {onSubmit}
  />

  <div class="row well well-lg admin-top-well">
    <div class="col-xs-12 col-sm-3 col-md-3">
      <div id="active_users_count">
        <span><User size={32} /> {metrics.activeUsersCount}</span>
        <div class="transaction-count-description text-muted">
          Aktive brugere
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-3 col-md-3">
      <div id="logins_today_count">
        <span><LogIn size={32} /> {metrics.loginsTodayCount}</span>
        <div class="transaction-count-description text-muted">Login i dag</div>
      </div>
    </div>
    {#if company.has_citizen_accounting_product}
      <div class="col-xs-12 col-sm-3 col-md-3">
        <div id="active_citizens_count">
          <span><Accessibility size={32} /> {metrics.activeCitizensCount}</span>
          <div class="transaction-count-description text-muted">
            Aktive borgere
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-3 col-md-3">
        <div id="todays_transactions_count">
          <span
            ><ReceiptText size={32} /> {metrics.todaysTransactionsCount}</span
          >
          <div class="transaction-count-description text-muted">
            Transaktioner i dag
          </div>
        </div>
      </div>
    {/if}
  </div>
</div>
