<script>
  import {
    Icon,
    ExclamationTriangle,
    InformationCircle,
  } from 'svelte-hero-icons'
  import AreaChartBox from '../components/AreaChartBox.svelte'

  export let company

  const incidentCountByDaySum = Object.values(
    company.incident_count_by_day,
  ).reduce((a, b) => a + b, 0)
</script>

<div class="company-card">
  <a href="/organization_reports/incident_reports/{company.id}">
    <div class="btn btn-default btn-block">
      <div class="company-incident-card">
        <div class="company-incident-card-title">
          <h4>{company.company_name}</h4>
          {#if company.high_impact_count > 0}
            <div class="text-danger icon-text">
              <Icon src={ExclamationTriangle} size="18" />
              <strong>{company.high_impact_count} Høj påvirkning</strong>
            </div>
          {/if}
        </div>
        <div class="company-incident-card-chart">
          {#if Object.keys(company.incident_count_by_day).length > 1}
            <AreaChartBox
              data={company.incident_count_by_day}
              minimal
              showFullDate
            />
          {:else}
            <strong>{incidentCountByDaySum} hændelser registeret</strong>
            <br />
            d. {Object.keys(company.incident_count_by_day)[0]}

            <br />
            <br />
            <small class="text-muted icon-text">
              <Icon src={InformationCircle} size="18" />
              Ikke nok hændelser til at vise graf.
            </small>
          {/if}
        </div>
      </div>
    </div>
  </a>
</div>

<style>
  .company-card {
    margin-bottom: 1rem;
  }

  .company-incident-card {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .company-incident-card-title {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }

  .icon-text {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
</style>
