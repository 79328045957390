<script>
  import { page } from '@inertiajs/svelte'
  export let companies

  const company = $page.props.auth.company
</script>

<div class="row">
  <div class="col-xs-12 col-md-6 col-md-offset-3">
    <h2>Skift arbejdsplads</h2>
    <span class="text-muted">
      <div class="glyphicon glyphicon-info-sign" />
      Nedenfor kan du skifte mellem de arbejdspladser du har adgang til.
    </span>
    <br />
    <br />
    {#each companies as company (company.id)}
      <a
        href="/switch_company/?slug={company.slug}"
        class="btn btn-primary btn-block"
      >
        {company.name}
      </a>
    {/each}
    <br />
    <div class="text-center text-muted">
      På nuværende tidspunkt er du logget ind på
      <br />
      {company.name}:
      <strong>{company.slug}</strong>
    </div>
  </div>
</div>
