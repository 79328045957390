<script>
  import { createEventDispatcher } from 'svelte'
  import { page } from '@inertiajs/svelte'

  const currentUser = $page.props.auth.user

  const dispatch = createEventDispatcher()

  export let emoji = null
  export let reactions = []
  export let newReaction = false

  $: count = reactions.length
  $: addedByCurrentUser = reactions.some((r) => r.user.id === currentUser.id)

  const handleReaction = () => {
    if (newReaction) {
      dispatch('addNewReaction')
    }

    if (addedByCurrentUser) {
      let reaction = reactions.find((r) => r.user.id === currentUser.id)
      dispatch('deleteReaction', { reaction })
    } else {
      dispatch('addSameReaction', { emoji })
    }
  }
</script>

{#if !newReaction}
  <button
    class="btn emoji-btn {addedByCurrentUser ? 'added-by-user' : ''}"
    on:click={handleReaction}
  >
    <span class="emoji">{emoji}</span>
    <span class="emoji-count">{count}</span>
  </button>
{:else}
  <button class="btn emoji-btn" on:click={handleReaction}>
    <span class="emoji">😊</span>
    <span class="emoji-count">
      <div class="glyphicon glyphicon-plus" />
    </span>
  </button>
{/if}

<style>
  .emoji-btn {
    background-color: #f1f5ff;
    border: 1px solid #b6d8ff;
    border-radius: 9px;
    padding: 0 10px;
    margin-right: 10px;
    color: #666;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
  .emoji {
    font-size: 20px;
  }
  .emoji-count {
    font-size: 14px;
    font-weight: bold;
  }

  .added-by-user {
    background-color: #d7eefc;
  }
</style>
