<div class="company_metric_box">
  <div class="panel panel-default">
    <div class="panel-body text-center company-metric-box">
      <slot />
    </div>
  </div>
</div>

<style>
  .company_metric_box {
    flex: 1;
  }
</style>
