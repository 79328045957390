<script>
  import { page } from '@inertiajs/svelte'

  export let companies

  let currentCompany = $page.props.auth.company

  let query = window.location.search
  let urlParams = new URLSearchParams(query)
  $: selectedCompany = urlParams.get('company_id') || currentCompany.id

  const changeCompany = (event) => {
    window.location.href = event.target.value
  }
</script>

<div class="container">
  <select
    name="companies"
    id="organization_report_select"
    class="form-control"
    on:change={changeCompany}
  >
    {#each companies as company}
      <option
        value="/nps_survey_reports?company_id={company.id}"
        selected={company.id == selectedCompany}
      >
        {company.name}
      </option>
    {/each}
  </select>
</div>

<style>
  .container {
    margin-bottom: 5rem;
  }
</style>
