<script>
  import ReportMenu from '@/components/ReportsNavigation/ReportMenu.svelte'
  import Section from '@/components/Section.svelte'
  import dayjs from 'dayjs'
  import ScoreRow from './components/biweekly/ScoreRow.svelte'
  import PeriodSampleRow from './components/biweekly/PeriodSampleRow.svelte'
  import { Icon, QuestionMarkCircle, ChartBar } from 'svelte-hero-icons'
  import PeriodSampleChart from './components/PeriodSampleChart.svelte'
  import Comments from './components/biweekly/Comments.svelte'
  import pluralize from 'pluralize'

  pluralize.addPluralRule(/er$/i, 'ere')

  export let currentReport
  export let priorReport
  export let eventsCountByUser
  export let historicalPeriodSamples

  let poorestEmployees = currentReport.poorest.slice(0, 5)
  let bestEmployees = currentReport.best.slice(0, 5)
</script>

<ReportMenu current_tab="biweekly" />

<Section title="14-dages rapport">
  <div slot="subtitle">
    For uge {dayjs(currentReport.from_date).format('w') - 1} og {dayjs(
      currentReport.to_date,
    ).format('w') - 1}
  </div>
  <div class="row">
    <div class="col-xs-12 col-md-6 overall-score">
      <div class="heading">
        <h3>Trivsels-score</h3>
        <div>
          <a
            href="#thriving-score-graph"
            class="btn btn-sm btn-default btn-icon"
            data-toggle="collapse"
          >
            <Icon src={ChartBar} mini size="16" />
            Se udvikling over tid
          </a>
        </div>
      </div>
      <div class="score-row">
        <ScoreRow
          overallScore={currentReport.overall_score}
          priorScore={priorReport.overall_score}
        />
        {#if currentReport.score_by_team && Object.entries(currentReport.score_by_team).length > 0}
          {#each Object.entries(currentReport.score_by_team) as [name, score]}
            <ScoreRow
              overallScore={score}
              teamName={name}
              priorScore={priorReport.score_by_team[name]}
            />
          {/each}
        {/if}
      </div>
      <div class="info-text text-muted">
        <Icon src={QuestionMarkCircle} mini size="32" />
        <p>
          Den overordnede Trivsel-Score er en score fra 1-5, som angiver den
          generelle trivsel for en medarbejder, team eller organisation over en
          periode.
        </p>
      </div>
    </div>
    <div id="thriving-score-graph" class="col-xs-12 collapse">
      <h3>Overordnet Trivsel-Score seneste 6 måneder</h3>
      <PeriodSampleChart data={historicalPeriodSamples} />
    </div>
  </div>

  <div class="best">
    <h3>{bestEmployees.length} bedst trivende medarbejdere</h3>
    {#each bestEmployees as sample}
      <PeriodSampleRow
        {sample}
        priorSample={priorReport.samples_by_user[sample.user.id][0]}
        eventsCount={eventsCountByUser[sample.user.id]}
        data={sample.data}
      />
    {/each}
  </div>

  <div class="poorest">
    <h3>{poorestEmployees.length} dårligst trivende medarbejdere</h3>
    {#each poorestEmployees as sample}
      <PeriodSampleRow
        {sample}
        priorSample={priorReport.samples_by_user[sample.user.id][0]}
        eventsCount={eventsCountByUser[sample.user.id]}
        data={sample.data}
      />
    {/each}
  </div>

  <div class="comments better-days">
    <h3>Kommentarer til grønne dage</h3>
    {#if currentReport.better_days_with_comments.length > 0}
      <Comments days={currentReport.better_days_with_comments} />
    {:else}
      <span class="text-muted"
        >Der er ikke grønne dage med kommentarer i den givne periode</span
      >
    {/if}
  </div>

  <div class="comments bad-days">
    <h3>Kommentarer til dårlige dage</h3>
    {#if currentReport.bad_days_with_comments.length > 0}
      <Comments days={currentReport.bad_days_with_comments} />
    {:else}
      <span class="text-muted"
        >Der er ikke dårlige dage med kommentarer i den givne periode</span
      >
    {/if}
  </div>

  <div class="too-few-registrations">
    <h3>
      {pluralize('medarbejder', currentReport.too_few.length, true)} med meget få
      bedømmelser
    </h3>
    <div class="text-muted">
      <p>
        <span class="label label-danger">Anbefaling</span> Mind medarbejderne om,
        at deres bedømmelser er afgørende for forbedring af trivslen
      </p>
    </div>
    <div class="too-few-table">
      <table class="table">
        <thead>
          <tr>
            <th>Navn</th>
            <th class="text-right">Bedømmelser</th>
            <th class="text-right">Fridage</th>
            <th class="text-right">Sygedage</th>
            <th class="text-right">Seneste login</th>
          </tr>
        </thead>
        <tbody>
          {#each currentReport.too_few as sample}
            <tr>
              <td>
                <a href="#">{sample.user.name}</a>
              </td>
              <td class="text-right">
                {sample.score_registrations}
              </td>
              <td class="text-right">
                {sample.days_off}
              </td>
              <td class="text-right">
                {sample.sick_days}
              </td>
              <td class="text-right">
                {#if sample.user.last_successful_login}
                  {dayjs(sample.user.last_successful_login).format('ll, LT')}
                {:else}
                  Aldrig
                {/if}
              </td>
            </tr>
          {/each}
        </tbody>
      </table>
    </div>
  </div>
</Section>

<style>
  .heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0;
  }

  .heading h3 {
    padding: 0;
    margin: 0;
  }

  .overall-score {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
  }

  .score-row {
    border-bottom: 1px solid #ddd;
  }

  .info-text {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: start;
  }
</style>
