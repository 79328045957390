<script>
  import { useForm, page } from '@inertiajs/svelte'
  import MembershipRoleSelect from './MembershipRoleSelect.svelte'
  import { get } from 'svelte/store'

  export let membership
  export let has_another_AMR
  export let has_another_leader

  const current_user = $page.props.auth.user
  const user = membership.user

  const has_accounting_product =
    membership.company.has_citizen_accounting_product
  const has_work_enviroment_product =
    membership.company.has_work_enviroment_product

  const citizens = has_accounting_product ? 'og borgere' : ''

  let form = useForm({
    membership: {
      owner: membership.owner,
      manage_users_and_citizens: membership.manage_users_and_citizens,
      can_access_anonymous_data: membership.can_access_anonymous_data,
      can_access_owners_data: membership.can_access_owners_data,
      can_access_all_data: membership.can_access_all_data,
      included_in_reports: membership.included_in_reports,
      can_access_incident_reports: membership.can_access_incident_reports,
      has_only_read_access_to_accounting:
        membership.has_only_read_access_to_accounting,
    },
  })

  $: getRole = () => {
    if ($form.membership.can_access_all_data || $form.owner) {
      return 'leader'
    } else if ($form.membership.can_access_anonymous_data) {
      return 'amr'
    } else {
      return 'employee'
    }
  }

  function updateMembership() {
    // console.log($form.membership.external_person)
    $form.patch(`/memberships/${membership.id}`)
  }

  function setRole(e) {
    let has_leader_warning = document.querySelector('#has-leader-warning')
    let has_amr_warning = document.querySelector('#has-amr-warning')
    if (e.target.value == 'leader') {
      $form.membership.owner = true
      $form.membership.manage_users_and_citizens = true
      $form.membership.can_access_anonymous_data = true
      $form.membership.can_access_owners_data = true
      $form.membership.can_access_all_data = true
      $form.membership.included_in_reports = true
      $form.membership.can_access_incident_reports = true

      if (has_another_leader) has_leader_warning.classList.remove('hidden')
      if (has_another_AMR) has_amr_warning.classList.add('hidden')
    }

    if (e.target.value == 'amr') {
      $form.membership.owner = false
      $form.membership.manage_users_and_citizens = true
      $form.membership.can_access_anonymous_data = true
      $form.membership.can_access_owners_data = false
      $form.membership.can_access_all_data = false
      $form.membership.included_in_reports = true
      $form.membership.can_access_incident_reports = true

      if (has_another_leader) has_leader_warning.classList.add('hidden')
      if (has_another_AMR) has_amr_warning.classList.remove('hidden')
    }

    if (e.target.value == 'employee') {
      $form.membership.owner = false
      $form.membership.manage_users_and_citizens = false
      $form.membership.can_access_anonymous_data = false
      $form.membership.can_access_owners_data = false
      $form.membership.can_access_all_data = false
      $form.membership.included_in_reports = true
      $form.membership.can_access_incident_reports = false

      if (has_another_leader) has_leader_warning.classList.add('hidden')
      if (has_another_AMR) has_amr_warning.classList.add('hidden')
    }
  }

  function setAllDataAccess(e) {
    if (e.target.checked) {
      $form.membership.can_access_anonymous_data = true
      $form.membership.can_access_owners_data = true
      $form.membership.can_access_all_data = true
    } else {
      $form.membership.can_access_anonymous_data = false
      $form.membership.can_access_owners_data = false
      $form.membership.can_access_all_data = false
    }
  }
</script>

<form on:submit|preventDefault={updateMembership}>
  {#if has_another_leader}
    <div id="has-leader-warning" class="alert alert-warning hidden">
      Denne virksomhed har allerede én leder
    </div>
  {/if}
  {#if has_another_AMR}
    <div id="has-amr-warning" class="alert alert-warning hidden">
      Denne virksomhed har allerede én AMR/TR
    </div>
  {/if}
  <div class="form-group">
    <label for="role">Medarbejder Type</label>
    <select
      on:change|preventDefault={(e) => setRole(e)}
      bind:value={$form.membership.role}
      class="form-control"
      id="role"
    >
      <MembershipRoleSelect role={getRole()} />
    </select>
  </div>
  <div class="form-group">
    <strong>Kontoindehaver</strong>
    <div class="checkbox">
      <label>
        <input
          type="checkbox"
          bind:checked={$form.membership.owner}
          id="owner"
          disabled={current_user.id == user.id}
        />
        Er leder for arbejdspladsen
      </label>
      <p class="help-block">
        Giver ultimativt ret til at konfigurere TeamEffects opsætning og
        rettigheder
      </p>
    </div>
  </div>
  <div class="form-group">
    <strong>Rettigheder</strong>
    <div class="checkbox">
      <label>
        <input
          type="checkbox"
          bind:value={$form.membership.manage_users_and_citizens}
          bind:checked={$form.membership.manage_users_and_citizens}
          id="manage_users_and_citizens"
          disabled={current_user.id == user.id}
        />
        Kan administrere medarbejdere {citizens}
      </label>
      <p class="help-block">
        Giver adgang til at oprette og slette teams {citizens ? ', ' : ' og'} medarbejdere
        {citizens}
      </p>
    </div>
  </div>
  {#if has_work_enviroment_product}
    <div class="form-group">
      <strong>Adgang til data</strong>
      <div class="checkbox">
        <label>
          <input
            type="checkbox"
            checked={true}
            disabled={true}
            readonly={true}
          /> Kan se egne bedømmelser
        </label>
      </div>
    </div>
    <div class="access-checkboxes">
      <div class="form-group">
        <div class="checkbox">
          <label>
            <input
              id="can_access_anonymous_data"
              type="checkbox"
              bind:value={$form.membership.can_access_anonymous_data}
              bind:checked={$form.membership.can_access_anonymous_data}
            /> Kan se anonyme data
          </label>
        </div>
      </div>
      <div class="form-group">
        <div class="checkbox">
          <label>
            <input
              id="can_access_owners_data"
              type="checkbox"
              bind:value={$form.membership.can_access_owners_data}
              bind:checked={$form.membership.can_access_owners_data}
            /> Kan se leders bedømmelser
          </label>
        </div>
      </div>
      <div class="form-group">
        <div class="checkbox">
          <label>
            <input
              id="can_access_all_data"
              type="checkbox"
              on:change|preventDefault={(e) => setAllDataAccess(e)}
              bind:value={$form.membership.can_access_all_data}
              bind:checked={$form.membership.can_access_all_data}
            /> Kan se alle brugeres bedømmelser
          </label>
        </div>
      </div>
    </div>
    <strong>Personale</strong>
    <div class="form-group">
      <div class="checkbox">
        <label>
          <input
            type="checkbox"
            id="included_in_reports"
            bind:value={$form.membership.included_in_reports}
            bind:checked={$form.membership.included_in_reports}
          />
          Inkluderet i rapporter
        </label>
      </div>
    </div>
  {/if}
  {#if membership.company.event_registration_enabled}
    <strong>Hændelsesregistrering</strong>
    <div class="form-group">
      <div class="checkbox">
        <label>
          <input
            type="checkbox"
            id="can_access_incident_reports"
            bind:value={$form.membership.can_access_incident_reports}
            bind:checked={$form.membership.can_access_incident_reports}
          />Adgang til hændelsesrapporter
        </label>
        <p class="help-block">
          Typisk har leder og AMR/TR adgang til at se hændelsesrapporter
        </p>
      </div>
    </div>
  {/if}
  {#if has_accounting_product}
    <strong>Borgerregnskab</strong>
    <div class="form-group">
      <div class="checkbox">
        <label>
          <input
            type="checkbox"
            id="has_only_read_access_to_accounting"
            bind:value={$form.membership.has_only_read_access_to_accounting}
            bind:checked={$form.membership.has_only_read_access_to_accounting}
          />Kun "Kigge-adgang" til regnskaber (Kan ikke føre regnskab)
        </label>
        <p class="help-block">
          Gives typisk til værger der skal følge med i regnskaberne, men ikke
          føre dem'
        </p>
      </div>
    </div>
  {/if}
  <div class="form-group">
    <button type="submit" class="btn btn-primary">Gem</button>
  </div>
</form>
